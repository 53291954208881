/** @format */

import React from 'react';
import { FormField } from './Input';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export const TelInput = props => {
    const { width } = props;

    return (
        <FormField {...props}>
            {width ? (
                <div
                    style={{
                        maxWidth: width,
                    }}>
                    <InputTelInput {...props} />
                </div>
            ) : (
                <InputTelInput {...props} />
            )}
        </FormField>
    );
};

export const InputTelInput = props => {
    const {
        error,
        value = '',
        onChange,
        name,
        placeholder,
        disabled,
        className = 'form-control form-control-solid h-auto form-control w-100',
        onKeyDown,
        onSelectFlag,
        onPhoneNumberChange,
    } = props;

    const customOnPhoneNumberChange = (isValid, value, country, fullNumber, extension, fieldName) => {
        onPhoneNumberChange(isValid, value, country, fullNumber, extension, fieldName);
    };

    const customOnSelectFlag = (currentNumber, selectedCountryData, fullNumber, fieldName) => {
        onSelectFlag(currentNumber, selectedCountryData, fullNumber, fieldName);
    };

    return (
        <IntlTelInput
            containerClassName={'intl-tel-input tel-wrapper w-100'}
            inputClassName={className}
            placeholder={placeholder}
            fieldName={name}
            fieldId={name}
            preferredCountries={['sg', 'my']}
            onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber, extension, fieldName) =>
                customOnPhoneNumberChange(isValid, value, selectedCountryData, fullNumber, extension, name)
            }
            onSelectFlag={(currentNumber, selectedCountryData, fullNumber) =>
                customOnSelectFlag(currentNumber, selectedCountryData, fullNumber, name)
            }
            value={value || ''}
            disabled={disabled}
            format={false}
            nationalMode={false}
            formatOnInit={false}
        />
    );
};
