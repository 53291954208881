/** @format */

import { gql } from '@apollo/client';

export const GET_TEMPLATE_FIELDS_BY_CORPORATE_ID = gql`
    query($corporateId: String!) {
        getTemplateFieldsByCorporateId(corporateId: $corporateId) {
            _id
            fields
            corporateId
            status
        }
    }
`;
