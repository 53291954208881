/** @format */

import React from "react";
import { DATE_FORMAT } from "src/app/helpers/misc";
import { FORMAT_DATE, NUMBER_FORMAT } from "src/app/helpers/util";
import { toAbsoluteUrl } from "src/_metronic/_helpers";
import {
  hyperlinkDownload,
  hyperlinkDownloadWithFileNameAndContentType
} from "src/app/helpers/util";
import SVG from "react-inlinesvg";

const { REACT_APP_API_URL } = process.env;

export const LookupFieldLabel = ({
  label,
  options,
  valueKey,
  matchedKey,
  matchedValue,
  noHr
}) => {
  const a = options
    ? options.filter(option => option[matchedKey] === matchedValue)
    : [];
  const aName = a && a.length > 0 ? a[0][valueKey] : "N/A";
  return (
    <>
      <div className="row">
        {label && (
          <div className="col-sm-12">
            <h6 className="print-h6 print-mb-0 text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
              {label}
            </h6>
          </div>
        )}
        <div className="col-sm-12 text-primary card-field-value text-muted font-weight-bold d-block">
          {aName}
        </div>
      </div>
      {!noHr && <hr className="print-hr" />}
    </>
  );
};
export const TextFieldLabel = ({ label, value }) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h6 className="print-h6 print-mb-0 text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {label}
          </h6>
        </div>
        <div className="col-sm-12 text-primary card-field-value text-muted font-weight-bold d-block">
          {value || "N/A"}
        </div>
      </div>
      <hr className="print-hr" />
    </>
  );
};
export const NumberFieldLabel = ({ label, value }) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h6 className="print-h6 print-mb-0 text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {label}
          </h6>
        </div>
        <div className="col-sm-12 text-primary card-field-value text-muted font-weight-bold d-block">
          {value ? NUMBER_FORMAT.format(value) : "N/A"}
        </div>
      </div>
      <hr className="print-hr" />
    </>
  );
};
export const CurrencyFieldLabel = ({ label, value }) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h6 className="print-h6 print-mb-0 text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {label}
          </h6>
        </div>
        <div className="col-sm-12 text-primary card-field-value text-muted font-weight-bold d-block">
          {value
            ? Number(value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : "N/A"}
        </div>
      </div>
      <hr className="print-hr" />
    </>
  );
};
export const DateFieldLabel = ({ label, value }) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h6 className="print-h6 print-mb-0 text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {label}
          </h6>
        </div>
        <div className="col-sm-12 text-primary card-field-value text-muted font-weight-bold d-block">
          {value ? FORMAT_DATE(value, DATE_FORMAT) : "N/A"}
        </div>
      </div>
      <hr className="print-hr" />
    </>
  );
};
export const AttachmentFieldLabel = ({
  label,
  fileName,
  fileId,
  noHr,
  downloadName
}) => {
  return (
    <>
      <div className="row">
        {label && (
          <div className="col-sm-12">
            <h6 className="print-h6 print-mb-0 text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
              {label}
            </h6>
          </div>
        )}
        <div className="col-sm-12 text-primary card-field-value text-muted font-weight-bold d-block">
          {fileId ? (
            <a
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (downloadName) {
                  hyperlinkDownloadWithFileNameAndContentType(
                    `${REACT_APP_API_URL}/attachment/download?id=${fileId}`,
                    `${downloadName}`,
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  );
                } else {
                  hyperlinkDownload(
                    `${REACT_APP_API_URL}/attachment/download?id=${fileId}`
                  );
                }
              }}
            >
              <div className="d-flex align-items-center px-4 py-2 bg-light-secondary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}
                  width={20}
                  height={20}
                />
                <span className="ml-2">{fileName}</span>
              </div>
            </a>
          ) : (
            "N/A"
          )}
        </div>
      </div>
      {!noHr && <hr className="print-hr" />}
    </>
  );
};
