/** @format */

import React from "react";
import ReactDOM from "react-dom";

import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "src/_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "src/_metronic/_assets/plugins/flaticon/flaticon.css";
import "src/_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import App from "./app/App";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById("root"));

// This service worker causes page to cache, so disabled
// serviceWorker.register();
