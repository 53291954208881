/** @format */

import React, { useState, useContext, useEffect } from 'react';

import { AuthContext } from 'src/app/context';
import { PageTitle } from 'src/app/layout';
import { Boundary } from 'src/app/components';

import IframeComm from 'react-iframe-comm';

const { REACT_APP_SPEEDCARD_URL } = process.env;

export default function TemplateFields() {
    const { getSessionStorage, getUserInfo } = useContext(AuthContext);
    const userInfo = getUserInfo();
    const { corporateId, corporateName } = getSessionStorage();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [pageHeight, setPageHeight] = useState(0);

    // const [updateCorporate] = useMutation(UPDATE_CORPORATE_INFO, {
    //     onError: e => setError(e.message),
    //     onCompleted: () => setSuccess('Successfully updated profile'),
    // });

    useEffect(() => {
        setPageHeight(document.documentElement.scrollHeight);
    }, []);

    const templateFieldsAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}template-input.php?isIframe=true&&corporateId=${corporateId}&&corporateName=${corporateName}`,
        width: '100%',
        height: pageHeight,
    };

    const postData = {
        ...userInfo,
        corporateName: corporateName,
        corporateId: corporateId,
    };

    const postMessageData = JSON.stringify(postData);

    const onReceiveMessage = e => {
        const data = JSON.parse(e.data);

        if (data !== undefined && data !== null) {
            const message = data.message;

            if (message !== undefined && message !== null) {
                const action = message.action;

                if (action !== undefined && action !== null) {
                    if (action === 0) {
                        // setStep(CARD_STEP.showList);
                    }
                }
            }
        }
    };

    // iframe has loaded
    const onReady = () => {};

    return (
        <>
            <PageTitle title="Template Fields" />
            <Boundary
                // loading={loading}
                error={error}
                success={success}
                onClose={() => {
                    setError('');
                    setSuccess('');
                }}>
                <IframeComm
                    attributes={templateFieldsAttributes}
                    postMessageData={postMessageData}
                    handleReady={onReady}
                    handleReceiveMessage={onReceiveMessage}
                />
            </Boundary>
        </>
    );
}

// const profileSchema = Yup.object().shape({
//     name: Yup.string().required('Required field'),
//     email: Yup.string()
//         .email('Wrong email format')
//         .min(3, 'Minimum 3 symbols')
//         .max(50, 'Maximum 50 symbols')
//         .required('Required field'),
//     // phone: Yup.string().matches(phoneRegExp, 'Invalid phone number'),
//     // .when('enabled2FA', {
//     //     is: value => value === true,
//     //     then: Yup.string().required('Required field'),
//     // }),
//     logo: Yup.mixed()
//         .notRequired()
//         .nullable(),
// });
