/** @format */

import React, { useState } from 'react';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { Form } from 'src/app/components';

export const SearchBar = props => {
    const { onSearch, placeholder = 'Search...', className } = props;
    const [keyword, setKeyword] = useState('');

    const onSubmit = e => {
        e.preventDefault();
        onSearch(keyword);
    };

    return (
        <Form onSubmit={onSubmit}>
            <div className="input-icon">
                <input
                    type="text"
                    className={`search-bar form-control form-control ${className}`}
                    placeholder={placeholder}
                    autoComplete="false"
                    onChange={e => setKeyword(e.target.value)}
                />
                <span>
                    <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')} />
                    </span>
                </span>
            </div>
        </Form>
    );
};

export const SearchBarV2 = props => {
    const { onSearch, placeholder = 'Search...', className } = props;
    const [keyword, setKeyword] = useState('');

    const onSubmit = e => {
        e.preventDefault();
        onSearch(keyword);
    };

    const onChange = e => {
        e.preventDefault();
        onSearch(e.target.value);
        setKeyword(e.target.value);
    };

    return (
        <Form onSubmit={onSubmit}>
            <div className="input-icon">
                <input
                    type="text"
                    className={`search-bar form-control form-control ${className}`}
                    placeholder={placeholder}
                    autoComplete="false"
                    onChange={onChange}
                />
                <span>
                    <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')} />
                    </span>
                </span>
            </div>
        </Form>
    );
};
