/** @format */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { InView } from "react-intersection-observer";

import "react-vertical-timeline-component/style.min.css";

export const VerticalTimelineElement = ({
  children,
  className,
  contentArrowStyle,
  contentStyle,
  date,
  dateClassName,
  icon,
  iconClassName,
  iconOnClick,
  onTimelineElementClick,
  iconStyle,
  id,
  position,
  style,
  textClassName,
  intersectionObserverProps,
  visible
}) => (
  <InView {...intersectionObserverProps}>
    {({ inView, ref }) => (
      <div
        ref={ref}
        id={id}
        className={classNames(className, "vertical-timeline-element", {
          "vertical-timeline-element--left": position === "left",
          "vertical-timeline-element--right": position === "right",
          "vertical-timeline-element--no-children": children === ""
        })}
        style={style}
      >
        <React.Fragment>
          <span // eslint-disable-line jsx-a11y/no-static-element-interactions
            style={iconStyle}
            onClick={iconOnClick}
            className={classNames(
              iconClassName,
              "vertical-timeline-element-icon-custom",
              {
                "bounce-in": inView || visible,
                "is-hidden": !(inView || visible)
              }
            )}
          >
            <span
              className={classNames(
                dateClassName,
                "vertical-timeline-element-date"
              )}
            >
              {date}
            </span>
          </span>
          {children && (
            <div
              style={contentStyle}
              onClick={onTimelineElementClick}
              className={classNames(
                textClassName,
                "vertical-timeline-element-content-custom",
                {
                  "bounce-in": inView || visible,
                  "is-hidden": !(inView || visible)
                }
              )}
            >
              <div
                style={contentArrowStyle}
                className="vertical-timeline-element-content-arrow-custom"
              />
              {children}
            </div>
          )}
        </React.Fragment>
      </div>
    )}
  </InView>
);

VerticalTimelineElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  contentArrowStyle: PropTypes.shape({}),
  contentStyle: PropTypes.shape({}),
  date: PropTypes.node,
  dateClassName: PropTypes.string,
  icon: PropTypes.element,
  iconClassName: PropTypes.string,
  iconStyle: PropTypes.shape({}),
  iconOnClick: PropTypes.func,
  onTimelineElementClick: PropTypes.func,
  id: PropTypes.string,
  position: PropTypes.string,
  style: PropTypes.shape({}),
  textClassName: PropTypes.string,
  visible: PropTypes.bool,
  intersectionObserverProps: PropTypes.shape({
    root: PropTypes.object,
    rootMargin: PropTypes.string,
    threshold: PropTypes.number,
    triggerOnce: PropTypes.bool
  })
};

VerticalTimelineElement.defaultProps = {
  children: "",
  className: "",
  contentArrowStyle: null,
  contentStyle: null,
  icon: null,
  iconClassName: "",
  iconOnClick: null,
  onTimelineElementClick: null,
  iconStyle: null,
  id: "",
  style: null,
  date: "",
  dateClassName: "",
  position: "",
  textClassName: "",
  visible: false,
  intersectionObserverProps: { rootMargin: "0px 0px -40px 0px" }
};

export const VerticalTimeline = ({ animate, className, layout, children }) => (
  <div
    className={classNames(className, "vertical-timeline-custom", {
      "vertical-timeline--animate": animate,
      "vertical-timeline--two-columns": layout === "2-columns",
      "vertical-timeline--one-column-left":
        layout === "1-column" || layout === "1-column-left",
      "vertical-timeline--one-column-right": layout === "1-column-right"
    })}
  >
    {children}
  </div>
);

VerticalTimeline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  animate: PropTypes.bool,
  layout: PropTypes.oneOf([
    "1-column-left",
    "1-column",
    "2-columns",
    "1-column-right"
  ])
};

VerticalTimeline.defaultProps = {
  animate: true,
  className: "",
  layout: "2-columns"
};
