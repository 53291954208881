/** @format */

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import * as Paths from 'src/app/routes/paths';
import { NavLink } from 'react-router-dom';

import { AuthContext } from 'src/app/context';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

export const PageHeader = props => {
    const { showGreeting, noSignOut, personNotification = {}, loadingPersonNotification } = props;
    const { pageTitle } = useSelector(state => state);
    const { getSessionStorage } = useContext(AuthContext);
    const { name } = getSessionStorage();

    const time = new Date().getHours();
    const greetingPrefix = time < 12 ? 'Good Morning' : time < 18 ? 'Good Afternoon' : 'Good Night';

    if (loadingPersonNotification) return <Spinner />;
    return (
        <div className={`border-bottom d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
            <div className="w100p d-flex flex-wrap justify-content-between align-items-center">
                <h2 className={`subheader-title text-dark font-weight-bol ml-5 mr-3 my-4`}>
                    {pageTitle || 'SpeedCard'}
                </h2>
                <div>
                    {personNotification && personNotification.length > 0 && (
                        <OverlayTrigger
                            trigger="focus"
                            placement="bottom"
                            overlay={
                                <Tooltip id="notification-alert">
                                    <div className="notification-title">Things to do today!</div>
                                    <ul className="notification-ul">
                                        {personNotification.map(notification => (
                                            <li className="notification-li">{notification.message}</li>
                                        ))}
                                    </ul>
                                </Tooltip>
                            }>
                            <NavLink className="nav-link btn btn-icon btn-clean procorp-btn-clean btn-lg" to={''}>
                                <span
                                    className="svg-icon svg-icon-lg svg-icon-warning"
                                    style={{ pointerEvents: 'none' }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path
                                                d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z"
                                                fill="#000000"
                                                opacity="0.3"
                                            />
                                            <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1" />
                                            <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </NavLink>
                        </OverlayTrigger>
                    )}
                    {showGreeting && (
                        <NavLink className="menu-link" to={`/user${Paths.PROFILE}`}>
                            <span className={clsx('text-dark-75 ml-1', !noSignOut && 'mr-5')}>
                                {greetingPrefix}, <span className="text-primary">{name}</span>
                            </span>
                        </NavLink>
                    )}
                    {/* {!noSignOut && (
                        <Link to={`/public${Paths.LOGOUT}`}>
                            <Button variant="link" label="Sign out" className="font-weight-bold px-4" />
                        </Link>
                    )} */}
                </div>
            </div>
        </div>
    );
};
