/** @format */

import React, { useMemo, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';

import { useHtmlClassService } from 'src/_metronic/layout/_core/MetronicLayout';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import { Brand } from 'src/_metronic/layout/components/brand/Brand';

import * as Paths from 'src/app/routes/paths';
import { AuthContext } from 'src/app/context';
import { isIntersectionExists } from 'src/app/helpers/util';
const { REACT_APP_SPEEDCARD_ORGANIZATION_CODE } = process.env;

export const Aside = props => {
    const { getSessionStorage } = useContext(AuthContext);
    const { roles, corporateId, corporateList, corporateName, corporateKey } = getSessionStorage();
    const { origin, openCorpOptionsModal } = props;
    const isProcorp = corporateId === REACT_APP_SPEEDCARD_ORGANIZATION_CODE;

    // metronic
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            asideClassesFromConfig: uiService.getClasses('aside', true),
            asideSecondaryDisplay: objectPath.get(uiService.config, 'aside.secondary.display'),
            asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
            extrasSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
            extrasNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
            extrasQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
            extrasQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
            extrasLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
            extrasUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
        };
    }, [uiService]);

    const isActive = link => window.location.pathname.includes(link);

    return (
        <div
            id="kt_aside"
            className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}>
            <Brand
                openCorpOptionsModal={openCorpOptionsModal}
                corporateList={corporateList}
                corporateName={corporateName}
                corporateKey={corporateKey}
            />
            <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
                <div id="kt_aside_menu" className="aside-menu my-4">
                    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                        {asideMenus({ origin, roles }).map(x => {
                            const { name, link, permittedRoles, icon, normalUser, procorpUser, fa } = x;
                            const active = isActive(link);
                            if (
                                // !isIntersectionExists(permittedRoles, roles)

                                !isIntersectionExists(permittedRoles, roles.split(',')) ||
                                !((procorpUser && isProcorp) || (normalUser && !isProcorp))
                            )
                                return null;

                            return (
                                <>
                                    <li
                                        key={name}
                                        className="menu-item"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-container="body"
                                        data-boundary="window">
                                        <NavLink className="menu-link" to={link}>
                                            <span
                                                className={clsx('svg-icon menu-icon', {
                                                    'text-primary': active,
                                                })}
                                                style={{
                                                    marginLeft: 10,
                                                }}>
                                                {fa ? (
                                                    <i className={`${icon} procorp-aside-icon`}></i>
                                                ) : (
                                                    <SVG src={icon} />
                                                )}
                                            </span>
                                            <span
                                                className={clsx('procorp-menu-text', {
                                                    'text-primary': active,
                                                })}>
                                                {name}
                                            </span>
                                        </NavLink>
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <ul
                className={`menu-nav`}
                style={{
                    paddingLeft: 20,
                }}>
                <li className="menu-item mb-3 mt-0">
                    {/* <NavLink className="menu-link" to={`/${origin}${Paths.PROFILE}`}> */}
                    <span
                        className="menu-text text-primary text-center"
                        style={{
                            marginLeft: 2,
                            fontSize: '13px',
                        }}>
                        {roles === 'corporatesuperadmin' ? 'SpeedCard Admin' : ''}
                    </span>
                    <br></br>
                    <span
                        className="menu-text text-primary text-center"
                        style={{
                            marginLeft: 2,
                            fontSize: '13px',
                        }}>
                        {roles === 'superadmin' ? 'SpeedCard Admin' : 'Corporate Admin'}
                    </span>
                    {/* </NavLink> */}
                </li>

                <li
                    className="menu-item"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-container="body"
                    data-boundary="window">
                    <NavLink className="menu-link" to={`/public${Paths.LOGOUT}`}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Sign-out.svg')} />
                        </span>
                        <span
                            className="menu-text procorp-menu-text"
                            style={{
                                marginLeft: 15,
                            }}>
                            Logout
                        </span>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

const asideMenus = props => {
    const { origin, roles } = props;

    return [
        {
            name: 'Home',
            link: `/${origin}${Paths.DASHBOARD}`,
            normalUser: true,
            procorpUser: true,
            permittedRoles: ['user', 'admin', 'superadmin', 'corporatesuperadmin'],
            icon: toAbsoluteUrl('/media/svg/icons/Home/home_white_24dp.svg'),
        },
        {
            name: 'Cards/Template',
            link: `/${origin}${Paths.CARDS}`,
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            normalUser: true,
            procorpUser: true,
            icon: 'fas fa-address-card',
            fa: true,
        },
        {
            name: 'Staff',
            link: `/${origin}${Paths.STAFF}`,
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            normalUser: true,
            procorpUser: true,
            // icon: toAbsoluteUrl('/media/svg/icons/Home/work_outline_white_24dp.svg'),
            icon: 'fas fa-users',
            fa: true,
        },
        {
            name: 'Corporate',
            link: `/${origin}${Paths.CORPORATE}`,
            permittedRoles: ['superadmin', 'corporatesuperadmin'],
            normalUser: true,
            procorpUser: true,
            // icon: toAbsoluteUrl('/media/svg/icons/Home/work_outline_white_24dp.svg'),
            icon: 'fas fa-building',
            fa: true,
        },
        // {
        //     name: 'Templates',
        //     link: `/${origin}${Paths.TEMPLATE}`,
        //     permittedRoles: ['user', 'admin'],
        //     normalUser: true,
        //     procorpUser: true,
        //     // icon: toAbsoluteUrl('/media/svg/icons/Home/work_outline_white_24dp.svg'),
        //     icon: 'fas fa-address-card',
        //     fa: true,
        // },
        {
            name: 'Analytics',
            link: `/${origin}${Paths.ANALYTICS}`,
            permittedRoles: ['user', 'admin'],
            normalUser: true,
            procorpUser: true,
            icon: 'fas fa-chart-line',
            fa: true,
        },
        {
            name: 'Analytics',
            link: `/${origin}${Paths.ADMIN_ANALYTICS}`,
            permittedRoles: ['corporatesuperadmin', 'superadmin'],
            normalUser: true,
            procorpUser: true,
            icon: 'fas fa-chart-line',
            fa: true,
        },
        {
            name: 'Settings',
            link: `/${origin}${Paths.SETTINGS}`,
            permittedRoles: ['user', 'admin', 'superadmin', 'corporatesuperadmin'],
            normalUser: true,
            procorpUser: true,
            icon: toAbsoluteUrl('/media/svg/icons/Home/settings_white_24dp.svg'),
        },
        // {
        //   name: "FAQ",
        //   link: `/${origin}/${roles}${Paths.HELP}`,
        //   permittedRoles: ["user", "admin"],
        //   normalUser: true,
        //   procorpUser: true,
        //   icon: toAbsoluteUrl(
        //     "/media/svg/icons/Home/contact_support_white_24dp.svg"
        //   )
        // }
    ];
};
