/** @format */

import React, { useContext } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import * as Pages from 'src/app/pages';
import * as Paths from 'src/app/routes/paths';

import { PrivateRoute } from './Routes';
import { AuthContext } from 'src/app/context';

export const ProCorpRoutes = props => {
    const { path } = props.match;
    const { getSessionStorage, isAuthenticated } = useContext(AuthContext);
    const { roles } = getSessionStorage() || {};
    const role = `/${roles && roles.toLowerCase()}`;

    return (
        <Switch>
            <Redirect exact from="/admin" to={isAuthenticated ? `/admin${Paths.DASHBOARD}` : `/public${Paths.LOGIN}`} />

            {/* user/admin */}
            <PrivateRoute path={path + Paths.DASHBOARD} component={Pages.Dashboard} />
            <PrivateRoute path={path + Paths.STAFF} component={Pages.Staff} />
            <PrivateRoute path={path + Paths.CARDS} component={Pages.Cards} />
            <PrivateRoute path={path + Paths.CORPORATE} component={Pages.Corporate} />
            <PrivateRoute path={path + Paths.STAFF_FORM} component={Pages.StaffForm} />
            <PrivateRoute path={path + Paths.CORPORATE_FORM} component={Pages.CorporateForm} />
            <PrivateRoute path={path + Paths.REGISTRATION} component={Pages.Registration} />
            <PrivateRoute path={path + Paths.EDIT_CORPORATE} component={Pages.EditCorporate} />
            <PrivateRoute path={path + Paths.TEMPLATE_FIELDS} component={Pages.TemplateFields} />
            <PrivateRoute path={path + Paths.SETTINGS} component={Pages.Settings} />
            <PrivateRoute path={path + Paths.ADMIN_ANALYTICS} component={Pages.AdminAnalytics} />
            <PrivateRoute path={path + Paths.GENERAL_ANALYTICS} component={Pages.GeneralAnalytics} />
            <PrivateRoute path={path + Paths.PROFILE} component={Pages.Profile} />
            <PrivateRoute path={path + Paths.USER_FORM} component={Pages.UserForm} />

            <Route path="*/unauthenticated" component={Pages.ErrorsPage} />
            <Route path="*" component={Pages.ErrorsPage} />
        </Switch>
    );
};
