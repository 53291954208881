/** @format */

import React, { useEffect, useContext } from "react";
import { AuthContext } from "src/app/context";

const Logout = () => {
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    signOut();
  }, []);

  return <div />;
};

export default Logout;
