/** @format */

import { gql } from '@apollo/client';

export const ADD_NEW_STAFF = gql`
    mutation($staffProfile: StaffInput!) {
        addNewStaff(staffProfile: $staffProfile) {
            user {
                _id
                name
                username
                password
                phone
                designation
                email
                address
                category
                motto
                tags
                alias
                hidden
                status
                icon
                _corporateId
                type
            }
            corporate {
                _id
                corporateName
            }
        }
    }
`;

export const ADD_NEW_STAFF_WITH_TEMPLATE_FIELDS = gql`
    mutation($staffProfile: StaffInput!) {
        addNewStaffWithTemplateFields(staffProfile: $staffProfile) {
            user {
                _id
                name
                username
                password
                phone
                designation
                email
                address
                category
                motto
                tags
                alias
                hidden
                status
                icon
                _corporateId
                type
            }
            corporate {
                _id
                corporateName
            }
        }
    }
`;

export const ENABLE_DISABLE_STAFF = gql`
    mutation($input: String!) {
        enableDisableUser(input: $input) {
            _id
            status
        }
    }
`;

export const CHECK_CORPORATE_USER_EXIST = gql`
    query($username: String!, $_corporateId: String!) {
        checkCorporateUserExist(username: $username, _corporateId: $_corporateId)
    }
`;

export const CHECK_EMAIL_EXIST = gql`
    query($username: String!) {
        checkUsernameExist(username: $username)
    }
`;

export const ADD_NEW_STAFF_CARD = gql`
    mutation($staffProfile: CardInput!) {
        createCard(staffProfile: $staffProfile) {
            _id
            name
            designation
            company
            email
            address
            phone
            category
            icon
            website
            motto
            tags
            alias
            hidden
            isPrimary
            isPublic
            layout {
                _id
                _defaultTemplateId
                layout
                extraPageLayout
                isPortrait
            }
        }
    }
`;

export const GET_STAFF_PROFILES = gql`
    query($_corporateId: String!) {
        getStaffInfo(_corporateId: $_corporateId) {
            _id
            username
            name
            designation
            address
            email
            phone
            company
            website
            icon
            status
            cardId
            userFields
            role
            createdDt
            accountId
        }
    }
`;

export const UPDATE_STAFF_PROFILE = gql`
    mutation($input: StaffInputUpdate!) {
        updateStaff(input: $input) {
            _id
            name
            username
            password
            phone
            designation
            email
            address
            category
            motto
            tags
            alias
            hidden
            status
            icon
        }
    }
`;

export const UPDATE_STAFF_PROFILE_WITH_TEMPLATE_FIELDS = gql`
    mutation($input: StaffInputUpdate!) {
        updateStaffWithTemplateFields(input: $input) {
            _id
            name
            username
            password
            phone
            designation
            email
            address
            category
            motto
            tags
            alias
            hidden
            status
            icon
            type
        }
    }
`;

export const GET_USER_BY_ID = gql`
    query($_id: String!) {
        getUserWithTemplateFieldsById(_id: $_id) {
            _id
            name
            username
            email
            icon
            type
            phone
            category
            designation
            address
            status
            userFields
        }
    }
`;

export const SEND_CORPORATE_INVITATION = gql`
    mutation($_id: String!) {
        sendCorporateInvitation(_id: $_id) {
            _id
            name
            username
            password
            phone
            designation
            email
            address
            category
            motto
            tags
            alias
            hidden
            status
            icon
        }
    }
`;

export const ADD_CORPORATE_STAFF = gql`
    mutation($staffProfile: StaffInputV2!) {
        addCorporateStaff(staffProfile: $staffProfile) {
            _id
            name
            phone
            designation
            email
            address
            category
            motto
            tags
            alias
            hidden
            status
            icon
            _corporateId
            type
            corporateStatus
        }
    }
`;

export const DELETE_STAFF_PERMANENTLY = gql`
    mutation($input: String!) {
        deleteStaff(input: $input) {
            _id
        }
    }
`;

export const UPDATE_CORPORATE_STAFF = gql`
    mutation($input: StaffInputUpdateV2!) {
        updateCorporateStaff(input: $input) {
            _id
            name
            username
            password
            phone
            designation
            email
            address
            category
            motto
            tags
            alias
            hidden
            status
            icon
            type
        }
    }
`;

export const GET_CORPORATE_USER_BY_ID = gql`
    query($_id: String!) {
        getCorporateUsersById(_id: $_id) {
            _id
            name
            username
            phone
            designation
            role
            email
            address
            category
            motto
            alias
            status
            icon
            createdDt
            updatedDt
            corporateId
        }
    }
`;
