/** @format */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const PageTitle = props => {
    const { title } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: title,
        });
    }, [title]);

    return null;
};
