/** @format */

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import { Input, Button, Boundary } from "src/app/components";

import { forgotUsername } from "src/app/api";
import * as Paths from "src/app/routes/paths";

const ForgotPassword = props => {
  const { history } = props;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const {
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid
  } = useFormik({
    initialValues: { email: "" },
    validationSchema: ForgotPasswordSchema,
    validateOnMount: true,
    validateOnChange: true,
    isInitialValid: false,
    enableReinitialize: true,
    onSubmit: async (values, { _, setSubmitting }) => {
      setSubmitting(true);

      try {
        const res = await forgotUsername(values);
        const { message } = res.data || {};
        setSubmitting(false);
        setSuccess(message);
      } catch (e) {
        setError(e && e.response.data);
      }
    }
  });

  return (
    <>
      {success ? (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h4 className="mb-6">Username is successfully retrieved.</h4>
            <p>
              You will receive a confirmation email from us with further
              instructions.
            </p>
            <Button
              label="Login"
              className=" floating-button btn btn-primary font-weight-bold px-9 py-4 my-12 mx-4"
              onClick={() => history.push(`/public${Paths.LOGIN}`)}
            />
          </div>
        </div>
      ) : (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgot Username</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to retrieve your username
            </div>
          </div>
          <Boundary
            error={error}
            success={success}
            onClose={() => {
              setError("");
              setSuccess("");
            }}
          >
            <Form onSubmit={handleSubmit}>
              <Input
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
              />
              <div className="form-group d-flex flex-wrap flex-center">
                <Row>
                  <Col xs="6">
                    <Button
                      type="submit"
                      label="Submit"
                      loading={isSubmitting}
                      disabled={!isValid}
                      className=" floating-button font-weight-bold px-9 py-4 my-3 mx-4"
                    />
                  </Col>
                  <Col xs="6">
                    <Button
                      label="Cancel"
                      variant="light"
                      className=" floating-button font-weight-bold px-9 py-4 my-3 mx-4"
                      onClick={() => history.push(`/public${Paths.LOGIN}`)}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </Boundary>
        </div>
      )}
    </>
  );
};

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Required field")
});

export default injectIntl(ForgotPassword);
