/** @format */
import '../../css/myStyle.css';

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { PageTitle } from 'src/app/layout';
import {
    Boundary,
    IconButton,
    Button,
    Modal,
    Tag,
    Input,
    InputPhoneNumber,
    Uploader,
    TableWithOnChangeSearch,
    Select,
    SmallButton,
} from 'src/app/components';
import { sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { capitalizeFirstString, useQueryProxy } from 'src/app/helpers/util';
import * as Paths from 'src/app/routes/paths';
import {
    ENABLE_DISABLE_STAFF,
    DELETE_STAFF_PERMANENTLY,
    LIST_ALL_USERS,
    UPDATE_USER,
    UPDATE_CORPORATE_STAFF,
    GET_TEMPLATE_FIELDS_BY_CORPORATE_ID,
    UPDATE_PROFILE_PICTURE,
} from 'src/app/api/graphql';
import { AuthContext } from 'src/app/context';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { STATUS } from '../helpers/misc';
import { uploadFile } from 'src/app/api';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

var Isemail = require('isemail');

const Users = props => {
    const { origin, history } = props;
    const { getSessionStorage, updateUserInfo } = useContext(AuthContext);
    const { _id, corporateList } = getSessionStorage();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedId, setSelectedId] = useState('');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const [rowSelect, setRowSelect] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [action, setAction] = useState(null);
    const [isEnableDisableOpen, setIsEnableDisableOpen] = useState(false);
    const [isCopied, setIsCopied] = useState('');
    const [formList, setFormList] = useState([]);

    const { data: users, loading: loadingUser, refetch: refetchUsers } = useQueryProxy(LIST_ALL_USERS);

    const { refetch: refetchTemplateFields } = useQueryProxy(GET_TEMPLATE_FIELDS_BY_CORPORATE_ID, {
        variables: { corporateId: '' },
    });

    useEffect(() => {
        if (!loadingUser) {
            const fields = [
                {
                    dataType: 'select',
                    main: true,
                    name: 'type',
                    value: '',
                },
                {
                    name: 'name',
                    value: '',
                    dataType: 'text',
                    main: true,
                },
                {
                    name: 'alias',
                    value: '',
                    dataType: 'text',
                    main: true,
                },
                {
                    name: 'designation',
                    value: '',
                    dataType: 'text',
                    main: true,
                },
                {
                    name: 'email',
                    value: '',
                    dataType: 'text',
                    main: true,
                },
                {
                    name: 'phone',
                    value: '',
                    dataType: 'phone',
                    main: true,
                },
            ];
            if (users !== undefined && users !== null && users.length > 0) {
                // let newList = [];
                users.map(user => {
                    if (user.userFields && user.category === 'Corporate') {
                        // console.log(user.userFields)
                        user.templateFields = user.userFields;
                        // setFormList (user.userFields)
                    } else {
                        user.templateFields = JSON.stringify(fields);
                        // setFormList(fields);
                    }
                });
                // console.log(users)

                setTableData(users.sort((a, b) => new Date(b.createdDt) - new Date(a.createdDt)));
            }
        } else {
            setTableData('');
        }
    }, [users]);

    const [updateProfilePicture] = useMutation(UPDATE_PROFILE_PICTURE, {
        onError: e => setError(e.message),
        // onCompleted: () => setSuccess('Successfully updated user'),
    });

    const [updateCorporateUser] = useMutation(UPDATE_CORPORATE_STAFF, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully updated user'),
    });

    const [enableDisableUser] = useMutation(ENABLE_DISABLE_STAFF, {
        onError: e => setError(e.message),
        onCompleted: () => setError(''),
    });

    const [deleteUser] = useMutation(DELETE_STAFF_PERMANENTLY, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully deleted user'),
    });

    const [updateUser] = useMutation(UPDATE_USER, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully updated user'),
    });

    const openEnableDisableModal = (cell, action) => {
        if (action === 1) {
        }
        setAction(action);
        setSelectedId(cell);
        setIsEnableDisableOpen(true);
    };

    const openDeleteModal = (cell, row) => {
        setSelectedId(cell);
        setIsDeleteOpen(true);
        setUserProfile(row);
    };

    const openEditModal = (cell, row) => {
        if (row.category === 'Corporate') {
            let fields = JSON.parse(row.templateFields);
            let corpFields = [];
            let staffFields = [];

            fields = fields.map(field => {
                if (field.name === 'company') {
                    corpFields.push(field);
                } else if (field.name === 'website') {
                    corpFields.push(field);
                } else if (field.name === 'address') {
                    corpFields.push(field);
                } else if (field.name === 'logo') {
                    corpFields.push(field);
                } else {
                    staffFields.push(field);
                }
            });

            setFormList({ user: staffFields, corporate: corpFields });
        } else {
            setFormList({ user: JSON.parse(row.templateFields), corporate: null });
        }

        setUserProfile(row);
        setSelectedId(cell);
        setIsEditOpen(true);
    };

    const onEnableDisable = () => {
        if (selectedId !== undefined && selectedId !== null && selectedId !== '') {
            const data = {
                id: selectedId,
                action: action,
            };
            enableDisableUser({
                variables: { input: JSON.stringify(data) },
                onCompleted: e => {
                    setError('');
                    if (action === 1) {
                        setSuccess('Successfully disable user');
                    }
                    if (action === 2) {
                        setSuccess('Successfully enable user');
                    }
                    // console.log(e)
                    // refetchUsers({ ...searchFilters, pageIndex });
                    refetchUsers();
                    setSelectedId('');
                },
                onError: e => {
                    setSuccess('');
                    setError(e.message);
                },
            });
        }
    };

    const onDeleteUser = () => {
        if (selectedId !== undefined && selectedId !== null && selectedId !== '') {
            const input = {
                id: selectedId,
                corporateId: userProfile.category === 'Corporate' ? userProfile._corporateId : null,
                category: userProfile.category,
            };

            deleteUser({
                variables: { input: JSON.stringify(input) },
                onCompleted: () => {
                    setError('');
                    refetchUsers();
                    setSelectedId('');
                },
                onError: e => {
                    setSuccess('');
                    setError(e.message);
                },
            });
        }
    };

    const onSearch = keyword => {
        if (keyword !== undefined && keyword !== null && keyword !== '') {
            if (users !== undefined && users !== null && users.length > 0) {
                users.filter(x => x.corporateName !== null && x.corporateName !== undefined);
                const newData = users.filter(
                    data =>
                        data.name.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.status.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.accountId.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.username.toLowerCase().includes(keyword.toLowerCase())
                );
                setTableData(newData);
            }
        } else {
            if (users !== undefined && users !== null && users.length > 0) {
                setTableData(users);
            }
        }
    };

    const moveToUserForm = () => {
        history.push(`/${origin}${Paths.USER_FORM}`);
    };

    return (
        <>
            <PageTitle title="Users" />
            <Boundary
                modal
                loading={loadingUser}
                error={error}
                success={success}
                onClick={() => {
                    history.push(`/${origin}${Paths.USERS}`);
                }}
                onClose={() => {
                    setSuccess('');
                    setError('');
                }}>
                <TableWithOnChangeSearch
                    // card
                    keyField="_id"
                    columns={columns(
                        openEnableDisableModal,
                        openEditModal,
                        openDeleteModal,
                        _id,
                        isCopied,
                        setIsCopied
                    )}
                    data={tableData}
                    defaultSizePerPage={10}
                    noResultMsg="No user found."
                    onSearch={onSearch}
                    rowSelect={rowSelect}
                    setRowSelect={setRowSelect}
                    showCheckbox={false}
                    searchExtension={
                        <>
                            <Button
                                label="Add User"
                                className=" floating-button font-weight-bold px-9 mr-1"
                                onClick={() => {
                                    moveToUserForm();
                                }}
                                alignItems="center"
                                alignSelf="center"
                            />
                        </>
                    }
                />
            </Boundary>
            <DeleteStaffModal onDelete={onDeleteUser} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
            <EnableDisableModal
                onEnableDisable={onEnableDisable}
                isOpen={isEnableDisableOpen}
                setIsOpen={setIsEnableDisableOpen}
                action={action}
            />
            <EditUserModal
                isOpen={isEditOpen}
                setIsOpen={setIsEditOpen}
                selectedId={selectedId}
                users={users}
                userProfile={userProfile}
                updateUser={updateUser}
                updateCorporateUser={updateCorporateUser}
                setSuccess={setSuccess}
                setError={setError}
                setSelectedId={setSelectedId}
                refetchUsers={refetchUsers}
                setUserProfile={setUserProfile}
                formList={formList}
                setFormList={setFormList}
                _id={_id}
                refetchTemplateFields={refetchTemplateFields}
                updateUserInfo={updateUserInfo}
                corporateList={corporateList}
                updateProfilePicture={updateProfilePicture}
            />
        </>
    );
};

const DeleteStaffModal = props => {
    const { onDelete, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Delete User"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: onDelete,
            }}>
            Are you sure to permanently delete this user?
        </Modal>
    );
};

const EnableDisableModal = props => {
    const { onEnableDisable, isOpen, setIsOpen, action } = props;
    return (
        <Modal
            show={isOpen}
            title={action === 1 ? 'Disable User' : 'Enable User'}
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: onEnableDisable,
            }}>
            Are you sure?
        </Modal>
    );
};

const EditUserModal = props => {
    const {
        isOpen,
        setIsOpen,
        selectedId,
        users,
        userProfile,
        updateUser,
        updateCorporateUser,
        setSuccess,
        setError,
        setSelectedId,
        refetchUsers,
        setUserProfile,
        user = {},
        formList,
        setFormList,
        _id,
        updateUserInfo,
        updateProfilePicture,
        refetchTemplateFields,
    } = props;

    let onEdit = false;
    let individualFormList = formList.user;
    const corporateFormList = formList.corporate;

    useEffect(() => {
        if (userProfile.userFields !== undefined && userProfile.userFields !== null) {
            const fields = JSON.parse(userProfile.userFields);

            let newTempValue = formList.user;
            fields.map(f => {
                const name = f.name;
                let value = f.value;

                if (newTempValue && newTempValue.length > 0) {
                    newTempValue = newTempValue.map(v => {
                        if (v.name === name) {
                            if (value === '') {
                                value = v.value;
                            }

                            if (value === '') {
                                if (
                                    userProfile[v.name] !== undefined &&
                                    userProfile[v.name] !== null &&
                                    userProfile[v.name] !== ''
                                ) {
                                    let val = userProfile[v.name];
                                    value = val;
                                }
                            }

                            return {
                                ...v,
                                name: name,
                                value: value,
                            };
                        } else {
                            return {
                                ...v,
                                name: v.name,
                                value: v.value,
                            };
                        }
                    });
                }
            });
            setFormList({ user: newTempValue, corporate: corporateFormList });
            individualFormList = newTempValue;
        } else {
            let newTempValue = formList.user;

            if (newTempValue && newTempValue.length > 0) {
                newTempValue = newTempValue.map(v => {
                    let value = userProfile[v.name];

                    if (value === '') {
                        if (
                            userProfile[v.name] !== undefined &&
                            userProfile[v.name] !== null &&
                            userProfile[v.name] !== ''
                        ) {
                            let val = userProfile[v.name];
                            value = val;
                        }
                    }

                    return {
                        ...v,
                        name: v.name,
                        value: value,
                    };
                });
            }

            setFormList({ user: newTempValue, corporate: corporateFormList });
            individualFormList = newTempValue;
        }
    }, [userProfile]);

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: selectedId ? userProfile : user,
        validationSchema: userProfileInputSchema(),
        validateOnMount: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async values => {
            const file =
                values.profilePicture instanceof File ? await uploadFile(values.profilePicture) : values.profilePicture;
            individualFormList = await Promise.all(
                individualFormList.map(async (z, i) => {
                    const { name, value, dataType } = z;

                    if (dataType === 'image' && value instanceof File) {
                        const fileId = await uploadFile(value);
                        // individualFormList[i].value = fileId;
                        return {
                            ...z,
                            value: fileId,
                        };
                    } else {
                        return {
                            ...z,
                        };
                    }
                })
            );

            // let templateFields;
            // if (user.category === 'Corporate') {

            // }

            if (selectedId !== undefined || selectedId !== null || selectedId !== '') {
                users.map(async user => {
                    if (user.category === 'Personal' && selectedId === user._id) {
                        const input = {
                            _id: selectedId,
                            name: values.name,
                            designation: values.designation,
                            email: values.email,
                            phone: values.phone ? values.phone : '',
                            address: values.address,
                            alias: values.alias,
                            type: parseInt(values.type),
                            // profilePicture: file,
                        };

                        updateUser({
                            variables: { input: input },
                            onCompleted: async e => {
                                setError('');
                                if (file !== undefined && file !== null && file !== '') {
                                    await updateProfilePicture({
                                        variables: { _userId: selectedId, icon: file },
                                    });
                                }
                                if (selectedId === _id) {
                                    const userInfo = e.updateUser;
                                    updateUserInfo(userInfo);
                                }
                                refetchUsers();

                                setSuccess('Successfully updated user');
                                setSelectedId('');
                                setIsOpen(false);
                            },
                            onError: e => {
                                setSuccess('');
                                setError('Unable to update user');
                            },
                        });
                    } else if (user.category === 'Corporate' && selectedId === user._id) {
                        const fieldsId = await refetchTemplateFields({ corporateId: userProfile._corporateId });
                        const templateFields = {
                            templateFieldsId: fieldsId,
                            fields: [...individualFormList, ...corporateFormList],
                        };
                        // console.log(userProfile.templateFields, templateFields)
                        const input = {
                            _id: selectedId,
                            _corporateId: user._corporateId,
                            templateFields: JSON.stringify(templateFields),
                        };

                        updateCorporateUser({
                            variables: { input: input },
                            onCompleted: async e => {
                                if (selectedId === _id) {
                                    const userInfo = e.updateCorporateStaff;
                                    await updateUserInfo(userInfo);
                                }
                                setError('');
                                refetchUsers();
                                setSuccess('Successfully updated user');
                                setSelectedId('');
                                setIsOpen(false);
                            },
                            onError: e => {
                                setSuccess('');
                                setError('Unable to update user');
                            },
                        });
                    }
                });
            }
        },
    });

    const onChange = e => {
        onEdit = true;
        if (e.target !== undefined) {
            const name = e.target.name;
            var value = e.target.value;

            if (name === 'type') {
                value = parseInt(e.target.value);
            }

            let newTempValue = individualFormList;

            if (newTempValue.length > 0) {
                let exist = false;
                newTempValue = newTempValue.map(v => {
                    if (v.name === name) {
                        v.value = value;
                        exist = true;
                        return {
                            ...v,
                            name: name,
                            value: value,
                        };
                    } else {
                        return {
                            ...v,
                            name: v.name,
                            value: v.value,
                        };
                    }
                });
            } else {
                newTempValue = [
                    {
                        name: name,
                        value: value,
                    },
                ];
            }

            setFieldValue(name, value);
            individualFormList = newTempValue;
        }
    };

    const onFileChange = (name, value) => {
        let newTempValue = individualFormList;

        if (newTempValue.length > 0) {
            let exist = false;
            newTempValue = newTempValue.map(v => {
                if (v.name === name) {
                    v.value = value;
                    exist = true;
                    return {
                        ...v,
                        name: name,
                        value: value,
                    };
                } else {
                    return {
                        ...v,
                        name: v.name,
                        value: v.value,
                    };
                }
            });
        } else {
            newTempValue = [
                {
                    name: name,
                    value: value,
                },
            ];
        }

        setFieldValue(name, value);
        individualFormList = newTempValue;
    };

    const handleKeyDown = async event => {
        // Check if backspace/delete key was pressed
        if (event.keyCode === 8) {
            let value = event.target.value;
            const name = event.target.name;

            let newTempValue = individualFormList;

            if (value !== undefined && value.length <= 4) {
                value = null;
            }

            if (newTempValue.length > 0) {
                let exist = false;
                newTempValue = newTempValue.map(v => {
                    if (v.name === name) {
                        v.value = value;
                        exist = true;

                        return {
                            ...v,
                            name: name,
                            value: value,
                        };
                    } else {
                        return {
                            ...v,
                            name: v.name,
                            value: v.value,
                        };
                    }
                });
            } else {
                newTempValue = [
                    {
                        name: name,
                        value: value,
                    },
                ];
            }

            setFieldValue(name, value);
            individualFormList = await newTempValue;
        }
    };

    const corpRoles = [
        { type: 'Speedcard Admin', val: 1 },
        { type: 'Corporate Admin', val: 2 },
        { type: 'Corporate User', val: 3 },
    ];

    const userRoles = [
        { type: 'Speedcard Admin', val: 1 },
        { type: 'Speedcard User', val: 4 },
    ];

    return (
        <Modal
            show={isOpen}
            title="Edit User"
            // onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Cancel',
                onClick: () => {
                    setIsOpen(!isOpen);
                    setUserProfile({});
                },
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                onClick: handleSubmit,
            }}>
            <Form>
                {values.category === 'Personal' ? (
                    <>
                        {values._id === _id ? (
                            ''
                        ) : (
                            <Select
                                name="type"
                                label="Role"
                                placeholder="Select Role"
                                value={values.type}
                                options={userRoles}
                                getOptionLabel={option => option.type}
                                getOptionValue={option => option.val}
                                getDisabled={option => option.disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabledPlaceholder={true}
                                error={touched.type && errors.type}
                                // error={touched.propertyType && errors.propertyType}
                            />
                        )}
                        <Input
                            mandatory
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && errors.name}
                            placeholder="Enter name"
                        />
                        <InputPhoneNumber
                            disabled={values.username === values.phone ? true : false}
                            name="phone"
                            label="Phone"
                            // description="Phone number of primary contact"
                            value={values.phone}
                            onChange={setFieldValue}
                            onBlur={handleBlur}
                            error={touched.phone && errors.phone}
                        />
                        <Input
                            disabled={values.username === values.email ? true : false}
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && errors.email}
                            placeholder="Enter email"
                        />
                        <Input
                            name="designation"
                            label="Designation"
                            value={values.designation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.designation && errors.designation}
                            placeholder="Enter designation"
                        />
                        <Input
                            name="alias"
                            label="Alias"
                            value={values.alias}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.alias && errors.alias}
                            placeholder="Enter designation"
                        />
                        <Input
                            name="address"
                            label="Address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.address && errors.address}
                            placeholder="Enter address"
                        />
                        <Uploader
                            width={450}
                            previewImage
                            label="Profile picture"
                            name="profilePicture"
                            value={values.profilePicture}
                            onChange={f => setFieldValue('profilePicture', f)}
                            onBlur={handleBlur}
                            error={touched.profilePicture && errors.profilePicture}
                        />
                    </>
                ) : (
                    <Tabs defaultActiveKey="personal" className="mb-7">
                        <Tab eventKey="personal" title="Personal">
                            {individualFormList &&
                                individualFormList.map((x, i) => {
                                    let value =
                                        individualFormList.length > 0
                                            ? individualFormList.filter(f => f.name === x.name)[0].value
                                            : '';

                                    const dataType = x.dataType;
                                    if (
                                        dataType === 'text' ||
                                        dataType === 'link' ||
                                        dataType === 'email' ||
                                        dataType === 'address'
                                    ) {
                                        return (
                                            <Input
                                                mandatory={x.name === 'name' ? true : false}
                                                disabled={
                                                    userProfile['username'] !== undefined
                                                        ? Isemail.validate(userProfile['username'])
                                                            ? x.name === 'email'
                                                                ? true
                                                                : false
                                                            : false
                                                        : false
                                                }
                                                name={x.name}
                                                label={capitalizeFirstString(x.name)}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={handleBlur}
                                                error={x.name === 'name' ? touched.name && errors.name : null}
                                                placeholder={x.name}
                                                key={x.name}
                                            />
                                        );
                                    } else if (dataType === 'select') {
                                        return (
                                            <>
                                                {userProfile['_id'] === _id ? (
                                                    ''
                                                ) : (
                                                    <Select
                                                        name={x.name}
                                                        label="Role"
                                                        placeholder="Select Role"
                                                        value={value}
                                                        options={corpRoles}
                                                        getOptionLabel={option => option.type}
                                                        getOptionValue={option => option.val}
                                                        onChange={onChange}
                                                        onBlur={handleBlur}
                                                        key={x.name}
                                                        // error={touched.roleType && errors.roleType}
                                                        // // error={touched.propertyType && errors.propertyType}
                                                    />
                                                )}
                                            </>
                                        );
                                    } else if (dataType === 'phone') {
                                        return (
                                            <InputPhoneNumber
                                                disabled={
                                                    userProfile.username !== undefined
                                                        ? x.name === 'phone'
                                                            ? userProfile.username === value
                                                                ? true
                                                                : false
                                                            : false
                                                        : false
                                                }
                                                name={x.name}
                                                label={capitalizeFirstString(x.name)}
                                                // description="Phone number of primary contact"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={handleBlur}
                                                error={touched.phone && errors.phone}
                                                key={x.name}
                                                onKeyDown={handleKeyDown}
                                            />
                                        );
                                    } else if (dataType === 'image') {
                                        return (
                                            <Uploader
                                                name={x.name}
                                                label={capitalizeFirstString(x.name)}
                                                description="*Only image files are accepted."
                                                key={x.name}
                                                width={450}
                                                previewImage
                                                value={value}
                                                onChange={f => onFileChange(x.name, f)}
                                                onBlur={handleBlur}
                                                // error={touched.logo && errors.logo}
                                            />
                                        );
                                    }
                                })}
                        </Tab>
                        <Tab eventKey="corporate" title="Corporate">
                            {corporateFormList &&
                                corporateFormList.map((x, i) => {
                                    let value =
                                        corporateFormList.length > 0
                                            ? corporateFormList.filter(f => f.name === x.name)[0].value
                                            : '';

                                    const dataType = x.dataType;

                                    if (dataType === 'text' || dataType === 'link' || dataType === 'address') {
                                        return (
                                            <Input
                                                style={{ color: 'grey' }}
                                                disabled
                                                name={x.name}
                                                label={capitalizeFirstString(x.name)}
                                                value={value}
                                                placeholder={x.name}
                                                key={x.name}
                                            />
                                        );
                                    } else if (dataType === 'phone' || dataType === 'phoneNum') {
                                        return (
                                            <InputPhoneNumber
                                                disabled
                                                name={x.name}
                                                label={capitalizeFirstString(x.name)}
                                                value={value}
                                                key={x.name}
                                            />
                                        );
                                    } else if (dataType === 'image') {
                                        return (
                                            <Uploader
                                                disabled={true}
                                                name={x.name}
                                                label={capitalizeFirstString(x.name)}
                                                key={x.name}
                                                width={450}
                                                previewImage
                                                value={value}
                                            />
                                        );
                                    }
                                })}
                        </Tab>
                    </Tabs>
                )}
            </Form>
        </Modal>
    );
};

const columns = (openEnableDisableModal, openEditModal, openDeleteModal, _id, isCopied, setIsCopied) => [
    {
        dataField: 'name',
        text: 'Name',
        sort: true,
        editable: false,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{row.name}</span>
                </div>
            );
        },
    },
    {
        dataField: 'username',
        text: 'Username',
        sort: true,
        editable: false,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{row.username}</span>
                </div>
            );
        },
    },
    {
        dataField: 'accountId',
        text: 'Account Id',
        sort: true,
        copyColumnsToClipboard: true,
        editable: false,
        sortCaret: sortCaret,
        headerSortingClasses,
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                setIsCopied(row.accountId);
                setTimeout(() => setIsCopied(''), 3000);
            },
        },
        formatter: (cell, row) => {
            const copyToClipboard = text => {
                navigator.clipboard.writeText(text);
            };

            return (
                <div className="d-flex" onClick={() => copyToClipboard(cell)}>
                    <SmallButton
                        className="ml-2"
                        onClick={() => copyToClipboard(cell)}
                        iconUrl="/media/svg/icons/Home/copy.svg"
                        noBg></SmallButton>
                    <span className="row-valign-center ml-1">{row.accountId}</span>
                    {isCopied !== '' && isCopied === row.accountId ? (
                        <span className="text-success ml-2">Copied!</span>
                    ) : (
                        ''
                    )}
                </div>
            );
        },
    },
    {
        dataField: 'category',
        text: 'Category',
        sort: true,
        editable: false,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            // console.log(row)
            var val;
            if (row.category === 'Corporate' && row.company !== '' && row.company !== undefined) {
                val = row.category + ' (' + row.company + ')';
            } else {
                val = row.category;
            }
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{val}</span>
                </div>
            );
        },
    },
    {
        dataField: 'type',
        text: 'Role',
        sort: true,
        editable: false,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            if (parseInt(row.type) === 1) {
                var roleName = 'SpeedCard Admin';
            } else if (parseInt(row.type) === 2) {
                var roleName = 'Corporate Admin';
            } else if (parseInt(row.type) === 3) {
                var roleName = 'Corporate User';
            } else {
                var roleName = 'Personal User';
            }
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{roleName}</span>
                </div>
            );
        },
    },
    {
        dataField: 'status',
        text: 'Status',
        sort: true,
        editable: false,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => (
            <div className="d-flex">
                <span className="row-valign-center">
                    <Tag label={cell} />
                </span>
            </div>
        ),
    },
    {
        dataField: '_id',
        text: 'Action',
        editable: false,
        formatter: (cell, row) => {
            return (
                <div className="d-flex">
                    <span>
                        <IconButton
                            title="Edit"
                            type="warning"
                            onClick={() => openEditModal(cell, row)}
                            iconUrl="/media/svg/icons/Home/edit-solid.svg"
                            className="mr-3"
                        />
                    </span>
                    {cell !== _id && row.status !== STATUS.inactive && row.status !== STATUS.pending ? (
                        <span>
                            <IconButton
                                title="Disable"
                                type="dark"
                                onClick={() => openEnableDisableModal(cell, 1)}
                                iconUrl="/media/svg/icons/Home/disable-user.svg"
                                className="mr-3"
                            />
                        </span>
                    ) : cell === _id && row.status !== STATUS.inactive ? (
                        <span>
                            <IconButton
                                disabled={true}
                                title="Disable"
                                type="dark"
                                onClick={() => openEnableDisableModal(cell, 1)}
                                iconUrl="/media/svg/icons/Home/disable-user.svg"
                                className="mr-3"
                            />
                        </span>
                    ) : (
                        ''
                    )}
                    {row.status === STATUS.inactive ? (
                        <>
                            <span>
                                <IconButton
                                    title="Enable"
                                    type="dark"
                                    onClick={() => openEnableDisableModal(cell, 2)}
                                    iconUrl="/media/svg/icons/Home/enable-user.svg"
                                    className="mr-3"
                                />
                            </span>
                            <span>
                                <IconButton
                                    title="Delete"
                                    type="danger"
                                    onClick={() => openDeleteModal(cell, row)}
                                    iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                                    className="mr-3"
                                />
                            </span>
                        </>
                    ) : row.status === STATUS.pending ? (
                        <span>
                            <IconButton
                                title="Delete"
                                type="danger"
                                onClick={() => openDeleteModal(cell, row)}
                                iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                                className="mr-3"
                            />
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            );
        },
    },
];

const userProfileInputSchema = () => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            name: Yup.string().required('Required field'),
            designation: Yup.string().nullable(),
            email: Yup.string().nullable(),
            phone: Yup.string().nullable(),
            address: Yup.string().nullable(),
            company: Yup.string().nullable(),
            category: Yup.string().nullable(),
            motto: Yup.string().nullable(),
            tags: Yup.string().nullable(),
            alias: Yup.string().nullable(),
        });
    });
};

export default Users;
