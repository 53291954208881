/**
 * /* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef
 *
 * @format
 */

import React, { useContext } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';

import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import * as Paths from 'src/app/routes/paths';

import { AuthContext } from 'src/app/context';

export const QuickUser = () => {
    const currentuser = {};
    const history = useHistory();

    const { getSessionStorage } = useContext(AuthContext);
    const { name, roles, email } = getSessionStorage() || {};

    const toggleDrawer = () => {
        const toggle = document.getElementById('kt_quick_user_toggle');
        if (toggle) {
            toggle.click();
        }
    };

    const logoutClick = () => {
        toggleDrawer();
        history.push(`/public${Paths.LOGOUT}`);
    };

    const editProfile = () => {
        toggleDrawer();
        history.push(`/procorp/${roles}${Paths.EDIT_CORPORATE}`);
    };

    const changePassword = () => {
        toggleDrawer();
        history.push(`/procorp/${roles}${Paths.USER_CHANGE_PASSWORD}`);
    };

    return (
        <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">User Profile</h3>
                <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i className="ki ki-close icon-xs text-muted" />
                </a>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center mt-5">
                    <div className="d-flex flex-column">
                        <h4 className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                            {name || 'Full Name'}
                        </h4>
                        <div className="navi my-2">
                            <a className="navi-item">
                                <span className="navi-link p-0 pb-2">
                                    <span className="navi-icon mr-1">
                                        <span className="svg-icon-lg svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    '/media/svg/icons/Communication/Mail-notification.svg'
                                                )}></SVG>
                                        </span>
                                    </span>
                                    <span className="navi-text text-muted text-hover-primary">
                                        {email || 'email@email.com'}
                                    </span>
                                </span>
                            </a>
                        </div>
                        <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>
                            Sign out
                        </button>
                    </div>
                </div>

                <div className="separator separator-dashed my-8" />

                {/*
                {currentuser.access !== MYCORP_LOGIN && (
                    <div>
                        <a
                            className="d-flex align-items-center bg-light-warning rounded p-5 mb-4 text-dark-75 text-hover-warning"
                            onClick={editProfile}>
                            <span className="svg-icon svg-icon-warning mr-5">
                                <SVG
                                    src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')}
                                    className="svg-icon svg-icon-lg"></SVG>
                            </span>
                            <div className="d-flex flex-column mr-2 font-weight-normal font-size-lg">Edit Profile</div>
                        </a>

                        <a
                            className="d-flex align-items-center bg-light-success rounded p-5 mb-4 text-dark-75 text-hover-success"
                            onClick={changePassword}>
                            <span className="svg-icon svg-icon-success mr-5">
                                <SVG
                                    src={toAbsoluteUrl('/media/svg/icons/Home/edit-solid.svg')}
                                    className="svg-icon svg-icon-lg"></SVG>
                            </span>
                            <div className="d-flex flex-column mr-2 font-weight-normal font-size-lg">
                                Change Password
                            </div>
                        </a>
                    </div>
                )}
                */}
            </div>
        </div>
    );
};
