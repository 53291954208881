/** @format */

import React from 'react';
import { OverlayTrigger, ButtonToolbar } from 'react-bootstrap';
import { TransparentButton } from './Button';
import { GrayIcon } from './Icon';

export function CustomDropdown({ children }) {
    return (
        <ButtonToolbar>
            <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom-end"
                overlay={
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '4px',
                            zIndex: 5,
                            boxShadow: '0 0 50px 0 rgba(82, 63, 105, 0.15)',
                        }}>
                        {children}
                    </div>
                }>
                <TransparentButton>
                    <GrayIcon className="ki ki-bold-more-ver" />
                </TransparentButton>
            </OverlayTrigger>
        </ButtonToolbar>
    );
}
