/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { PageTitle } from 'src/app/layout';
import * as Yup from 'yup';
import { sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { Form } from 'react-bootstrap';
import {
    Boundary,
    Input,
    Button,
    Modal,
    InputPhoneNumber,
    Table,
    IconButton,
    Select,
    CardWrapper,
} from 'src/app/components';
import { AuthContext } from 'src/app/context';
import * as Paths from 'src/app/routes/paths';
import { CHECK_EMAIL_EXIST, CREATE_USER } from 'src/app/api/graphql';
import { useQueryProxy } from 'src/app/helpers/util';
import IsEmail from 'isemail';

const UserForm = props => {
    const { origin, history } = props;
    const { getSessionStorage } = useContext(AuthContext);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isSaveOpen, setIsSaveOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState('');
    const [index, setIndex] = useState(null);
    const [count, setCount] = useState(0);
    const [user, setUser] = useState({});

    useEffect(() => {
        const data = sessionStorage.getItem('users');
        if (data) {
            setUsers(JSON.parse(data));
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('users', JSON.stringify(users));
    }, [users]);

    const [createUser] = useMutation(CREATE_USER, {
        onError: error => setError(error.message),
        onCompleted: () => {
            setSuccess('Successfully created user');
        },
    });

    const { refetch } = useQueryProxy(CHECK_EMAIL_EXIST, {
        variables: { username: '' },
    });

    const onCreateUser = () => {
        users.forEach(async user => {
            user.isPreregister = true;
            if (user.roleType === 1 || user.roleType === '1') {
                user.type = parseInt(user.roleType);
            } else {
                delete user.type;
            }
            user.password = '';
            delete user.roleType;
            delete user.email;
            delete user.phone;

            await createUser({
                variables: {
                    input: user,
                },
            });
        });
        setUsers([]);
    };

    const openEditModal = row => {
        let index;
        setSelected(row.username);
        index = users.findIndex(x => x.username === row.username);

        setIsEditOpen(true);
        setUser(users[index]);
        setIndex(index);
    };

    const openDeleteModal = row => {
        setSelected(row.username);
        setIsDeleteOpen(true);
    };

    const onDeleteUser = () => {
        if (selected !== undefined && selected !== null && selected !== '') {
            setUsers(users.filter(a => a.username !== selected));

            setSelected('');
            setCount(count - 1);
        }
    };

    return (
        <>
            <PageTitle title={'New User'} />
            <CardWrapper>
                <div>
                    <h5>Instruction</h5>
                    <p>
                        Preregister new SpeedCard user. Login credentials will be sent to new user upon successful
                        registration.
                    </p>
                </div>
                <br></br>
                <Boundary
                    modal
                    onClick={() => {
                        history.push(`/${origin}${Paths.USERS}`);
                    }}
                    error={error}
                    success={success}
                    onClose={() => {
                        setSuccess('');
                        setError('');
                    }}>
                    <Button
                        buttonIcon={<i className="ki ki-plus icon-sm" />}
                        label="Add New User"
                        className=" floating-button font-weight-bold px-9"
                        onClick={() => setIsEditOpen(true)}
                    />
                    {users.length > 0 ? (
                        <Table keyField="username" columns={columns(openDeleteModal, openEditModal)} data={users} />
                    ) : (
                        ''
                    )}
                </Boundary>
                <br></br>
                {users.length > 0 ? (
                    <Button
                        buttonIcon={<i className="ki ki-plus icon-sm" />}
                        label="Done"
                        className=" floating-button btn-primary"
                        textAlign="right"
                        float="right"
                        onClick={() => {
                            setIsSaveOpen(true);
                        }}
                    />
                ) : (
                    ''
                )}
                <EditUserModal
                    isOpen={isEditOpen}
                    setIsOpen={setIsEditOpen}
                    setUsers={setUsers}
                    selected={selected}
                    index={index}
                    user={user}
                    users={users}
                    setSelected={setSelected}
                    refetch={refetch}
                    setIndex={setIndex}
                    setError={setError}
                    setSuccess={setSuccess}
                />
                <DeleteUserModal onDelete={onDeleteUser} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
                <SaveUserModal onSave={onCreateUser} isOpen={isSaveOpen} setIsOpen={setIsSaveOpen} />
            </CardWrapper>
        </>
    );
};

const SaveUserModal = props => {
    const { onSave, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Add user"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Cancel',
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                onClick: onSave,
            }}>
            Are you sure?
        </Modal>
    );
};

const initialValues = users => {
    let values = {
        ...users,
        name: '',
        phone: '',
        email: '',
        type: '',
        user: '',
        roleType: '',
    };

    return values;
};

const EditUserModal = props => {
    const { selected, isOpen, setIsOpen, setUsers, users, user, setSelected, index, refetch } = props;

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        isValid,
        resetForm,
    } = useFormik({
        initialValues: selected ? user : initialValues(users),
        enableReinitialize: true,
        validationSchema: userInputSchema(user, refetch, users),
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: async values => {
            let temp = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                type: values.type,
                roleType: values.roleType,
                username: values.email !== null && values.email !== '' ? values.email : values.phone,
            };

            if (selected !== undefined && selected !== null && selected !== '') {
                if (index !== undefined && index !== null) {
                    const newArr = [...users];
                    newArr[index] = temp;
                    setUsers(newArr);
                }
            } else {
                let newUser = users.concat(temp);
                setUsers(newUser);
            }
            setSelected('');
            setIsOpen(false);
        },
    });

    const types = [
        { type: 'Email Account', val: 1 },
        { type: 'Mobile Account', val: 2 },
    ];

    const rolesType = [
        { roleType: 'SpeedCard Admin', val: 1 },
        { roleType: 'SpeedCard User', val: 2 },
    ];

    return (
        <Modal
            backdrop="static"
            keyboard={false}
            show={isOpen}
            title={selected ? 'Edit User' : 'Add User'}
            onToggle={() => {
                setIsOpen(!isOpen);
            }}
            cancelButton={{
                show: true,
                label: 'Cancel',
                onClick: () => {
                    setIsOpen(false);
                    resetForm();
                    setSelected('');
                },
            }}
            okButton={{
                show: true,
                label: 'Done',
                disabled: !isValid,
                onClick: handleSubmit,
            }}>
            <Form>
                <>
                    <Input
                        mandatory
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name}
                        placeholder="Enter user name"
                    />
                    <Select
                        mandatory
                        name="roleType"
                        label="Role"
                        placeholder="Select Role"
                        value={values.roleType}
                        options={rolesType}
                        getOptionLabel={option => option.roleType}
                        getOptionValue={option => option.val}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabledplaceholder={`true`}
                        error={touched.roleType && errors.roleType}
                        // error={touched.propertyType && errors.propertyType}
                    />
                    <Select
                        mandatory
                        name="type"
                        label="Account Type"
                        description="**Credentials will be sent to the selected account type"
                        placeholder="Select Account Type"
                        value={values.type}
                        options={types}
                        getOptionLabel={option => option.type}
                        getOptionValue={option => option.val}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // disabledplaceholder={true}
                        error={touched.type && errors.type}
                    />
                    {values.type === '2' ? (
                        <InputPhoneNumber
                            mandatory={values.type === '2' ? true : false}
                            name="phone"
                            label="Phone"
                            // description="Phone number of primary contact"
                            value={values.phone}
                            onChange={setFieldValue}
                            onBlur={handleBlur}
                            error={touched.phone && errors.phone}
                        />
                    ) : (
                        ''
                    )}
                    {values.type === '1' ? (
                        <Input
                            mandatory={values.type === '1' ? true : false}
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && errors.email}
                            placeholder="Enter email"
                        />
                    ) : (
                        ''
                    )}
                </>
            </Form>
        </Modal>
    );
};

const DeleteUserModal = props => {
    const { onDelete, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Delete User"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: id => {
                    onDelete(id);
                },
            }}>
            Do you want to delete this user?
        </Modal>
    );
};

const columns = (openDeleteModal, openEditModal) => [
    {
        dataField: 'name',
        editable: false,
        text: 'Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            return (
                <div className="d-flex">
                    <span className="row-valign-center">{cell}</span>
                </div>
            );
        },
    },
    {
        dataField: 'username',
        editable: false,
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{cell}</span>
                </div>
            );
        },
    },
    {
        dataField: 'roleType',
        editable: false,
        text: 'Role',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            var roleName = '';
            if (cell === 1 || cell === '1') {
                roleName = 'SpeedCard Admin';
            }
            if (cell === 2 || cell === '2') {
                roleName = 'SpeedCard User';
            }
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{roleName}</span>
                </div>
            );
        },
    },
    {
        dataField: 'id',
        editable: false,
        text: 'Action',
        formatter: (cell, row) => (
            <div className="d-flex">
                <span>
                    <IconButton
                        title="Edit"
                        type="primary"
                        onClick={() => openEditModal(row)}
                        iconUrl="/media/svg/icons/Home/edit-solid.svg"
                        className="mr-3"
                    />
                </span>
                <span>
                    <IconButton
                        title="Delete"
                        type="danger"
                        onClick={() => openDeleteModal(row)}
                        iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                        className="mr-3"
                    />
                </span>
            </div>
        ),
    },
];

const userInputSchema = (user, refetch, users) => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            name: Yup.string()
                .nullable()
                .notRequired(),
            type: Yup.string()
                .ensure()
                .required('This field is required'),
            roleType: Yup.string()
                .ensure()
                .required('This field is required'),
            email: Yup.string().when('type', {
                is: '1',
                then: Yup.string()
                    .email('Wrong email format')
                    .test({
                        message: 'Email already exists',
                        test: async values => {
                            let emailExist = false;
                            if (values !== undefined && values !== null) {
                                let res = await refetch({
                                    username: values,
                                });

                                if (user !== [] || user !== undefined) {
                                    if (user.email === values) {
                                        return true;
                                    }
                                }
                                emailExist = res.data[`checkUsernameExist`];

                                users.map(v => {
                                    if (v.email === values) {
                                        emailExist = true;
                                    }
                                });

                                if (emailExist) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    }),
                otherwise: Yup.string()
                    .nullable()
                    .notRequired(),
            }),
            phone: Yup.string().when('type', {
                is: '2',
                then: Yup.string()
                    .required('This field is required')
                    .test({
                        message: 'Phone number already exists',
                        test: async values => {
                            let phoneExist = false;
                            if (values !== undefined && values !== null) {
                                let res = await refetch({
                                    username: values,
                                });

                                if (user !== [] || user !== undefined) {
                                    if (user.phone === values) {
                                        return true;
                                    }
                                }
                                phoneExist = res.data[`checkUsernameExist`];

                                users.map(v => {
                                    if (v.phone === values) {
                                        phoneExist = true;
                                    }
                                });

                                if (phoneExist) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    }),
                otherwise: Yup.string()
                    .nullable()
                    .notRequired(),
            }),
        });
    });
};

export default UserForm;
