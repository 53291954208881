/** @format */
import '../../css/myStyle.css';

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { PageTitle } from 'src/app/layout';
import {
    Boundary,
    TableWithOnChangeSearch,
    IconButton,
    Button,
    Modal,
    Tag,
    Input,
    Select,
    InputPhoneNumber,
    SmallButton,
} from 'src/app/components';
import { sortCaret, headerSortingClasses, setStorage } from 'src/_metronic/_helpers';
import { useQueryProxy } from 'src/app/helpers/util';
import * as Paths from 'src/app/routes/paths';
import { GET_CORPORATE, DELETE_CORPORATE, UPDATE_CORPORATE_INFO } from 'src/app/api/graphql';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { AuthContext } from 'src/app/context';
import IsEmail from 'isemail';
import { CodeSharp } from '@material-ui/icons';

const Corporate = props => {
    const { origin, history } = props;
    const { getSessionStorage, updateCorporateList } = useContext(AuthContext);
    const { corporateId, corporateList } = getSessionStorage();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedId, setSelectedId] = useState('');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [corporate, setCorporate] = useState({});
    const [rowSelect, setRowSelect] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [corporateKey, setCorporateKey] = useState('');
    const { data: corporates, loading, refetch: refetch } = useQueryProxy(GET_CORPORATE);

    useEffect(() => {
        if (!loading) {
            if (corporates !== undefined && corporates !== null && corporates.length > 0) {
                var newCorplist = [];
                corporates.map(corp => {
                    corp.corporateId = corp._id;
                    if (corp.adminUsername !== null && corp.adminUsername !== undefined && corp.adminUsername !== '') {
                        var isEmail = IsEmail.validate(corp.adminUsername);
                        if (isEmail) {
                            corp.type = '1';
                            corp.email = corp.adminUsername;
                            corp.phone = null;
                        } else {
                            corp.type = '2';
                            corp.phone = corp.adminUsername;
                            corp.email = '';
                        }
                        newCorplist.push(corp);
                    } else {
                        corp.adminUsername = '';
                        corp.type = null;
                        newCorplist.push(corp);
                    }
                });
                const filtered = newCorplist.filter(list => list.status !== 'INACTIVE' && list.status !== 'DRAFT');
                setTableData(filtered.sort((a, b) => new Date(b.createdDt) - new Date(a.createdDt)));
            }
        }
    }, [corporates]);

    const openDeleteModal = cell => {
        setSelectedId(cell);
        setIsDeleteOpen(true);
    };

    const openEditModal = (cell, row) => {
        setCorporate(row);
        setSelectedId(cell);
        setIsEditOpen(true);
    };

    const [deleteCorporate] = useMutation(DELETE_CORPORATE, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully deleted corporate'),
    });

    const [updateCorporate] = useMutation(UPDATE_CORPORATE_INFO, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully updated corporate'),
    });

    const onDeleteCorporate = () => {
        if (corporateList !== null && corporateList !== undefined) {
            var list = [];
            JSON.parse(corporateList).map(corp => {
                if (corp._id === selectedId) {
                    corp.status = 'INACTIVE';
                    corp._doc.status = 'INACTIVE';
                    list.push(corp);
                } else {
                    list.push(corp);
                }
            });
            updateCorporateList(JSON.stringify(list));
        }

        if (selectedId !== undefined && selectedId !== null && selectedId !== '') {
            deleteCorporate({
                variables: { _id: selectedId },
                onCompleted: e => {
                    setSuccess('Successfully deleted corporate');
                    setError('');
                    refetch();
                    setSelectedId('');
                },
                onError: e => {
                    setSuccess('');
                    setError(e.message);
                },
            });
        }
        // TODO: this is just a workaround...
        // setTimeout(() => {
        //     props.history.push('corporate');
        // }, 2000);
    };

    const onSearch = keyword => {
        if (keyword !== undefined && keyword !== null && keyword !== '') {
            if (corporates !== undefined && corporates !== null && corporates.length > 0) {
                const newData = corporates.filter(
                    data =>
                        data.corporateName.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.status.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.corporateKey.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.corporateId.toLowerCase().includes(keyword.toLowerCase())
                );
                const filtered = newData.filter(list => list.status !== 'INACTIVE' && list.status !== 'DRAFT');
                setTableData(filtered.sort((a, b) => new Date(b.createdDt) - new Date(a.createdDt)));
            }
        } else {
            if (corporates !== undefined && corporates !== null && corporates.length > 0) {
                const filtered = corporates.filter(list => list.status !== 'INACTIVE' && list.status !== 'DRAFT');
                setTableData(filtered.sort((a, b) => new Date(b.createdDt) - new Date(a.createdDt)));
            }
        }
    };

    const moveToStaffForm = () => {
        history.push(`/${origin}${Paths.CORPORATE_FORM}`);
    };

    return (
        <>
            <PageTitle title="Corporate" />
            <Boundary
                modal
                onClick={() => {
                    history.push(`/${origin}${Paths.CORPORATE}`);
                }}
                loading={loading}
                error={error}
                success={success}
                onClose={() => {
                    setSuccess('');
                    setError('');
                }}>
                <TableWithOnChangeSearch
                    // card
                    keyField="corporateId"
                    columns={columns(openDeleteModal, openEditModal, corporateId, setCorporateKey, corporateKey)}
                    data={tableData}
                    defaultSizePerPage={10}
                    noResultMsg="No corporate found."
                    onSearch={onSearch}
                    rowSelect={rowSelect}
                    setRowSelect={setRowSelect}
                    searchExtension={
                        <Button
                            label="Add Corporate"
                            className=" floating-button font-weight-bold px-9"
                            onClick={() => moveToStaffForm()}
                            alignItems="center"
                            alignSelf="center"
                        />
                    }
                />
            </Boundary>
            <DeleteCorporateModal onDelete={onDeleteCorporate} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
            <EditCorporateModal
                isOpen={isEditOpen}
                setIsOpen={setIsEditOpen}
                setCorporate={setCorporate}
                selectedId={selectedId}
                updateCorporate={updateCorporate}
                corporates={corporates}
                corporate={corporate}
                corporateId={corporateId}
                setSuccess={setSuccess}
                setError={setError}
                setSelectedId={setSelectedId}
                refetch={refetch}
            />
        </>
    );
};

const DeleteCorporateModal = props => {
    const { onDelete, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Delete Corporate"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: onDelete,
            }}>
            Do you want to delete this corporate?
        </Modal>
    );
};

const EditCorporateModal = props => {
    const {
        isOpen,
        setIsOpen,
        selectedId,
        corporate,
        updateCorporate,
        setError,
        setSuccess,
        setSelectedId,
        refetch,
        corporateId,
    } = props;
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        resetForm,
        isValid,
    } = useFormik({
        initialValues: corporate,
        validationSchema: corporateInputSchema(),
        validateOnMount: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async values => {
            const input = {
                _id: selectedId,
                registrationNumber: values.registrationNumber,
                corporateName: values.corporateName,
                maxStaff: parseInt(values.maxStaff),
                maxTemplate: parseInt(values.maxTemplate),
                maxAnnex: parseInt(values.maxAnnex),
                adminUsername:
                    values.email !== null || values.email !== '' || values.email !== undefined
                        ? values.email
                        : values.phone,
            };
            if (selectedId === corporateId) {
                const corp = JSON.parse(sessionStorage.getItem('corporate')) || {};
                corp.registrationNumber = input.registrationNumber;
                corp.corporateName = input.corporateName;
                corp.adminUsername = input.adminUsername;
                corp.maxStaff = input.maxStaff;
                corp.maxTemplate = input.maxTemplate;
                corp.maxAnnex = input.maxAnnex;

                sessionStorage.setItem('corporate', JSON.stringify(corp));
            }
            if (selectedId !== undefined || selectedId !== null || selectedId !== '') {
                updateCorporate({
                    variables: { input: input },
                    onCompleted: e => {
                        setError('');
                        refetch();
                        setSelectedId('');
                    },
                    onError: e => {
                        setSuccess('');
                        setError('Unable to update corporate');
                    },
                });
            }
        },
    });

    const types = [
        { type: 'Email Account', val: 1 },
        { type: 'Mobile Account', val: 2 },
    ];

    return (
        <Modal
            backdrop="static"
            keyboard={false}
            show={isOpen}
            title="Edit Corporate"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Cancel',
                onClick: () => {
                    setIsOpen(false);
                    resetForm();
                    setSelectedId('');
                },
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                disabled: !isValid,
                onClick: handleSubmit,
            }}>
            <Form>
                <Input
                    name="corporateName"
                    label="Corporate Name"
                    value={values.corporateName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.corporateName && errors.corporateName}
                />
                <Input
                    name="registrationNumber"
                    label="Corporate Registration Number"
                    value={values.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.registrationNumber && errors.registrationNumber}
                />
                <Select
                    name="type"
                    label="Account Type"
                    // description="**Credentials will be sent to the selected account type"
                    placeholder="Select Account Type"
                    value={values.type}
                    options={types}
                    getOptionLabel={option => option.type}
                    getOptionValue={option => option.val}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // disabledplaceholder={true}
                    error={touched.type && errors.type}
                />
                {values.type === '2' ? (
                    <InputPhoneNumber
                        // mandatory={values.type === '2' ? `true` : `false`}
                        name="phone"
                        label="Admin Phone"
                        // description="Phone number of primary contact"
                        value={values.phone}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        error={touched.phone && errors.phone}
                    />
                ) : (
                    ''
                )}
                {values.type === '1' ? (
                    <Input
                        // mandatory={values.type === '1' ? `true` : `false`}
                        name="email"
                        label="Admin Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && errors.email}
                        placeholder="Enter email"
                    />
                ) : (
                    ''
                )}
                {/* </>  */}

                {/* } */}

                <Input
                    type="number"
                    name="maxStaff"
                    label="Max Staff"
                    width={100}
                    min={1}
                    value={values.maxStaff}
                    onChange={e => {
                        // e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                            setFieldValue('maxStaff', value);
                        }
                    }}
                    onBlur={handleBlur}
                    error={touched.maxStaff && errors.maxStaff}
                />
                <Input
                    type="number"
                    name="maxTemplate"
                    label="Max Template"
                    width={100}
                    min={1}
                    max={3}
                    value={values.maxTemplate}
                    onChange={e => {
                        // e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                            setFieldValue('maxTemplate', value);
                        }
                    }}
                    onBlur={handleBlur}
                    error={touched.maxTemplate && errors.maxTemplate}
                />
                <Input
                    type="number"
                    name="maxAnnex"
                    label="Max Annex Card"
                    width={100}
                    min={1}
                    max={20}
                    value={values.maxAnnex}
                    onChange={e => {
                        // e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                            setFieldValue('maxAnnex', value);
                        }
                    }}
                    onBlur={handleBlur}
                    error={touched.maxAnnex && errors.maxAnnex}
                />
            </Form>
        </Modal>
    );
};

const columns = (openDeleteModal, openEditModal, corporateId, setCorporateKey, corporateKey) => [
    {
        dataField: 'corporateName',
        editable: false,
        text: 'Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.corporateName}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'corporateKey',
        editable: false,
        text: 'Corporate Id',
        sort: true,
        copyColumnsToClipboard: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                setCorporateKey(row.corporateKey);
                setTimeout(() => setCorporateKey(''), 3000);
            },
        },
        formatter: (cell, row) => {
            const copyToClipboard = text => {
                navigator.clipboard.writeText(text);
                // onChange(text);
            };

            const Render = () => {
                return (
                    <div className="d-flex" onClick={() => copyToClipboard(cell)}>
                        <SmallButton className="ml-2" iconUrl="/media/svg/icons/Home/copy.svg" noBg></SmallButton>
                        <span className="row-valign-center ml-1">{row.corporateKey.toUpperCase()}</span>
                        {corporateKey !== '' && corporateKey === row.corporateKey ? (
                            <span className="text-success ml-2">Copied!</span>
                        ) : (
                            ''
                        )}
                    </div>
                );
            };

            return <Render />;
        },
    },
    {
        dataField: 'maxStaff',
        editable: false,
        text: 'Max Staff',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div>
                <div className="text-dark-75 font-size-lg mb-0 text-center">{row.maxStaff}</div>
            </div>
        ),
    },
    {
        dataField: 'maxTemplate',
        editable: false,
        text: 'Max Template',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div>
                <div className="text-dark-75 font-size-lg mb-0 text-center">{row.maxTemplate}</div>
            </div>
        ),
    },
    {
        dataField: 'maxAnnex',
        editable: false,
        text: 'Max Annex',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div>
                <div className="text-dark-75 font-size-lg mb-0 text-center">{row.maxAnnex}</div>
            </div>
        ),
    },
    {
        dataField: 'status',
        editable: false,
        text: 'Status',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => (
            <span className="row-valign-center">
                <Tag label={cell} />
            </span>
        ),
    },
    {
        dataField: '_id',
        editable: false,
        text: 'Action',
        formatter: (cell, row) => (
            <div className="d-flex">
                <span>
                    <IconButton
                        title="Edit"
                        type="warning"
                        onClick={() => openEditModal(cell, row)}
                        iconUrl="/media/svg/icons/Home/edit-solid.svg"
                        className="mr-3"
                    />
                </span>
                <span>
                    {corporateId === cell ? (
                        <IconButton
                            disabled
                            title="Delete"
                            type="danger"
                            onClick={() => openDeleteModal(cell)}
                            iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                            className="mr-3"
                        />
                    ) : (
                        <IconButton
                            title="Delete"
                            type="danger"
                            onClick={() => openDeleteModal(cell)}
                            iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                            className="mr-3"
                        />
                    )}
                </span>
            </div>
        ),
    },
];

const corporateInputSchema = () => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            corporateName: Yup.string().required('Required field'),
            registrationNumber: Yup.string().required('Required field'),
            email: Yup.string().when('type', {
                is: '1',
                then: Yup.string('Wrong email format')
                    .email()
                    .required('Required field'),
                otherwise: Yup.string()
                    .nullable()
                    .notRequired(),
            }),
            phone: Yup.string().when('type', {
                is: '2',
                then: Yup.string().required('Required field'),
                otherwise: Yup.string()
                    .nullable()
                    .notRequired(),
            }),
            maxStaff: Yup.string().required('Required field'),
            maxTemplate: Yup.string()
                .required('Required field')
                .test({
                    message: 'Max template is 3',
                    test: async values => {
                        if (parseInt(values) > 3) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                }),
            maxAnnex: Yup.string()
                .required('Required field')
                .test({
                    message: 'Max annex card is 20',
                    test: async values => {
                        if (parseInt(values) > 20) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                }),
        });
    });
};

export default Corporate;
