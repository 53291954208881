/** @format */

import { fetcher } from './fetcher';

export const registration = async payload => {
    try {
        const response = await fetcher().post('/auth/register', payload);
        return response;
    } catch (e) {
        throw e;
    }
};
