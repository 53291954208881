/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { capitalizeFirstString, useQueryProxy } from 'src/app/helpers/util';
import { AuthContext } from 'src/app/context';
import { PageTitle } from 'src/app/layout';
import {
    Input,
    Uploader,
    Button,
    Boundary,
    InputPhoneNumber,
    FormButtonWrapper,
    FormPanelWrapper,
    FormWrapper,
    CardWrapper,
} from 'src/app/components';

import { GET_USER_BY_ID, UPDATE_CORPORATE_STAFF, UPDATE_USER } from 'src/app/api/graphql';
import { uploadFile } from 'src/app/api';
import * as Paths from 'src/app/routes/paths';

var Isemail = require('isemail');

export default function Profile(props) {
    const { history, origin } = props;
    const { getSessionStorage, updateUserInfo } = useContext(AuthContext);
    const { roles, _id, corporateId } = getSessionStorage();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [fieldsId, setFieldsId] = useState([]);
    const [formList, setFormList] = useState([]);
    const [userProfile, setUserProfile] = useState({});

    let individualFormList = formList;
    let onEdit = false;

    const [updateCorporateStaff] = useMutation(UPDATE_CORPORATE_STAFF, {
        onError: e => setError(e.message),
        // onCompleted: () => setSuccess('Successfully updated staff'),
    });

    const { loading, data: user } = useQueryProxy(GET_USER_BY_ID, {
        variables: { _id: _id },
    });

    const [updateUser] = useMutation(UPDATE_USER, {
        onError: e => setError(e.message),
        // onCompleted: () => setSuccess('Successfully updated user'),
    });

    useEffect(() => {
        if (!loading) {
            if (user !== undefined && user !== null && user !== {}) {
                // console.log(user)

                if (user.phone === null) {
                    user.phone = '';
                }

                setUserProfile(user);

                if (user.userFields !== null) {
                    var fields = JSON.parse(user.userFields);
                    // console.log(fields)
                    setFormList(fields.fields);
                    setFieldsId(fields._id);
                }
            }
        }
    }, [user]);

    useEffect(() => {
        if (userProfile.userFields !== undefined && userProfile.userFields !== null) {
            const fields = formList;
            // console.log(fields);

            let newTempValue = formList;
            fields.map(f => {
                const name = f.name;
                let value = f.value;

                if (newTempValue.length > 0) {
                    newTempValue = newTempValue.map(v => {
                        if (v.name === name) {
                            if (value === '') {
                                value = v.value;
                            }

                            if (value === '') {
                                if (
                                    userProfile[v.name] !== undefined &&
                                    userProfile[v.name] !== null &&
                                    userProfile[v.name] !== ''
                                ) {
                                    let val = userProfile[v.name];
                                    value = val;
                                }
                            }

                            return {
                                ...v,
                                name: name,
                                value: value,
                            };
                        } else {
                            return {
                                ...v,
                                name: v.name,
                                value: v.value,
                            };
                        }
                    });
                }
            });
            setFormList(newTempValue);
            individualFormList = newTempValue;
        } else {
            let newTempValue = formList;

            if (newTempValue.length > 0) {
                newTempValue = newTempValue.map(v => {
                    let value = userProfile[v.name];

                    if (value === '') {
                        if (
                            userProfile[v.name] !== undefined &&
                            userProfile[v.name] !== null &&
                            userProfile[v.name] !== ''
                        ) {
                            let val = userProfile[v.name];
                            value = val;
                        }
                    }

                    return {
                        ...v,
                        name: v.name,
                        value: value,
                    };
                });
            }

            setFormList(newTempValue);
            individualFormList = newTempValue;
        }
    }, [userProfile]);

    const {
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        errors,
        touched,
        isValid,
        isSubmitting,
    } = useFormik({
        initialValues: userProfile,
        validationSchema: profileSchema(),
        validateOnMount: true,
        validateOnChange: true,
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: async values => {
            if (userProfile.category === 'Personal') {
                const fileId = values.icon instanceof File ? await uploadFile(values.icon) : values.icon;
                const input = {
                    _id: _id,
                    name: values.name,
                    designation: values.designation,
                    email: values.email,
                    phone: values.phone ? values.phone : '',
                    address: values.address,
                    alias: values.alias,
                    icon: fileId,
                };

                updateUser({
                    variables: { input: input },
                    onCompleted: async e => {
                        setError('');
                        const userInfo = e.updateUser;
                        await updateUserInfo(userInfo);
                        setSuccess('Successfully updated user');
                    },
                    onError: e => {
                        setSuccess('');
                        setError('Unable to update user');
                    },
                });
            } else {
                individualFormList = await Promise.all(
                    individualFormList.map(async (z, i) => {
                        const { name, value, dataType } = z;

                        if (dataType === 'image' && value instanceof File) {
                            const fileId = await uploadFile(value);
                            // individualFormList[i].value = fileId;
                            return {
                                ...z,
                                value: fileId,
                            };
                        } else {
                            return {
                                ...z,
                            };
                        }
                    })
                );

                individualFormList.push({
                    dataType: 'select',
                    main: true,
                    name: 'type',
                    value: userProfile.type,
                });

                const templateFields = {
                    templateFieldsId: fieldsId,
                    fields: [...individualFormList],
                };

                const input = {
                    _id: _id,
                    _corporateId: corporateId,
                    templateFields: JSON.stringify(templateFields),
                };
                // console.log(input, input.templateFields)
                updateCorporateStaff({
                    variables: {
                        input: input,
                    },
                    onCompleted: async e => {
                        const userInfo = e.updateCorporateStaff;
                        await updateUserInfo(userInfo);
                        setError('');
                        setSuccess('Successfully updated user');
                    },
                    onError: e => {
                        setSuccess('');
                        setError('Unable to update user');
                    },
                });
            }
        },
    });

    const onChange = e => {
        onEdit = true;
        if (e.target !== undefined) {
            const name = e.target.name;
            var value = e.target.value;

            if (name === 'type') {
                value = parseInt(e.target.value);
            }

            let newTempValue = individualFormList;

            if (newTempValue.length > 0) {
                let exist = false;
                newTempValue = newTempValue.map(v => {
                    if (v.name === name) {
                        v.value = value;
                        exist = true;
                        return {
                            ...v,
                            name: name,
                            value: value,
                        };
                    } else {
                        return {
                            ...v,
                            name: v.name,
                            value: v.value,
                        };
                    }
                });
            } else {
                newTempValue = [
                    {
                        name: name,
                        value: value,
                    },
                ];
            }

            setFieldValue(name, value);
            individualFormList = newTempValue;
        }
    };

    const onFileChange = (name, value) => {
        let newTempValue = individualFormList;

        if (newTempValue.length > 0) {
            let exist = false;
            newTempValue = newTempValue.map(v => {
                if (v.name === name) {
                    v.value = value;
                    exist = true;
                    return {
                        ...v,
                        name: name,
                        value: value,
                    };
                } else {
                    return {
                        ...v,
                        name: v.name,
                        value: v.value,
                    };
                }
            });
        } else {
            newTempValue = [
                {
                    name: name,
                    value: value,
                },
            ];
        }

        setFieldValue(name, value);
        individualFormList = newTempValue;
    };

    const handleKeyDown = async event => {
        // Check if backspace/delete key was pressed
        if (event.keyCode === 8) {
            let value = event.target.value;
            const name = event.target.name;

            let newTempValue = individualFormList;

            if (value !== undefined && value.length <= 4) {
                value = null;
            }

            if (newTempValue.length > 0) {
                let exist = false;
                newTempValue = newTempValue.map(v => {
                    if (v.name === name) {
                        v.value = value;
                        exist = true;

                        return {
                            ...v,
                            name: name,
                            value: value,
                        };
                    } else {
                        return {
                            ...v,
                            name: v.name,
                            value: v.value,
                        };
                    }
                });
            } else {
                newTempValue = [
                    {
                        name: name,
                        value: value,
                    },
                ];
            }

            setFieldValue(name, value);
            individualFormList = await newTempValue;
        }
    };

    return (
        <>
            <PageTitle title="Admin Profile" />
            <Boundary
                // loading={loading}
                modal
                onClick={() => {
                    history.push(`/${origin}${Paths.DASHBOARD}`);
                }}
                error={error}
                success={success}
                onClose={() => {
                    setError('');
                    setSuccess('');
                }}>
                <CardWrapper>
                    <div className="text-center text-primary">
                        {roles === 'admin'
                            ? 'Corporate Admin'
                            : roles === 'superadmin'
                            ? 'SpeedCard Admin'
                            : 'SpeedCard Admin - Corporate Admin'}
                    </div>
                </CardWrapper>
                <FormPanelWrapper>
                    <FormWrapper>
                        <Form onSubmit={handleSubmit}>
                            {userProfile.category === 'Personal' ? (
                                <>
                                    <Input
                                        mandatory
                                        name="name"
                                        label="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.name && errors.name}
                                        placeholder="Enter name"
                                    />
                                    <InputPhoneNumber
                                        disabled={values.username === values.phone ? true : false}
                                        name="phone"
                                        label="Phone"
                                        // description="Phone number of primary contact"
                                        value={values.phone}
                                        onChange={setFieldValue}
                                        onBlur={handleBlur}
                                        error={touched.phone && errors.phone}
                                    />
                                    <Input
                                        disabled={values.username === values.email ? true : false}
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.email && errors.email}
                                        placeholder="Enter email"
                                    />
                                    <Input
                                        name="designation"
                                        label="Designation"
                                        value={values.designation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.designation && errors.designation}
                                        placeholder="Enter designation"
                                    />
                                    <Input
                                        name="alias"
                                        label="Alias"
                                        value={values.alias}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.alias && errors.alias}
                                        placeholder="Enter designation"
                                    />
                                    <Input
                                        name="address"
                                        label="Address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.address && errors.address}
                                        placeholder="Enter address"
                                    />
                                    <Uploader
                                        width={450}
                                        previewImage
                                        label="Icon"
                                        name="icon"
                                        value={values.icon}
                                        onChange={f => setFieldValue('icon', f)}
                                        onBlur={handleBlur}
                                        error={touched.icon && errors.icon}
                                    />
                                </>
                            ) : (
                                <>
                                    {individualFormList &&
                                        individualFormList.map((x, i) => {
                                            let value =
                                                individualFormList.length > 0
                                                    ? individualFormList.filter(f => f.name === x.name)[0].value
                                                    : '';

                                            const dataType = x.dataType;
                                            if (
                                                dataType === 'text' ||
                                                dataType === 'link' ||
                                                dataType === 'email' ||
                                                dataType === 'address'
                                            ) {
                                                return (
                                                    <Input
                                                        mandatory={x.name === 'name' ? true : false}
                                                        disabled={
                                                            userProfile['username'] !== undefined
                                                                ? Isemail.validate(userProfile['username'])
                                                                    ? x.name === 'email'
                                                                        ? true
                                                                        : false
                                                                    : false
                                                                : false
                                                        }
                                                        name={x.name}
                                                        label={capitalizeFirstString(x.name)}
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={handleBlur}
                                                        error={x.name === 'name' ? touched.name && errors.name : null}
                                                        placeholder={x.name}
                                                        key={x.name}
                                                    />
                                                );
                                            } else if (dataType === 'phone') {
                                                return (
                                                    <InputPhoneNumber
                                                        disabled={
                                                            userProfile.username !== undefined
                                                                ? x.name === 'phone'
                                                                    ? userProfile.username === value
                                                                        ? true
                                                                        : false
                                                                    : false
                                                                : false
                                                        }
                                                        name={x.name}
                                                        label={capitalizeFirstString(x.name)}
                                                        // description="Phone number of primary contact"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={handleBlur}
                                                        error={touched.phone && errors.phone}
                                                        key={x.name}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                );
                                            } else if (dataType === 'image') {
                                                return (
                                                    <Uploader
                                                        name={x.name}
                                                        label={capitalizeFirstString(x.name)}
                                                        description="*Only image files are accepted."
                                                        key={x.name}
                                                        width={450}
                                                        previewImage
                                                        value={value}
                                                        onChange={f => onFileChange(x.name, f)}
                                                        onBlur={handleBlur}
                                                        // error={touched.logo && errors.logo}
                                                    />
                                                );
                                            }
                                        })}
                                </>
                            )}
                            <FormButtonWrapper>
                                <Button
                                    // type="submit"
                                    className="floating-button mr-2"
                                    label="Cancel"
                                    onClick={() => {
                                        history.push(`/${origin}${Paths.DASHBOARD}`);
                                    }}
                                />
                                <Button
                                    type="submit"
                                    className=" floating-button"
                                    label="Save"
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                />
                            </FormButtonWrapper>
                        </Form>
                    </FormWrapper>
                </FormPanelWrapper>
            </Boundary>
        </>
    );
}

const profileSchema = () => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            name: Yup.string().required('Required field'),
            logo: Yup.mixed().nullable(),
        });
    });
};
