/** @format */

import React, { useContext, useState } from 'react';
import { useQueryProxy } from 'src/app/helpers/util';
import { useMutation } from '@apollo/client';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PageTitle } from 'src/app/layout';
import { ButtonCardWrapper, Modal, Boundary } from 'src/app/components';

import * as Paths from 'src/app/routes/paths';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import { AuthContext } from 'src/app/context';
import { isIntersectionExists } from 'src/app/helpers/util';
import { UPDATE_USER_SETTING, GET_SETTING_BY_ACCOUNTID } from 'src/app/api/graphql';

const { REACT_APP_SPEEDCARD_ORGANIZATION_CODE } = process.env;

const Settings = props => {
    const { origin } = props;
    const { getSessionStorage } = useContext(AuthContext);
    const { roles, corporateId, _id } = getSessionStorage();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTfa, setIsTfa] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const { data: setting, refetch } = useQueryProxy(GET_SETTING_BY_ACCOUNTID, {
        variables: { accountId: _id },
    });

    const [updateTfa] = useMutation(UPDATE_USER_SETTING, {
        onError: e => setError(e.message),
        // onCompleted: () => setSuccess('Successfully updated setting'),
    });

    const onOpen = async tfa => {
        const input = {
            _id: setting._id,
            tfa: tfa,
        };

        updateTfa({
            variables: { input: input },
            onCompleted: () => {
                setSuccess('Successfully updated setting');
                refetch();
            },
            onError: e => {
                setSuccess('');
                setError(e.message);
            },
        });
    };

    const openModal = () => {
        setIsTfa(setting.tfa);
        setIsModalOpen(true);
    };

    return (
        <>
            <PageTitle title="Settings" />
            <Boundary
                modal
                error={error}
                success={success}
                onClose={() => {
                    setSuccess('');
                    setError('');
                }}>
                <Row>
                    {settingList({ origin, roles, openModal }).map((x, i) => {
                        const { permittedRoles, title, description, link, icon, color } = x;

                        if (!isIntersectionExists(permittedRoles, roles.split(','))) return null;

                        return (
                            <Col key={i} xs={12} md={6}>
                                <SettingCard
                                    title={title}
                                    description={description}
                                    link={link}
                                    icon={icon}
                                    color={color}
                                    iconColor="grey"
                                    openModal={openModal}
                                    roles={roles}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Boundary>
            <TestModal
                onOpen={onOpen}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                isTfa={isTfa}
                setIsTfa={setIsTfa}
            />
        </>
    );
};

const SettingCard = ({ title, description, link, icon, fa, small, iconColor, color, openModal }) => {
    const CardContent = () => {
        if (title == 'Multi-factor Authentication') {
            return (
                <ButtonCardWrapper
                    svgIcon={toAbsoluteUrl(icon)}
                    onClick={openModal}
                    title={title}
                    fa={fa}
                    small={small}
                    iconColor={iconColor}
                    description={description}
                    color={color || 'success'}
                />
            );
        } else {
            return (
                <ButtonCardWrapper
                    svgIcon={toAbsoluteUrl(icon)}
                    title={title}
                    fa={fa}
                    small={small}
                    iconColor={iconColor}
                    description={description}
                    color={color || 'success'}
                />
            );
        }
    };

    return (
        <Link to={link} className="dashboard-card">
            <CardContent />
        </Link>
    );
};

const TestModal = props => {
    const { onOpen, isOpen, setIsOpen, isTfa, setIsTfa } = props;
    return (
        <Modal
            show={isOpen}
            dialogClassName="modal-50p"
            title="Settings"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Cancel',
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                onClick: () => {
                    onOpen(isTfa);
                },
            }}>
            <div className="form-group mb-0 row text-center">
                <label className="col-8 col-form-label">Multi-factor authentication:</label>
                <div className="col-4 text-right">
                    <span className="switch switch-sm">
                        <label>
                            <input
                                type="checkbox"
                                name="tfa"
                                checked={isTfa}
                                onChange={e => {
                                    setIsTfa(e.target.checked);
                                }}
                            />
                            <span></span>
                        </label>
                    </span>
                </div>
            </div>
        </Modal>
    );
};

const settingList = props => {
    const { origin } = props;

    return [
        {
            link: `/${origin}${Paths.EDIT_CORPORATE}`,
            title: 'Edit Corporate',
            description: 'Manage your corporate.',
            icon: '/media/svg/icons/Home/pen-solid.svg',
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            color: 'primary',
        },
        {
            link: `/${origin}${Paths.CHANGE_PASSWORD}`,
            title: 'Change Admin Password',
            description: 'Change your password.',
            icon: '/media/svg/icons/Home/user-shield-solid.svg',
            permittedRoles: ['user', 'admin', 'superadmin', 'corporatesuperadmin'],
            color: 'primary',
        },
        {
            title: 'Multi-factor Authentication',
            description: 'Secure authentication method.',
            icon: '/media/svg/icons/Home/user-shield-solid.svg',
            permittedRoles: ['user', 'admin', 'superadmin', 'corporatesuperadmin'],
            color: 'primary',
        },
        {
            title: 'System Settings',
            description: 'Manage system limitation.',
            link: `/${origin}${Paths.SYSTEM_SETTINGS}`,
            icon: '/media/svg/icons/Home/user-shield-solid.svg',
            permittedRoles: ['superadmin', 'corporatesuperadmin'],
            color: 'primary',
        },
        {
            title: 'Users',
            description: 'Manage Speedcard user.',
            link: `/${origin}${Paths.USERS}`,
            icon: '/media/svg/icons/Home/user-shield-solid.svg',
            permittedRoles: ['superadmin', 'corporatesuperadmin'],
            color: 'primary',
        },
    ];
};

export default Settings;
