/** @format */

import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';

export const CenterWrapper = ({ children, className = '' }) => (
    <div className={`d-flex align-items-center justify-content-center ${className}`}>{children}</div>
);

export const CardWrapper = ({ children, className = '' }) => (
    <div className={`card card-custom gutter-b m-0 ${className}`}>
        <div className="p-6">{children}</div>
    </div>
);

export const FormPanelWrapper = ({ children }) => (
    <div className={`card card-custom gutter-b m-0 `}>
        <div className="p-6 procorp-justify-center row">{children}</div>
    </div>
);

export const FormWrapper = ({ children }) => <div className={`col-xl-12 col-xxl-7`}>{children}</div>;

export const ButtonCardWrapper = ({ svgIcon, title, description, iconColor, fa, small, color, onClick }) => {
    const faIconStyle = {
        color: iconColor,
    };
    const cdIconStyle = small ? 'cd-icon-small' : 'cd-icon';

    return (
        <div className="button-card" onClick={onClick}>
            <div className="button-card-body">
                <div className="button-media">
                    <div className="button-media-body button-mr-3">
                        <h3 className={`text-${color} procorp-lookup-title`}>{title}</h3>
                        <span className={`text-${color} button-description-position`}>{description}</span>
                    </div>
                    <span className={`bgl-${iconColor} ${cdIconStyle}`}>
                        {fa ? (
                            <i
                                className={`${svgIcon} ${small ? 'fa-2x' : 'fa-3x'} fa-button-icon`}
                                style={faIconStyle}></i>
                        ) : (
                            <ConvertToSVGFormat svgIcon={svgIcon} color={iconColor} />
                        )}
                    </span>
                </div>
                {/* <span className={`bg-${color} button-line`}></span> */}
            </div>
        </div>
    );
};

export const ButtonCardHeaderWrapper = ({
    svgIcon,
    onClick,
    title,
    description,
    descriptionSize = 18,
    fa,
    smallDescription,
    color,
}) => {
    const faIconStyle = {
        color: color,
    };
    return (
        <div className="button-header-card" onClick={onClick}>
            <div className="button-card-body">
                <div className="button-media">
                    <div className="button-media-body button-mr-3">
                        <h2 style={{ color }}>{title}</h2>
                        <span style={{ color }} className={`button-description-position font-${descriptionSize}`}>
                            {description}
                        </span>
                    </div>
                    <span className={`bgl-${color} cd-icon d-none d-md-block`}>
                        {fa ? (
                            <i className={`${svgIcon} fa-3x fa-button-icon`} style={faIconStyle}></i>
                        ) : (
                            <img className={`card-icon`} src={svgIcon} />
                        )}
                    </span>
                </div>
                <div>
                    <span className={`text-${color} button-small-description-position`}>{smallDescription}</span>
                </div>
            </div>
        </div>
    );
};

export const ButtonCardHeaderWrapperV2 = ({
    svgIcon,
    onClick,
    title,
    description,
    descriptionSize = 18,
    smallDescription,
    fa,
    color,
}) => {
    const faIconStyle = {
        color: color,
    };
    return (
        <div className="button-header-card" onClick={onClick}>
            <div className="button-card-body">
                <div className="button-media text-center">
                    <div className="button-media-body mt-5">
                        <h2
                            style={{
                                color,
                            }}>
                            {title}
                        </h2>
                    </div>
                    <span className={`cd-icon d-none d-md-block`}>
                        {fa ? (
                            <i className={`${svgIcon} fa-3x fa-button-icon`} style={faIconStyle}></i>
                        ) : (
                            <img className={`card-icon`} src={svgIcon} />
                        )}
                    </span>
                </div>
                <div className="text-center pt-4">
                    <span
                        style={{
                            color,
                        }}
                        className={` button-description-position font-${descriptionSize}`}>
                        {description}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const EntityRecordCardWrapper = ({ onClick, entity }) => (
    <div className="entityrecord-card" onClick={onClick}>
        <div className="button-card-body">
            <div className="button-media">
                <div className="button-media-body button-mr-3">
                    <h2 className={`text-success`}>{entity.entityName}</h2>
                    <span className={`text-success button-description-position`}>{entity.description}</span>
                </div>
                {/* <span className={`bgl-success cd-icon d-none d-md-block`}>
                    <img className={`card-icon`} src={svgIcon} />
                </span> */}
            </div>
        </div>
    </div>
);

export const AssetOrgChartWrapper = ({ title, description, color }) => (
    <div className="button-orgchart-card">
        <div className="button-orgchart-card-body">
            <div className="button-media">
                <div className="button-media-body">
                    <span className={`text-${color}`}>{title}</span>
                    {/* <hr />
                    <span className={`text-${color} button-orgchart-description-position`}>{description}</span> */}
                </div>
            </div>
        </div>
    </div>
);

export const CardInnerWrapper = ({ children, className = '' }) => (
    <div className={`d-flex flex-row align-items-center ${className}`}>{children}</div>
);

export const CardContentWrapper = ({ children, className = '' }) => (
    <div className={`d-flex flex-column flex-grow-1 ${className}`}>{children}</div>
);

export const CardToolbarWrapper = ({ children, className = '' }) => (
    <div className={`d-flex align-items-center ${className}`}>
        <div className="card-toolbar">{children}</div>
    </div>
);

export const CardTitle = ({ title, className = '' }) => (
    <div className={`text-dark-75 font-weight-bolder font-size-lg my-1 ${className}`}>{title}</div>
);

export const CardColorTitle = ({ title, className = '' }) => (
    <div className={`text-primary font-weight-bold font-size-lg my-1 ${className}`}>{title}</div>
);

export const CardInfo = ({ heading, item }) => (
    <>
        <span className={`text-muted font-weight-bold font-size-sm`}>
            {heading}
            <span className="text-primary font-weight-bold ml-2">{item}</span>
        </span>
    </>
);

export const CardDescription = ({ description, className = '' }) => (
    <span className={`text-dark-50 font-weight-bold font-size-sm mt-2 ${className}`}>{description}</span>
);

export const CommonLabelWrapper = ({ label, item }) => (
    <div className="my-2">
        <span className="text-muted font-weight-bold mr-2">{label}</span>
        <span className="text-primary">{item}</span>
    </div>
);

export const QuestionListItemWrapper = ({ index, question, answer }) => (
    <div className="my-6">
        <div className="mb-2 font-weight-bold text-muted">
            <span className="mr-2">{index}</span>
            <span>{question}</span>
        </div>
        <div className="text-primary">
            {answer.map((a, i) => (
                <div key={i}>{a.name}</div>
            ))}
        </div>
    </div>
);

export const ErrorWrapper = ({ error }) => <div className="my-4 text-danger">{error}</div>;

export const ChartWrapper = ({ children }) => <div className="mb-6">{children}</div>;

export const FormButtonWrapper = ({ children }) => (
    <div
        style={{
            width: '100%',
            textAlign: 'right',
        }}>
        <hr />
        {children}
    </div>
);

export const SelectWrapper = ({ children, width }) => (
    <div
        style={{
            width,
        }}>
        {children}
    </div>
);

export const Card = ({ title, description, onClick, icon }) => {
    return (
        <a onClick={onClick} className="dashboard-card">
            <CardWrapper>
                <CardInnerWrapper className="py-8 px-6">
                    {icon && (
                        <div className="dashboard-card-icon mr-4">
                            <SVG src={toAbsoluteUrl(icon)} width={50} height={50} title={title} />
                        </div>
                    )}
                    <CardContentWrapper>
                        <CardTitle title={title} />
                        <CardDescription description={description} />
                    </CardContentWrapper>
                </CardInnerWrapper>
            </CardWrapper>
        </a>
    );
};
export const ConvertToSVGFormat = props => {
    const { svgIcon, color } = props;
    let iconColor = color;

    switch (color) {
        case 'success':
            iconColor = 'primary';
            break;
        case 'secondary':
            iconColor = 'success';
            break;
        case 'warning':
            iconColor = 'warning';
            break;
    }

    switch (svgIcon) {
        case '/media/svg/icons/Clothes/Briefcase.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-4x`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z"
                                fill="#000000"
                            />
                            <path
                                d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                            />
                        </g>
                    </svg>
                </span>
            );
        case '/media/svg/icons/Files/Selected-file.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-3x`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                                d="M4.85714286,1 L11.7364114,1 C12.0910962,1 12.4343066,1.12568431 12.7051108,1.35473959 L17.4686994,5.3839416 C17.8056532,5.66894833 18,6.08787823 18,6.52920201 L18,19.0833333 C18,20.8738751 17.9795521,21 16.1428571,21 L4.85714286,21 C3.02044787,21 3,20.8738751 3,19.0833333 L3,2.91666667 C3,1.12612489 3.02044787,1 4.85714286,1 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                            />
                            <path
                                d="M6.85714286,3 L14.7364114,3 C15.0910962,3 15.4343066,3.12568431 15.7051108,3.35473959 L20.4686994,7.3839416 C20.8056532,7.66894833 21,8.08787823 21,8.52920201 L21,21.0833333 C21,22.8738751 20.9795521,23 19.1428571,23 L6.85714286,23 C5.02044787,23 5,22.8738751 5,21.0833333 L5,4.91666667 C5,3.12612489 5.02044787,3 6.85714286,3 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                                fill="#000000"
                                fillRule="nonzero"
                            />
                        </g>
                    </svg>
                </span>
            );
        case '/media/svg/icons/Communication/Group.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-3x`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                                d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                            />
                            <path
                                d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                fill="#000000"
                                fillRule="nonzero"
                            />
                        </g>
                    </svg>
                </span>
            );
        case '/media/svg/icons/Communication/Clipboard-list.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-3x`}>
                    {/* <span className={`svg-icon svg-icon-success svg-icon-3x`}> */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                fill="#000000"
                                opacity="0.3"
                            />
                            <path
                                d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                fill="#000000"
                            />
                            <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1" />
                            <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1" />
                            <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1" />
                            <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1" />
                            <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1" />
                            <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1" />
                        </g>
                    </svg>
                </span>
            );
        case '/media/svg/icons/Communication/Incoming-mail.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-3x`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M5,9 L19,9 C20.1045695,9 21,9.8954305 21,11 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,11 C3,9.8954305 3.8954305,9 5,9 Z M18.1444251,10.8396467 L12,14.1481833 L5.85557487,10.8396467 C5.4908718,10.6432681 5.03602525,10.7797221 4.83964668,11.1444251 C4.6432681,11.5091282 4.77972206,11.9639747 5.14442513,12.1603533 L11.6444251,15.6603533 C11.8664074,15.7798822 12.1335926,15.7798822 12.3555749,15.6603533 L18.8555749,12.1603533 C19.2202779,11.9639747 19.3567319,11.5091282 19.1603533,11.1444251 C18.9639747,10.7797221 18.5091282,10.6432681 18.1444251,10.8396467 Z"
                                fill="#000000"
                            />
                            <path
                                d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                                transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508) "
                            />
                        </g>
                    </svg>
                </span>
            );
        case '/media/svg/icons/Home/edit-solid.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-3x`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                            />
                            <path
                                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                            />
                        </g>
                    </svg>
                </span>
            );
        case '/media/svg/icons/Communication/Outgoing-mail.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-3x`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M5,9 L19,9 C20.1045695,9 21,9.8954305 21,11 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,11 C3,9.8954305 3.8954305,9 5,9 Z M18.1444251,10.8396467 L12,14.1481833 L5.85557487,10.8396467 C5.4908718,10.6432681 5.03602525,10.7797221 4.83964668,11.1444251 C4.6432681,11.5091282 4.77972206,11.9639747 5.14442513,12.1603533 L11.6444251,15.6603533 C11.8664074,15.7798822 12.1335926,15.7798822 12.3555749,15.6603533 L18.8555749,12.1603533 C19.2202779,11.9639747 19.3567319,11.5091282 19.1603533,11.1444251 C18.9639747,10.7797221 18.5091282,10.6432681 18.1444251,10.8396467 Z"
                                fill="#000000"
                            />
                            <path
                                d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                                transform="translate(11.959697, 3.661508) rotate(-90.000000) translate(-11.959697, -3.661508) "
                            />
                        </g>
                    </svg>
                </span>
            );
        case '/media/svg/icons/Communication/Chat6.svg':
            return (
                <span className={`svg-icon svg-icon-${iconColor} svg-icon-3x`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                opacity="0.3"
                                fillRule="evenodd"
                                clip-rule="evenodd"
                                d="M14.4862 18L12.7975 21.0566C12.5304 21.54 11.922 21.7153 11.4386 21.4483C11.2977 21.3704 11.1777 21.2597 11.0887 21.1255L9.01653 18H5C3.34315 18 2 16.6569 2 15V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H14.4862Z"
                                fill="black"
                            />
                            <path
                                fillRule="evenodd"
                                clip-rule="evenodd"
                                d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z"
                                fill="black"
                            />
                        </g>
                    </svg>
                </span>
            );
        default:
            return <img className={`card-icon`} src={svgIcon} />;
    }
};
