/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageTitle } from 'src/app/layout';
import { ButtonCardHeaderWrapperV2, Table, IconButton, Modal, Tag } from 'src/app/components';
import { sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { AuthContext } from 'src/app/context';
import { useQueryProxy } from 'src/app/helpers/util';
import IframeComm from 'react-iframe-comm';
import { GET_CONTACT_BY_CORPORATE_ID } from '../../api/graphql';

const { REACT_APP_SPEEDCARD_URL } = process.env;

const Analytics = props => {
    const { getSessionStorage, getUserInfo } = useContext(AuthContext);
    const userInfo = getUserInfo();
    const { corporateId, corporateName } = getSessionStorage();

    const [isPreviewCardOpen, setIsPreviewCardOpen] = useState(false);
    const [rowSelect, setRowSelect] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [tableData, setTableData] = useState([]);

    // const { data: contactAnalytic, loading, refetch } = useQueryProxy(GET_TOTAL_CONTACT, {
    //     variables: { corporateId: corporateId },
    // });

    const { data: contactData, loadingContactData, refetch } = useQueryProxy(GET_CONTACT_BY_CORPORATE_ID, {
        variables: {
            corporateId: corporateId,
        },
    });

    useEffect(() => {
        if (!loadingContactData) {
            if (contactData !== undefined && contactData !== null && contactData.length > 0) {
                setTableData(contactData);
            } else {
                setTableData([]);
            }
        }
    }, [contactData]);

    // if (!loadingContactData) {
    //     console.log(contactData);
    // }

    var start = new Date();
    start.setHours(0, 0, 0, 0);

    var end = new Date();
    end.setHours(23, 59, 59, 999);

    const getAllData = () => {
        if (contactData !== undefined && contactData !== null && contactData.length > 0) {
            if (tableData !== undefined && tableData !== null && tableData.length > 0) {
                if (contactData.length !== tableData.length) {
                    setTableData(contactData);
                }
            } else {
                setTableData(contactData);
            }
        }
    };

    const getNewData = () => {
        if (contactData !== undefined && contactData !== null && contactData.length > 0) {
            const newData = contactData.filter(
                cd => new Date(cd.contact.createdDt) >= start && new Date(cd.contact.createdDt) <= end
            );
            setTableData(newData);
        }
    };

    const onSearch = keyword => {
        if (keyword !== undefined && keyword !== null && keyword !== '') {
            if (contactData !== undefined && contactData !== null && contactData.length > 0) {
                const newData = contactData.filter(
                    data =>
                        data.card.cardName.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.card.tags.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.owner.name.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.user.name.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.card.company.toLowerCase().includes(keyword.toLowerCase())
                );

                setTableData(newData);
            }
        } else {
            if (contactData !== undefined && contactData !== null && contactData.length > 0) {
                setTableData(contactData);
            }
        }
    };

    const contactStatus = !loadingContactData
        ? [
              {
                  label: `Total Contacts`,
                  status: '',
                  num: contactData.length || 0,
                  color: '#ffffff',
                  icon: 'fas fa-users',
                  fa: true,
                  onClick: () => getAllData(),
              },
              {
                  label: `Total New Contacts`,
                  num:
                      contactData.filter(
                          cd => new Date(cd.contact.createdDt) >= start && new Date(cd.contact.createdDt) <= end
                      ).length || 0,
                  color: '#ffffff',
                  icon: 'fas fa-pen',
                  fa: true,
                  onClick: () => getNewData(),
              },
          ]
        : [];

    const previewAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}preview-card.php?cardId=${selectedId}&&isIframe=true&&corporateId=${corporateId}&&corporateName=${corporateName}`,
        width: '100%',
        height: '500px',
        // scrolling: 'no',
    };

    const postData = {
        ...userInfo,
        corporateName: corporateName,
        corporateId: corporateId,
    };

    const postMessageData = JSON.stringify(postData);

    const onReceiveMessage = e => {
        const data = JSON.parse(e.data);

        if (data !== undefined && data !== null) {
            const message = data.message;

            if (message !== undefined && message !== null) {
                const action = message.action;

                if (action !== undefined && action !== null) {
                    if (action === 0) {
                        // setStep(CARD_STEP.showList);
                    }
                }
            }
        }
    };

    const onReady = () => {};

    const openPreviewCardModal = (cell, row) => {
        // setStaffProfile(row);
        setSelectedId(row.card._id);
        setIsPreviewCardOpen(true);
    };

    return (
        !loadingContactData && (
            <>
                <PageTitle title="Analytics (Contacts)" />
                <Row>
                    {contactStatus.map((x, i) => (
                        <Col key={i}>
                            <ButtonCardHeaderWrapperV2
                                title={x.num}
                                description={x.label}
                                color={x.color}
                                fa={x.fa}
                                svgIcon={x.icon}
                                descriptionSize={20}
                                onClick={x.onClick}
                            />
                        </Col>
                    ))}
                </Row>
                <Table
                    // card
                    keyField="_id"
                    columns={columns(openPreviewCardModal)}
                    data={tableData}
                    defaultSizePerPage={10}
                    noResultMsg="No contact found."
                    onSearch={onSearch}
                    rowSelect={rowSelect}
                    setRowSelect={setRowSelect}
                />
                <PreviewCardStaffModal
                    isOpen={isPreviewCardOpen}
                    setIsOpen={setIsPreviewCardOpen}
                    postMessageData={postMessageData}
                    getAttributes={previewAttributes}
                    onReady={onReady}
                    onReceiveMessage={onReceiveMessage}
                />
            </>
        )
    );
};

const PreviewCardStaffModal = props => {
    const { isOpen, setIsOpen, getAttributes, postMessageData, onReady, onReceiveMessage } = props;

    return (
        <Modal
            show={isOpen}
            title="Preview Card"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: false,
                label: 'Ok',
            }}
            okButton={{
                show: true,
                label: 'Close',
                // onClick: handleSubmit,
            }}>
            <IframeComm
                attributes={getAttributes}
                postMessageData={postMessageData}
                handleReady={onReady}
                handleReceiveMessage={onReceiveMessage}
            />
        </Modal>
    );
};

const columns = openPreviewCardModal => [
    // {
    //     dataField: '',
    //     text: 'id',
    //     sort: true,
    //     sortCaret: sortCaret,
    //     headerSortingClasses,
    //     headerFormatter: (cell, row) => (
    //         <div className="d-flex align-items-center">
    //             <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
    //             <div className="ml-4">
    //                 <input type="checkbox" />
    //             </div>
    //         </div>
    //     ),
    //     formatter: (cell, row) => (
    //         <div className="d-flex align-items-center">
    //             <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
    //             <div className="ml-4">
    //                 <input type="checkbox" />
    //             </div>
    //         </div>
    //     ),
    // },
    {
        dataField: 'card.cardName',
        editable: false,
        text: 'Card Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.card.cardName}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'owner.name',
        editable: false,
        text: 'Card Holder',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.owner.name}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'card.company',
        editable: false,
        text: 'Company',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.card.company}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'user.name',
        editable: false,
        text: 'Added By',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div>
                <div className="text-dark-75 font-size-lg mb-0">
                    {row.user.name} ({row.user.username})
                </div>
            </div>
        ),
    },
    {
        dataField: 'contact.createdDt',
        editable: false,
        text: 'Date',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            var today = new Date(row.contact.createdDt);
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;

            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{today}</span>
                </div>
            );
        },
    },
    {
        dataField: 'status',
        editable: false,
        text: 'Status',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <span className="row-valign-center">
                <Tag label={row.contact.status} />
            </span>
        ),
    },
    {
        dataField: '_id',
        editable: false,
        text: 'Action',
        formatter: (cell, row) => {
            return (
                <div className="d-flex">
                    <span>
                        <IconButton
                            title="Preview Card"
                            type="primary"
                            onClick={() => openPreviewCardModal(cell, row)}
                            iconUrl="/media/svg/icons/Home/business-card-icon.svg"
                            className="mr-3"
                        />
                    </span>
                </div>
            );
        },
    },
];

export default Analytics;
