/** @format */

import { fileFetcher } from "./fetcher";

export const uploadFile = file => {
  const formData = new FormData();
  formData.append(file.name, file);

  return fileFetcher({
    "Content-Type": "multipart/form-data"
  })
    .post("/attachment/upload", formData)
    .then(res => res.data && res.data.ids[0])
    .catch(e => e);
};

export const uploadFileWithName = file => {
  const formData = new FormData();
  formData.append(file.name, file);

  return (
    fileFetcher({
      "Content-Type": "multipart/form-data"
    })
      .post("/attachment/upload", formData)
      // .then(res => res.data && res.data.ids[0])
      .then(res => {
        let id = res.data && res.data.ids[0];
        const data = {
          id: id,
          name: file.name
        };
        return data;
      })
      .catch(e => e)
  );
};

export const downloadFile = fileId => {
  return fileFetcher()
    .get(`/attachment/download?id=${fileId}`)
    .then(res => {
      return res;
    })
    .catch(e => e);
};

let onceATime = false;

export const getBase64 = fileId => {
  if (!onceATime) {
    onceATime = true;
    return fileFetcher()
      .get(`/attachment/download?id=${fileId}`, {
        responseType: "arraybuffer"
      })
      .then(res => {
        onceATime = false;
        return Buffer.from(res.data, "binary").toString("base64");
      })
      .catch(e => e);
  } else {
    return null;
  }
};

export const getFileUrl = fileId => {
  return fileFetcher()
    .get(`/attachment/file-url?id=${fileId}`)
    .then(res => {
      return res;
    })
    .catch(e => e);
};
