/** @format */

import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { PageTitle } from 'src/app/layout';
import { Boundary, FormPanelWrapper, FormWrapper, Input, Form, FormButtonWrapper, Button } from 'src/app/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AuthContext } from 'src/app/context';
import * as Paths from 'src/app/routes/paths';
import { UPDATE_USER } from 'src/app/api/graphql';

const ChangePassword = props => {
    const { history, origin } = props;
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const { getSessionStorage } = useContext(AuthContext);
    const { _id } = getSessionStorage() || {};

    console.log(_id);

    const [updatePassword, updatePasswordCtx] = useMutation(UPDATE_USER, {
        onError: error => setError(error.message),
        onCompleted: () => setSuccess('Successfully changed password'),
    });

    const onSubmit = async values => {
        updatePassword({
            variables: {
                input: {
                    _id: _id,
                    oldPassword: values.oldPassword,
                    password: values.password,
                },
            },
        });
    };

    return (
        <>
            <PageTitle title="Change Admin Password" />
            <Boundary
                modal
                onClick={() => {
                    history.push(`/${origin}${Paths.SETTINGS}`);
                }}
                error={error}
                success={success}
                onClose={() => {
                    setError('');
                    setSuccess('');
                }}>
                <FormPanelWrapper>
                    <FormWrapper>
                        <ChangePasswordForm onSubmit={onSubmit} history={history} origin={origin} />
                    </FormWrapper>
                </FormPanelWrapper>
            </Boundary>
        </>
    );
};

export const ChangePasswordForm = props => {
    const { onSubmit, history, origin } = props;

    const {
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
    } = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: ChangePasswordSchema,
        validateOnMount: true,
        validateOnChange: true,
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: async (values, { _, setSubmitting }) => {
            try {
                setSubmitting(true);
                await onSubmit(values);
                setSubmitting(false);
                resetForm();
            } catch (e) {}
        },
    });

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                password
                name="oldPassword"
                label="Current Password"
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.oldPassword && errors.oldPassword}
            />
            <Input
                password
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password}
            />
            <Input
                password
                name="confirmPassword"
                label="Confirm Password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.confirmPassword && errors.confirmPassword}
            />
            <FormButtonWrapper>
                <Button
                    // type="submit"
                    className="floating-button mr-2"
                    label="Cancel"
                    onClick={() => {
                        history.push(`/${origin}${Paths.SETTINGS}`);
                    }}
                />
                <Button
                    className=" floating-button "
                    label="Save"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid}
                />
            </FormButtonWrapper>
        </Form>
    );
};

export const ChangePasswordSchema = Yup.object({
    oldPassword: Yup.string().required('Required field'),
    password: Yup.string().required('Required field'),
    confirmPassword: Yup.string()
        .required('Required field')
        .when('password', {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        }),
});

export default ChangePassword;
