/**
 * Entry application component used to compose providers and render Routes.
 *
 * @format
 */

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "src/app/config/apollo";

import {
  LayoutSplashScreen,
  MaterialThemeProvider
} from "src/_metronic/layout";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "src/_metronic/layout";
import { MetronicI18nProvider, I18nProvider } from "src/app/i18n";

import { Routes } from "src/app/routes";
import { AuthProvider, FormProvider } from "src/app/context";

// Redux, added by Fernando
import { createStore } from "redux";
import { Provider } from "react-redux";
import layoutReducer from "./reducers/layoutReducer";

const store = createStore(layoutReducer);

export default function App() {
  return (
    <MetronicProvider>
      <DependencyProvider>
        <BrowserRouter>
          <React.Suspense fallback={<LayoutSplashScreen />}>
            <LocalProvider>
              <Provider store={store}>
                <Routes />
              </Provider>
            </LocalProvider>
          </React.Suspense>
        </BrowserRouter>
      </DependencyProvider>
    </MetronicProvider>
  );
}

const MetronicProvider = props => {
  return (
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <MaterialThemeProvider>
              <I18nProvider>{props.children}</I18nProvider>
            </MaterialThemeProvider>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  );
};

const DependencyProvider = props => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

const LocalProvider = props => {
  return (
    <AuthProvider>
      <FormProvider>{props.children}</FormProvider>
    </AuthProvider>
  );
};
