/** @format */

import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PageTitle } from 'src/app/layout';
import { ButtonCardHeaderWrapperV2 } from 'src/app/components/Wrapper';
import { Hero } from 'src/app/components/Dashboard/Hero';
import SVG from 'react-inlinesvg';
import * as Paths from 'src/app/routes/paths';
import { useQueryProxy, isIntersectionExists } from 'src/app/helpers/util';
import { STATUS_STAFF } from 'src/app/helpers/misc';
import { AuthContext } from 'src/app/context';
import { GET_STAFF_PROFILES, GET_CORPORATE } from 'src/app/api/graphql';

const Dashboard = props => {
    const { origin, history } = props;
    const { getSessionStorage } = useContext(AuthContext);
    const { roles, corporateId, corporateName, corporateKey } = getSessionStorage();

    const time = new Date().getHours();
    // const greetingPrefix = time < 12 ? 'Good Morning' : time < 18 ? 'Good Afternoon' : 'Good Night';
    // console.log("selectedCorpId: "+getSelectedCorpId())

    const isProcorp = '';

    const { data: staffProfiles } = useQueryProxy(GET_STAFF_PROFILES, {
        variables: { _corporateId: corporateId },
    });

    const { data: corporateList, loading, refetch } = useQueryProxy(GET_CORPORATE, {
        variables: { id: '' },
    });

    const moveToStaffList = () => {
        history.push(`/${origin}${Paths.STAFF}`);
    };
    const moveToCorporateList = () => {
        history.push(`/${origin}${Paths.CORPORATE}`);
    };
    const moveToLink = x => {
        history.push(x);
    };

    const staffStatusLabels = Array.isArray(staffProfiles)
        ? [
              {
                  label: `Total`,
                  status: '',
                  num:
                      staffProfiles.length > 0
                          ? staffProfiles.filter(x => x !== null && x.status !== STATUS_STAFF.inactive).length
                          : 0,
                  color: '#ffffff',
                  icon: 'fas fa-users',
                  fa: true,
              },
              {
                  label: `Pending`,
                  num:
                      staffProfiles.length > 0
                          ? staffProfiles.filter(x => x !== null && x.status === STATUS_STAFF.pending).length
                          : 0,
                  status: STATUS_STAFF.pending,
                  color: '#ffffff',
                  icon: 'fas fa-pen',
                  fa: true,
              },
              {
                  label: `Active`,
                  num:
                      staffProfiles.length > 0
                          ? staffProfiles.filter(x => x !== null && x.status === STATUS_STAFF.active).length
                          : 0,
                  status: STATUS_STAFF.active,
                  color: '#ffffff',
                  icon: 'fas fa-user-check',
                  fa: true,
              },
              {
                  label: `Inactive`,
                  num:
                      staffProfiles.length > 0
                          ? staffProfiles.filter(x => x !== null && x.status === STATUS_STAFF.inactive).length
                          : 0,
                  status: STATUS_STAFF.inactive,
                  color: '#ffffff',
                  icon: 'fas fa-archive',
                  fa: true,
              },
          ]
        : Array.isArray(corporateList)
        ? [
              {
                  label: `Total`,
                  status: '',
                  num:
                      corporateList.length > 0
                          ? corporateList.filter(x => x !== null && x.status !== STATUS_STAFF.inactive).length
                          : 0,
                  color: '#ffffff',
                  icon: 'fas fa-building',
                  fa: true,
              },
              {
                  label: `Pending`,
                  num:
                      corporateList.length > 0
                          ? corporateList.filter(x => x !== null && x.status === STATUS_STAFF.pending).length
                          : 0,
                  status: STATUS_STAFF.pending,
                  color: '#ffffff',
                  icon: 'fas fa-pen',
                  fa: true,
              },
              {
                  label: `Active`,
                  num:
                      corporateList.length > 0
                          ? corporateList.filter(x => x !== null && x.status === STATUS_STAFF.active).length
                          : 0,
                  status: STATUS_STAFF.active,
                  color: '#ffffff',
                  icon: 'fas fa-building-circle-arrow-right',
                  fa: true,
              },
              {
                  label: `Inactive`,
                  num:
                      corporateList.length > 0
                          ? corporateList.filter(x => x !== null && x.status === STATUS_STAFF.inactive).length
                          : 0,
                  status: STATUS_STAFF.inactive,
                  color: '#ffffff',
                  icon: 'fas fa-archive',
                  fa: true,
              },
          ]
        : [];

    return (
        <>
            <PageTitle title="Dashboard" />
            <>
                <Hero></Hero>
                <div
                    className="card card-custom mx-10 px-10 pt-8 mb-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => (roles === 'superadmin' ? moveToCorporateList() : moveToStaffList())}>
                    <i className="text-dark-65 text-left mb-5">
                        <h4>
                            {roles === 'superadmin' ? '' : corporateName} ({corporateKey})
                        </h4>
                    </i>
                    <Row>
                        {staffStatusLabels.map((x, i) => (
                            <Col key={i}>
                                <ButtonCardHeaderWrapperV2
                                    title={x.num}
                                    description={x.label}
                                    color={x.color}
                                    fa={x.fa}
                                    svgIcon={x.icon}
                                    descriptionSize={20}
                                    onClick={() => (roles === 'superadmin' ? moveToCorporateList() : moveToStaffList())}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className="px-8">
                    <DashboardMenu {...props} isProcorp={isProcorp} roles={roles} moveToLink={moveToLink} />
                </div>
                <br />
            </>
        </>
    );
};

const DashboardMenu = props => {
    const { origin, isProcorp, roles, moveToLink } = props;

    return (
        <Row>
            {dashboardMenuList({ origin, roles }).map((x, i) => {
                const {
                    permittedRoles,
                    title,
                    description,
                    link,
                    icon,
                    color,
                    externalLink,
                    label,
                    procorpUser,
                    fa,
                    normalUser,
                } = x;
                if (
                    !isIntersectionExists(permittedRoles, roles.split(',')) ||
                    !((procorpUser && isProcorp) || (normalUser && !isProcorp))
                )
                    return null;

                return (
                    <DashboardCard
                        title={title}
                        label={label}
                        description={description}
                        link={link}
                        fa={fa}
                        icon={icon}
                        color={color || 'success'}
                        externalLink={externalLink}
                        key={title}
                        moveToLink={moveToLink}
                    />
                );
            })}
        </Row>
    );
};

const DashboardCard = props => {
    const { title, description, link, icon, externalLink, label, color, fa, moveToLink } = props;

    return (
        <>
            <div className="col-lg-6 mt-2">
                <div
                    className="card card-custom p-4 mb-lg-0 h-100 justify-item-center"
                    onClick={() => moveToLink(link)}>
                    <div className="card-body " style={{ cursor: 'pointer' }}>
                        <div className="row">
                            <div className="col-sm-7">
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50 symbol-sm flex-shrink-0">
                                        <div className="symbol-label procorp-symbol-label">
                                            {fa ? (
                                                <i className={`${icon} fa-4x fa-button-icon mr-2`}></i>
                                            ) : (
                                                <SVG src={icon} width={50} height={50} fill={'#11894a'} />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="ml-4 flex-column"
                                        style={{
                                            overflowWrap: 'break-word',
                                            maxHeight: '100%',
                                            minWidth: '75%',
                                            flexWrap: 'wrap',
                                        }}>
                                        <h2 className="text-dark mb-4 d-flex">{title}</h2>
                                        <p className="text-dark-50 line-height-lg">{description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5 d-flex align-items-center justify-content-sm-end">
                                <Link
                                    to={link}
                                    className={`btn font-weight-bolder text-uppercase font-size-lg btn-${color} py-3 px-6`}>
                                    {label}
                                </Link>
                                {/* <a href={toAbsoluteUrl(link)} className={`btn font-weight-bolder text-uppercase font-size-lg btn-${color} py-3 px-6`}>{label}</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const dashboardMenuList = props => {
    const { origin } = props;

    return [
        {
            title: 'Card Template',
            description: 'Click to manage template',
            normalUser: true,
            label: 'Manage',
            link: `/${origin}${Paths.CARDS}`,
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            color: 'primary',
            icon: 'fas fa-address-card',
            fa: true,
        },
        {
            title: 'Staff',
            description: 'Click to add new staff',
            link: `/${origin}${Paths.STAFF_FORM}`,
            normalUser: true,
            label: 'Add',
            icon: 'fas fa-user-alt',
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            color: 'primary',
            fa: true,
        },
        {
            title: 'Analytic',
            description: 'View analytic',
            link: `/${origin}${Paths.ANALYTICS}`,
            normalUser: true,
            label: 'View',
            color: 'primary',
            icon: 'fas fa-chart-line',
            permittedRoles: ['user', 'admin'],
            fa: true,
        },
        {
            title: 'Analytic',
            description: 'View analytic',
            link: `/${origin}${Paths.ADMIN_ANALYTICS}`,
            normalUser: true,
            label: 'View',
            color: 'primary',
            icon: 'fas fa-chart-line',
            permittedRoles: ['corporatesuperadmin', 'superadmin'],
            fa: true,
        },
        {
            title: 'Corporate',
            description: 'View all corporate',
            link: `/${origin}${Paths.CORPORATE}`,
            normalUser: true,
            label: 'View',
            icon: 'fas fa-building',
            permittedRoles: ['superadmin', 'corporatesuperadmin'],
            color: 'primary',
            fa: true,
        },
    ];
};

export default Dashboard;
