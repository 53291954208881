/** @format */

import React from 'react';
import { Button, Row, Accordion as DefaultAccordion } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

export default function CustomAccordion({ defaultActive, eventKey, header, children, headerButton }) {
    const defaultActiveKey = defaultActive ? { defaultActiveKey: eventKey } : {};
    return (
        <DefaultAccordion className="mb-4" {...defaultActiveKey}>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <DefaultAccordion.Toggle
                    as={Row}
                    eventKey={eventKey}
                    className="d-flex flex-grow-1 text-hover-primary cursor-pointer">
                    <div style={{ marginLeft: '12.5px', marginRight: '12.5px' }}>{header}</div>
                </DefaultAccordion.Toggle>
                {headerButton && <div>{headerButton}</div>}
            </div>
            <hr />
            <DefaultAccordion.Collapse eventKey={eventKey}>{children}</DefaultAccordion.Collapse>
        </DefaultAccordion>
    );
}

export const Accordion = ({ defaultActiveKey, accordions }) => {
    const defaultActiveKeyProps = defaultActiveKey ? { defaultActiveKey } : {};
    return (
        <DefaultAccordion className="mb-4" {...defaultActiveKeyProps}>
            {accordions.map((x, i) => (
                <div key={i}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <DefaultAccordion.Toggle
                            as={Row}
                            eventKey={x.eventKey}
                            className="d-flex flex-grow-1 text-hover-primary cursor-pointer">
                            <div style={{ marginLeft: '12.5px', marginRight: '12.5px' }}>{x.header()}</div>
                        </DefaultAccordion.Toggle>
                        {x.headerButton && <div>{x.headerButton}</div>}
                    </div>
                    {!x.noHeaderHr && <hr />}
                    <DefaultAccordion.Collapse eventKey={x.eventKey}>{x.collapse()}</DefaultAccordion.Collapse>
                </div>
            ))}
        </DefaultAccordion>
    );
};

export const BtnToggleAccordion = ({ eventKey, children, className, variant }) => {
    const toggleAccordion = useAccordionToggle(eventKey);
    return (
        <Button onClick={toggleAccordion} className={className} variant={variant}>
            {children}
        </Button>
    );
};
