/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageTitle } from 'src/app/layout';
import { ButtonCardHeaderWrapperV2, Table, IconButton, Modal, TableWithOnChangeSearch } from 'src/app/components';
import { sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { AuthContext } from 'src/app/context';
import { useQueryProxy } from 'src/app/helpers/util';
import IframeComm from 'react-iframe-comm';
import { GET_ANALYTICS } from '../../api/graphql';

const { REACT_APP_SPEEDCARD_URL } = process.env;

const GeneralAnalytics = props => {
    const { getSessionStorage, getUserInfo } = useContext(AuthContext);
    const userInfo = getUserInfo();
    const { corporateId, corporateName } = getSessionStorage();

    const [isPreviewCardOpen, setIsPreviewCardOpen] = useState(false);
    const [rowSelect, setRowSelect] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [tableData, setTableData] = useState([]);
    const [analyticType, setAnalyticType] = useState(0);

    // const { data: contactAnalytic, loading, refetch } = useQueryProxy(GET_TOTAL_CONTACT, {
    //     variables: { corporateId: corporateId },
    // });

    const { data: analyticsData, loading: loadinganalyticsData, refetch } = useQueryProxy(GET_ANALYTICS, {});

    useEffect(() => {
        if (!loadinganalyticsData) {
            if (analyticsData !== undefined && analyticsData !== null && analyticsData.length > 0) {
                setTableData(analyticsData);
            } else {
                let users = analyticsData.users;
                let sortedUsers = users.sort(custom_sort);
                setTableData(sortedUsers);
                setAnalyticType(1);
            }
        }
    }, [analyticsData]);

    // if (!loadinganalyticsData) {
    //     console.log(analyticsData);
    // }

    var start = new Date();
    start.setHours(0, 0, 0, 0);

    var end = new Date();
    end.setHours(23, 59, 59, 999);

    const custom_sort = (a, b) => {
        return new Date(b.createdDt).getTime() - new Date(a.createdDt).getTime();
    };

    const getAllUsers = () => {
        if (
            analyticsData !== undefined &&
            analyticsData !== null &&
            analyticsData.users !== undefined &&
            analyticsData.users !== null &&
            analyticsData.users.length > 0
        ) {
            let users = analyticsData.users;
            let sortedUsers = users.sort(custom_sort);
            setTableData(sortedUsers);
            setAnalyticType(1);
        }
    };

    const getNewUsers = () => {
        if (
            analyticsData !== undefined &&
            analyticsData !== null &&
            analyticsData.users !== undefined &&
            analyticsData.users !== null &&
            analyticsData.users.length > 0
        ) {
            const newData = analyticsData.users.filter(
                cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
            );
            setTableData(newData);
            setAnalyticType(2);
        }
    };

    const getAllCards = () => {
        if (
            analyticsData !== undefined &&
            analyticsData !== null &&
            analyticsData.cards !== undefined &&
            analyticsData.cards !== null &&
            analyticsData.cards.length > 0
        ) {
            let cards = analyticsData.cards;
            let sortedCards = cards.sort(custom_sort);
            setTableData(sortedCards);
            setAnalyticType(3);
        }
    };

    const getNewCards = () => {
        if (
            analyticsData !== undefined &&
            analyticsData !== null &&
            analyticsData.cards !== undefined &&
            analyticsData.cards !== null &&
            analyticsData.cards.length > 0
        ) {
            const newData = analyticsData.cards.filter(
                cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
            );
            setTableData(newData);
            setAnalyticType(4);
        }
    };

    const onSearch = keyword => {
        if (analyticType === 1) {
            if (keyword !== undefined && keyword !== null && keyword !== '') {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.users !== undefined &&
                    analyticsData.users !== null &&
                    analyticsData.users.length > 0
                ) {
                    const newData = analyticsData.users.filter(
                        data =>
                            (data.name !== undefined &&
                                data.name !== null &&
                                data.name !== '' &&
                                data.name.toLowerCase().includes(keyword.toLowerCase())) ||
                            (data.username !== undefined &&
                                data.username !== null &&
                                data.username !== '' &&
                                data.username.toLowerCase().includes(keyword.toLowerCase())) ||
                            (data.email !== undefined &&
                                data.email !== null &&
                                data.email !== '' &&
                                data.email.toLowerCase().includes(keyword.toLowerCase()))
                    );

                    setTableData(newData);
                }
            } else {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.users !== undefined &&
                    analyticsData.users !== null &&
                    analyticsData.users.length > 0
                ) {
                    setTableData(analyticsData.users);
                }
            }
        } else if (analyticType === 2) {
            if (keyword !== undefined && keyword !== null && keyword !== '') {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.users !== undefined &&
                    analyticsData.users !== null &&
                    analyticsData.users.length > 0
                ) {
                    const filterData = analyticsData.users.filter(
                        cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
                    );

                    if (filterData.length > 0) {
                        const newData = filterData.filter(
                            data =>
                                (data.name !== undefined &&
                                    data.name !== null &&
                                    data.name !== '' &&
                                    data.name.toLowerCase().includes(keyword.toLowerCase())) ||
                                (data.username !== undefined &&
                                    data.username !== null &&
                                    data.username !== '' &&
                                    data.username.toLowerCase().includes(keyword.toLowerCase())) ||
                                (data.email !== undefined &&
                                    data.email !== null &&
                                    data.email !== '' &&
                                    data.email.toLowerCase().includes(keyword.toLowerCase()))
                        );

                        setTableData(newData);
                    }
                }
            } else {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.users !== undefined &&
                    analyticsData.users !== null &&
                    analyticsData.users.length > 0
                ) {
                    const filterData = analyticsData.users.filter(
                        cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
                    );
                    setTableData(filterData);
                }
            }
        } else if (analyticType === 3) {
            if (keyword !== undefined && keyword !== null && keyword !== '') {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.cards !== undefined &&
                    analyticsData.cards !== null &&
                    analyticsData.cards.length > 0
                ) {
                    if (
                        analyticsData !== undefined &&
                        analyticsData !== null &&
                        analyticsData.users !== undefined &&
                        analyticsData.users !== null &&
                        analyticsData.users.length > 0
                    ) {
                        const user = analyticsData.users.filter(x =>
                            x.name.toLowerCase().includes(keyword.toLowerCase())
                        )[0];
                        let _userId = '';
                        if (user !== undefined && user !== null) {
                            _userId = user._id;
                        }

                        const newData = analyticsData.cards.filter(
                            data =>
                                (data.cardName !== undefined &&
                                    data.cardName !== null &&
                                    data.cardName !== '' &&
                                    data.cardName.toLowerCase().includes(keyword.toLowerCase())) ||
                                data._userId === _userId
                        );

                        setTableData(newData);
                    }
                }
            } else {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.cards !== undefined &&
                    analyticsData.cards !== null &&
                    analyticsData.cards.length > 0
                ) {
                    setTableData(analyticsData.cards);
                }
            }
        } else if (analyticType === 4) {
            if (keyword !== undefined && keyword !== null && keyword !== '') {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.cards !== undefined &&
                    analyticsData.cards !== null &&
                    analyticsData.cards.length > 0
                ) {
                    const filterData = analyticsData.cards.filter(
                        cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
                    );

                    if (
                        analyticsData !== undefined &&
                        analyticsData !== null &&
                        analyticsData.users !== undefined &&
                        analyticsData.users !== null &&
                        analyticsData.users.length > 0
                    ) {
                        const user = analyticsData.users.filter(x =>
                            x.name.toLowerCase().includes(keyword.toLowerCase())
                        )[0];
                        let _userId = '';
                        if (user !== undefined && user !== null) {
                            _userId = user._id;
                        }

                        if (filterData.length > 0) {
                            const newData = filterData.filter(
                                data =>
                                    (data.cardName !== undefined &&
                                        data.cardName !== null &&
                                        data.cardName !== '' &&
                                        data.cardName.toLowerCase().includes(keyword.toLowerCase())) ||
                                    data._userId === _userId
                            );

                            setTableData(newData);
                        }
                    }
                }
            } else {
                if (
                    analyticsData !== undefined &&
                    analyticsData !== null &&
                    analyticsData.cards !== undefined &&
                    analyticsData.cards !== null &&
                    analyticsData.cards.length > 0
                ) {
                    const filterData = analyticsData.cards.filter(
                        cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
                    );

                    setTableData(filterData);
                }
            }
        }
    };

    const analyticsStatistic = !loadinganalyticsData
        ? [
              {
                  label: `Total Users`,
                  status: '',
                  num: analyticsData.users !== undefined ? analyticsData.users.length || 0 : 0,
                  color: '#ffffff',
                  icon: 'fas fa-users',
                  fa: true,
                  onClick: () => getAllUsers(),
              },
              {
                  label: `Total New Users`,
                  num:
                      analyticsData.users !== undefined
                          ? analyticsData.users.filter(
                                cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
                            ).length || 0
                          : 0,
                  color: '#ffffff',
                  icon: 'fas fa-users',
                  fa: true,
                  onClick: () => getNewUsers(),
              },
              {
                  label: `Total Cards`,
                  status: '',
                  num: analyticsData.cards !== undefined ? analyticsData.cards.length || 0 : 0,
                  color: '#ffffff',
                  icon: 'fas fa-vcard',
                  fa: true,
                  onClick: () => getAllCards(),
              },
              {
                  label: `Total New Cards`,
                  num:
                      analyticsData.cards !== undefined
                          ? analyticsData.cards.filter(
                                cd => new Date(cd.createdDt) >= start && new Date(cd.createdDt) <= end
                            ).length || 0
                          : 0,
                  color: '#ffffff',
                  icon: 'fas fa-vcard',
                  fa: true,
                  onClick: () => getNewCards(),
              },
          ]
        : [];

    const previewAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}preview-card.php?cardId=${selectedId}&&isIframe=true&&corporateId=${corporateId}&&corporateName=${corporateName}`,
        width: '100%',
        height: '500px',
        // scrolling: 'no',
    };

    const postData = {
        ...userInfo,
        corporateName: corporateName,
        corporateId: corporateId,
    };

    const postMessageData = JSON.stringify(postData);

    const onReceiveMessage = e => {
        const data = JSON.parse(e.data);

        if (data !== undefined && data !== null) {
            const message = data.message;

            if (message !== undefined && message !== null) {
                const action = message.action;

                if (action !== undefined && action !== null) {
                    if (action == 0) {
                        // setStep(CARD_STEP.showList);
                    }
                }
            }
        }
    };

    const onReady = () => {};

    const openPreviewCardModal = (cell, row) => {
        // setStaffProfile(row);
        setSelectedId(row._id);
        setIsPreviewCardOpen(true);
    };

    return (
        !loadinganalyticsData && (
            <>
                <PageTitle title="Analytics" />
                <Row>
                    {analyticsStatistic.map((x, i) => (
                        <Col key={i}>
                            <ButtonCardHeaderWrapperV2
                                title={x.num}
                                description={x.label}
                                color={x.color}
                                fa={x.fa}
                                svgIcon={x.icon}
                                descriptionSize={20}
                                onClick={x.onClick}
                            />
                        </Col>
                    ))}
                </Row>
                <TableWithOnChangeSearch
                    // card
                    keyField="_id"
                    columns={
                        analyticType === 1 || analyticType === 2
                            ? columnsUsers(analyticsData)
                            : columnsCards(analyticsData, openPreviewCardModal)
                    }
                    data={tableData}
                    defaultSizePerPage={10}
                    noResultMsg="No contact found."
                    onSearch={onSearch}
                    rowSelect={rowSelect}
                    setRowSelect={setRowSelect}
                />
                <PreviewCardStaffModal
                    isOpen={isPreviewCardOpen}
                    setIsOpen={setIsPreviewCardOpen}
                    postMessageData={postMessageData}
                    getAttributes={previewAttributes}
                    onReady={onReady}
                    onReceiveMessage={onReceiveMessage}
                />
            </>
        )
    );
};

const PreviewCardStaffModal = props => {
    const { isOpen, setIsOpen, getAttributes, postMessageData, onReady, onReceiveMessage } = props;

    return (
        <Modal
            show={isOpen}
            title="Preview Card"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: false,
                label: 'Ok',
            }}
            okButton={{
                show: true,
                label: 'Close',
                // onClick: handleSubmit,
            }}>
            <IframeComm
                attributes={getAttributes}
                postMessageData={postMessageData}
                handleReady={onReady}
                handleReceiveMessage={onReceiveMessage}
            />
        </Modal>
    );
};

const columnsUsers = () => [
    {
        dataField: 'name',
        editable: false,
        text: 'Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.name}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'email',
        editable: false,
        text: 'E-mail',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.email}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'username',
        editable: false,
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">
                        {row.username} {row.corporateName ? `(${row.corporateName})` : ''}
                    </div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'createdDt',
        editable: false,
        text: 'Date',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            var today = new Date(row.createdDt);
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;

            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{today}</span>
                </div>
            );
        },
    },
];

const columnsCards = (analyticsData, openPreviewCardModal) => [
    {
        dataField: 'cardName',
        editable: false,
        text: 'Card Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.cardName}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: '_userId',
        editable: false,
        text: 'Card Owner',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            // if (
            //     analyticsData !== undefined &&
            //     analyticsData !== null &&
            //     analyticsData.users !== undefined &&
            //     analyticsData.users !== null &&
            //     analyticsData.users.length > 0
            // ) {
            // const user = analyticsData.users.filter(x => x._id === row._userId)[0];

            // if (user !== undefined && user !== null) {
            return (
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-sm flex-shrink-0"></div>
                    <div className="ml-4">
                        <div className="text-dark-75 font-size-lg mb-0">
                            {row.user_name} {row.corporateName ? `(${row.corporateName})` : ''}
                        </div>
                    </div>
                </div>
            );
            // }
            // }
        },
    },
    {
        dataField: 'createdDt',
        editable: false,
        text: 'Date',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            var today = new Date(row.createdDt);
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;

            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{today}</span>
                </div>
            );
        },
    },
    {
        dataField: '_id',
        editable: false,
        text: 'Action',
        formatter: (cell, row) => {
            return (
                <div className="d-flex">
                    <span>
                        <IconButton
                            title="Preview Card"
                            type="primary"
                            onClick={() => openPreviewCardModal(cell, row)}
                            iconUrl="/media/svg/icons/Home/business-card-icon.svg"
                            className="mr-3"
                        />
                    </span>
                </div>
            );
        },
    },
];

export default GeneralAnalytics;
