/** @format */

import { gql } from '@apollo/client';

export const CREATE_USER = gql`
    mutation($input: UserInput!) {
        createUser(input: $input) {
            _id
            username
            name
            email
            phone
            password
            status
            icon
        }
    }
`;

export const UPDATE_USER = gql`
    mutation($input: UserInputUpdate!) {
        updateUser(input: $input) {
            _id
            name
            username
            email
            icon
            type
            phone
            category
            designation
            address
            status
            userFields
        }
    }
`;

export const UPDATE_USER_SETTING = gql`
    mutation($input: SettingInputUpdate!) {
        updateSetting(input: $input) {
            _id
        }
    }
`;

export const GET_SETTING_BY_ACCOUNTID = gql`
    query($accountId: String!) {
        getSettingByAccountId(accountId: $accountId) {
            _id
            tfa
        }
    }
`;

export const FIND_USER_BY_USERNAME = gql`
    query getUserByUsername($username: String!) {
        getUserByUsername(username: $username) {
            _id
            status
            hidden
            type
        }
    }
`;

export const LIST_ALL_SPEEDCARD_ADMIN = gql`
    query listAllSpeedcardAdmin($id: String!) {
        listAllSpeedcardAdmin(id: $id) {
            _corporateId
            _id
            name
            username
            status
            type
        }
    }
`;

export const LIST_ALL_USERS = gql`
    query listAllSpeedcardUsersV2 {
        listAllSpeedcardUsersV2 {
            _id
            name
            username
            email
            company
            designation
            phone
            alias
            address
            status
            category
            type
            icon
            profilePicture
            accountId
            _corporateId
            updatedDt
            createdDt
            userFields
        }
    }
`;

export const UPDATE_PROFILE_PICTURE = gql`
    mutation($_userId: String!, $icon: String!) {
        updateProfilePicture(_userId: $_userId, icon: $icon) {
            _id
            name
            username
            password
            phone
            email
            status
            icon
        }
    }
`;
