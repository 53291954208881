/** @format */

import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageTitle } from 'src/app/layout';
import { ButtonCardWrapper, Boundary } from 'src/app/components';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import { AuthContext } from 'src/app/context';
import { isIntersectionExists, useQueryProxy } from 'src/app/helpers/util';
import IframeComm from 'react-iframe-comm';
import * as Paths from 'src/app/routes/paths';
import { GET_CORPORATE_TEMPLATE_LIST } from '../api/graphql';

const { REACT_APP_SPEEDCARD_URL } = process.env;

const CARD_STEP = {
    showList: 0,
    createTemplate: 1,
    createCard: 2,
    manageTemplate: 3,
    manageCard: 4,
};

const Cards = props => {
    const { origin, history } = props;
    const { getSessionStorage, getUserInfo, getCorporate } = useContext(AuthContext);
    const userInfo = getUserInfo();
    const corpInfo = getCorporate();
    const { roles, corporateId, corporateName } = getSessionStorage();
    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [step, setStep] = useState(CARD_STEP.showList);

    const { data: corp, loading: loading, refetch: refetchTemplates } = useQueryProxy(GET_CORPORATE_TEMPLATE_LIST, {
        variables: { corporateId: corporateId },
    });

    useEffect(() => {
        if (!loading) {
            if (corp !== undefined && corp !== null && corp !== '') {
                // console.log('templates', corp);
                setTemplates(corp);
            } else {
                setTemplates('');
            }
        }
    }, [corp]);

    const createTemplateAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}template-orientation.php?template=createTemplate&&mode=create&&isIframe=true&&fromParent=true&&corporateId=${corporateId}&&corporateName=${corporateName}&&maxAnnex=${corpInfo.maxAnnex}`,
        width: '100%',
        height: '750px',
    };

    const createCardAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}app-new-card.php?isIframe=true&&corporateId=${corporateId}&&corporateName=${corporateName}&&maxAnnex=${corpInfo.maxAnnex}`,
        width: '100%',
        height: '750px',
    };

    const manageTemplateAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}corporate-template.php?template=editTemplate&&isIframe=true&&corporateId=${corporateId}&&corporateName=${corporateName}&&maxAnnex=${corpInfo.maxAnnex}`,
        width: '100%',
        height: '750px',
    };

    const postData = {
        ...userInfo,
        corporateName: corporateName,
        corporateId: corporateId,
    };

    const postMessageData = JSON.stringify(postData);

    const onReceiveMessage = e => {
        const data = JSON.parse(e.data);

        if (data !== undefined && data !== null) {
            const message = data.message;

            if (message !== undefined && message !== null) {
                const action = message.action;

                if (action !== undefined && action !== null) {
                    if (action === 0) {
                        refetchTemplates();
                        setStep(CARD_STEP.showList);
                    }
                }
            }
        }
    };

    const onReady = () => {};

    return (
        <>
            <PageTitle title="Cards" />
            <Boundary
                modal
                onClick={() => {
                    history.push(`/${origin}${Paths.CARDS}`);
                }}
                error={error}
                success={success}
                onClose={() => {
                    setError('');
                    setSuccess('');
                }}>
                {step === CARD_STEP.showList && (
                    <Row>
                        {settingList({
                            setStep,
                            history,
                            origin,
                            corpInfo,
                            templates,
                            setError,
                        }).map((x, i) => {
                            const { permittedRoles, title, description, icon, color, onClick } = x;

                            if (!isIntersectionExists(permittedRoles, roles.split(','))) return null;

                            return (
                                <Col key={i} xs={12} md={6}>
                                    <SettingCard
                                        title={title}
                                        description={description}
                                        icon={icon}
                                        color={color}
                                        iconColor="grey"
                                        onClick={onClick}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}
                {step === CARD_STEP.createTemplate && (
                    <IframeComm
                        attributes={createTemplateAttributes}
                        postMessageData={postMessageData}
                        handleReady={onReady}
                        handleReceiveMessage={onReceiveMessage}
                    />
                )}
                {step === CARD_STEP.createCard && (
                    <IframeComm
                        attributes={createCardAttributes}
                        postMessageData={postMessageData}
                        handleReady={onReady}
                        handleReceiveMessage={onReceiveMessage}
                    />
                )}
                {step === CARD_STEP.manageTemplate && (
                    <IframeComm
                        attributes={manageTemplateAttributes}
                        postMessageData={postMessageData}
                        handleReady={onReady}
                        handleReceiveMessage={onReceiveMessage}
                    />
                )}
            </Boundary>
        </>
    );
};

const SettingCard = ({ title, description, icon, fa, small, iconColor, color, onClick }) => {
    const CardContent = () => (
        <ButtonCardWrapper
            svgIcon={toAbsoluteUrl(icon)}
            title={title}
            fa={fa}
            small={small}
            iconColor={iconColor}
            description={description}
            color={color || 'success'}
            onClick={onClick}
        />
    );

    return <CardContent />;
};

const settingList = props => {
    const { setStep, origin, history, corpInfo, templates, setError } = props;

    return [
        {
            title: 'Create Template',
            description: 'Create new template',
            icon: '/media/svg/icons/Home/pen-solid.svg',
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            color: 'primary',
            onClick: () => {
                if (parseInt(templates.length) >= parseInt(corpInfo.maxTemplate)) {
                    setError('Max template limit reached! (Max template: ' + corpInfo.maxTemplate + ')');
                } else {
                    setStep(CARD_STEP.createTemplate);
                }
            },
        },
        {
            title: 'Manage Template',
            description: 'Manage your templates',
            icon: '/media/svg/icons/Home/user-shield-solid.svg',
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            color: 'primary',
            onClick: () => {
                setStep(CARD_STEP.manageTemplate);
            },
        },
        {
            onClick: () => {
                history.push(`/${origin}${Paths.TEMPLATE_FIELDS}`);
            },
            title: 'Template Fields',
            description: 'Manage your template fields.',
            icon: '/media/svg/icons/Home/pen-solid.svg',
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            color: 'primary',
        },
    ];
};

export default Cards;
