/** @format */

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

import { getTimeout } from 'src/app/context/auth';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_API_URL,
});

const authLink = setContext((_, { headers }) => {
    const token = sessionStorage.getItem('token');

    // check timeout
    // const isTimeOut = getTimeout();
    // if (isTimeOut) {
    //     sessionStorage.clear();
    //     window.location.href = `${window.location.origin}/unauthenticated`;
    // }

    return {
        headers: {
            ...headers,
            authorization: token || '',
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
    },
});
