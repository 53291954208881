/** @format */
import React, { useEffect, useState } from 'react';

import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    Button,
    SearchBar,
    ErrorAlert,
    CenterWrapper,
    EntityRecordCardWrapper,
    CardWrapper,
    SearchBarV2,
} from 'src/app/components';
import { Row, Col } from 'react-bootstrap';

export const Table = props => {
    const {
        className,
        keyField = 'id',
        columns = [],
        data = [],
        defaultSizePerPage = 10,
        searchExtension,
        searchExtensionClassName,
        onSearch,
        card,
        hideWrapper,
        rowSelect,
        setRowSelect,
        showCheckbox = false,
        cellEdit,
        setDisableAssignBtn,
    } = props;

    const totalSize = data ? data.length : 0;
    const [tableData, setTableData] = useState(null);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(defaultSizePerPage);

    useEffect(() => {
        setPage(1);
        setSizePerPage(defaultSizePerPage);
        setTableData(data);
    }, [data]);

    const paginationOptions = {
        custom: true,
        totalSize,
        page,
        sizePerPage,
        paginationSize: 3,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        disablePageTitle: true,
        sizePerPageList: sizePerPageList({ defaultSizePerPage, totalSize }),
        sizePerPageRenderer: args => <SizePerPageRenderer {...args} onSizePerPageChange={onSizePerPageChange} />,
        pageButtonRenderer: args => (
            <PageButtonRenderer {...args} setPage={setPage} totalSize={totalSize} sizePerPage={sizePerPage} />
        ),
    };

    const onSizePerPageChange = e => {
        const { value } = e.target;
        setPage(1);
        setSizePerPage(value);
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        classes: 'selection-row',
        onSelect: (row, isSelect, rowIndex, e) => {
            setRowSelect([...rowSelect, row]);

            if (!isSelect) {
                setDisableAssignBtn(true);
                setRowSelect(rowSelect.filter(item => item._id !== row._id));
            } else {
                setDisableAssignBtn(false);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            setRowSelect(rows);

            if (!isSelect) {
                setDisableAssignBtn(true);
                setRowSelect([]);
            } else {
                setDisableAssignBtn(false);
            }
        },
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            // console.log({ row, rowIndex });
        },
    };

    return (
        <TableWrapper hideWrapper={hideWrapper}>
            <div
                className="d-flex flex-wrap align-items-center"
                style={{
                    paddingTop: 5,
                }}>
                <div className="mr-4">{onSearch && <SearchBar onSearch={onSearch} />}</div>
                <div className={`flex-fill ` + searchExtensionClassName}>{searchExtension}</div>
            </div>

            <div>
                {tableData && tableData.length > 0 ? (
                    <>
                        {card && (
                            <Row>
                                {tableData.map((e, i) => (
                                    <Col key={i} xs={12} xl={3} md={4} sm={6}>
                                        <EntityRecordCardWrapper entity={e} onClick={() => {}} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                        {!card && (
                            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                                {({ paginationProps, paginationTableProps }) => (
                                    <div>
                                        <BootstrapTable
                                            // wrapperClasses="card card-shadowless border-0 px-6 mt-4"
                                            classes={
                                                className ||
                                                'table-slim table-head-custom table-vertical-center overflow-hidden table-row-custom'
                                            }
                                            bordered={false}
                                            keyField={keyField}
                                            data={tableData}
                                            columns={columns}
                                            selectRow={showCheckbox ? selectRow : undefined}
                                            rowEvents={rowEvents}
                                            cellEdit={cellEdit}
                                            {...getSelectRowProps(props)}
                                            {...paginationTableProps}
                                        />
                                        <div className="mt-4">
                                            <CenterWrapper>
                                                <PaginationListStandalone {...paginationProps} />
                                            </CenterWrapper>
                                            <CenterWrapper>
                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                            </CenterWrapper>
                                        </div>
                                    </div>
                                )}
                            </PaginationProvider>
                        )}
                    </>
                ) : (
                    <div className="mb-4">
                        <ErrorAlert error={'No record found'} />
                    </div>
                )}
            </div>
        </TableWrapper>
    );
};

export const TableWithOnChangeSearch = props => {
    const {
        className,
        keyField = 'id',
        columns = [],
        data = [],
        defaultSizePerPage = 10,
        searchExtension,
        searchExtensionClassName,
        onSearch,
        card,
        hideWrapper,
        rowSelect,
        setRowSelect,
        showCheckbox = false,
        cellEdit,
        setDisableAssignBtn,
    } = props;

    const totalSize = data ? data.length : 0;
    const [tableData, setTableData] = useState(null);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(defaultSizePerPage);

    useEffect(() => {
        setPage(1);
        setSizePerPage(defaultSizePerPage);
        setTableData(data);
    }, [data]);

    const paginationOptions = {
        custom: true,
        totalSize,
        page,
        sizePerPage,
        paginationSize: 3,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        disablePageTitle: true,
        sizePerPageList: sizePerPageList({ defaultSizePerPage, totalSize }),
        sizePerPageRenderer: args => <SizePerPageRenderer {...args} onSizePerPageChange={onSizePerPageChange} />,
        pageButtonRenderer: args => (
            <PageButtonRenderer {...args} setPage={setPage} totalSize={totalSize} sizePerPage={sizePerPage} />
        ),
    };

    const onSizePerPageChange = e => {
        const { value } = e.target;
        setPage(1);
        setSizePerPage(value);
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        classes: 'selection-row',
        onSelect: (row, isSelect, rowIndex, e) => {
            setRowSelect([...rowSelect, row]);

            if (!isSelect) {
                setDisableAssignBtn(true);
                setRowSelect(rowSelect.filter(item => item._id !== row._id));
            } else {
                setDisableAssignBtn(false);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            setRowSelect(rows);

            if (!isSelect) {
                setDisableAssignBtn(true);
                setRowSelect([]);
            } else {
                setDisableAssignBtn(false);
            }
        },
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            // console.log({ row, rowIndex });
        },
    };

    return (
        <TableWrapper hideWrapper={hideWrapper}>
            <div
                className="d-flex flex-wrap align-items-center"
                style={{
                    paddingTop: 5,
                }}>
                <div className="mr-4">{onSearch && <SearchBarV2 onSearch={onSearch} />}</div>
                <div className={`flex-fill ` + searchExtensionClassName}>{searchExtension}</div>
            </div>

            <div>
                {tableData && tableData.length > 0 ? (
                    <>
                        {card && (
                            <Row>
                                {tableData.map((e, i) => (
                                    <Col key={i} xs={12} xl={3} md={4} sm={6}>
                                        <EntityRecordCardWrapper entity={e} onClick={() => {}} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                        {!card && (
                            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                                {({ paginationProps, paginationTableProps }) => (
                                    <div>
                                        <BootstrapTable
                                            // wrapperClasses="card card-shadowless border-0 px-6 mt-4"
                                            classes={
                                                className ||
                                                'table-slim table-head-custom table-vertical-center overflow-hidden table-row-custom'
                                            }
                                            bordered={false}
                                            keyField={keyField}
                                            data={tableData}
                                            columns={columns}
                                            selectRow={showCheckbox ? selectRow : undefined}
                                            rowEvents={rowEvents}
                                            cellEdit={cellEdit}
                                            {...getSelectRowProps(props)}
                                            {...paginationTableProps}
                                        />
                                        <div className="mt-4">
                                            <CenterWrapper>
                                                <PaginationListStandalone {...paginationProps} />
                                            </CenterWrapper>
                                            <CenterWrapper>
                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                            </CenterWrapper>
                                        </div>
                                    </div>
                                )}
                            </PaginationProvider>
                        )}
                    </>
                ) : (
                    <div className="mb-4">
                        <ErrorAlert error={'No record found'} />
                    </div>
                )}
            </div>
        </TableWrapper>
    );
};

const TableWrapper = props => {
    const { hideWrapper, children } = props;

    if (hideWrapper) return <div>{children}</div>;

    return <CardWrapper>{children}</CardWrapper>;
};

const PageButtonRenderer = props => {
    const { page, active, setPage, totalSize, sizePerPage } = props;

    const handleClick = e => {
        e.preventDefault();
        const lastPage = Math.ceil(totalSize / sizePerPage);

        switch (page) {
            case '<<':
                setPage(1);
                break;

            case '<':
                setPage(prev => (prev > 1 ? prev - 1 : 1));
                break;

            case '>':
                setPage(prev => (prev < lastPage ? prev + 1 : lastPage));
                break;

            case '>>':
                setPage(lastPage);
                break;

            default:
                setPage(page);
        }
    };

    return (
        <div key={page} className="page-item mx-1">
            <Button
                label={page}
                onClick={e => handleClick(e)}
                className={`floating-button  px-3 btn ${active ? 'btn-primary' : 'btn-secondary'}`}
            />
        </div>
    );
};

const SizePerPageRenderer = props => {
    const { options = [], currSizePerPage, onSizePerPageChange, totalSize } = props;

    return (
        <select
            disabled={totalSize === 0}
            className={`pagination-size font-weight-bold mr-4 border-0 bg-gray-200`}
            style={{ width: '75px', borderRadius: '0.42rem' }}
            onChange={onSizePerPageChange}
            value={currSizePerPage}>
            {options.map((option, i) => {
                const isSelect = `${currSizePerPage}` === `${option.page}`;
                return (
                    <option
                        key={i}
                        value={option.page}
                        // selected={isSelect}
                        className={`btn ${isSelect ? 'active' : ''}`}>
                        {option.text}
                    </option>
                );
            })}
        </select>
    );
};

const sizePerPageList = ({ defaultSizePerPage, totalSize }) => [
    { text: `${defaultSizePerPage}`, value: defaultSizePerPage },
    { text: `${defaultSizePerPage * 2}`, value: defaultSizePerPage * 2 },
    { text: `${defaultSizePerPage * 3}`, value: defaultSizePerPage * 3 },
    { text: `${defaultSizePerPage * 4}`, value: defaultSizePerPage * 4 },
    { text: 'All', value: totalSize },
];

const getSelectRowProps = props => {
    const { selectable, onSelect, onSelectAll } = props;

    if (!selectable) return {};
    return {
        selectRow: {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect,
            onSelectAll,
        },
    };
};
