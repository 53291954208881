/** @format */

import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { getDocumentTemplate, getBase64 } from "src/app/api";
import { Boundary } from "src/app/components";
import {
  useWindowDimensions,
  getBreakpointWidth,
  getErrorMsg
} from "src/app/helpers/util";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const TEMPLATE_CODE = "9b90be4c-8a2b-401f-8aa3-e26a9b284a2b";

const Terms = () => {
  const { width } = useWindowDimensions();
  const lg = getBreakpointWidth("lg");

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [noOfPages, setNoOfPages] = useState(null);

  useEffect(() => {
    async function getFileData() {
      try {
        setLoading(true);
        const fileId = await getDocumentTemplate(TEMPLATE_CODE);
        const downloadedFile = await getBase64(fileId);
        setFileData(downloadedFile);
      } catch (e) {
        setError(getErrorMsg(e, "Failed to download terms & condition"));
      } finally {
        setLoading(false);
      }
    }

    getFileData();
  }, []);

  const onLoadSuccess = ({ numPages }) => {
    setNoOfPages(numPages);
  };

  return (
    <Boundary
      error={error}
      success={success}
      loading={loading}
      onClose={() => {
        setError("");
        setSuccess("");
      }}
    >
      {fileData && (
        <Document
          file={`data:application/pdf;base64,${fileData}`}
          onLoadSuccess={onLoadSuccess}
        >
          {Array.apply(null, Array(noOfPages)).map((x, i) => (
            <Page
              key={i}
              pageNumber={i + 1}
              width={width > lg ? width * 0.6 : width * 0.9}
            />
          ))}
        </Document>
      )}
    </Boundary>
  );
};

export default Terms;
