/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { PageTitle } from 'src/app/layout';
import * as Yup from 'yup';
import { sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { Form } from 'react-bootstrap';
import {
    Boundary,
    Input,
    Button,
    Modal,
    InputPhoneNumber,
    Table,
    IconButton,
    Select,
    CardWrapper,
    Uploader,
} from 'src/app/components';
import {
    ADD_CORPORATE_STAFF,
    GET_TEMPLATE_FIELDS_BY_CORPORATE_ID,
    GET_STAFF_PROFILES,
    CHECK_CORPORATE_USER_EXIST,
} from 'src/app/api/graphql';
import { useQueryProxy, capitalizeFirstString } from 'src/app/helpers/util';
import { AuthContext } from 'src/app/context';
import { STATUS_STAFF } from 'src/app/helpers/misc';
import { uploadFile } from 'src/app/api';
import * as Paths from 'src/app/routes/paths';
import IsEmail from 'isemail';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const StaffForm = props => {
    const { history, origin } = props;
    const { getSessionStorage, getCorporate } = useContext(AuthContext);
    const { roles, corporateId, corporateName } = getSessionStorage();
    const { maxStaff, website, address, icon } = getCorporate();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isSaveOpen, setIsSaveOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [staffProfile, setstaffProfile] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [index, setIndex] = useState(null);
    const [count, setCount] = useState(0);
    const [staff, setStaff] = useState({});
    const [formList, setFormList] = useState({ staff: [], corporate: [] });
    const [fieldsId, setFieldsId] = useState([]);
    const [initialStaffValue, setInitialStaffValue] = useState([]);

    const { refetch: refetchCorpUser } = useQueryProxy(CHECK_CORPORATE_USER_EXIST, {
        variables: { username: '', _corporateId: corporateId },
    });

    // const { refetch: refetchEmail } = useQueryProxy(CHECK_EMAIL_EXIST, {
    //     variables: { username: '' },
    // });

    const { data: templateFields, loadingTemplateFields, refetch: refetchTemplateFields } = useQueryProxy(
        GET_TEMPLATE_FIELDS_BY_CORPORATE_ID,
        {
            variables: { corporateId: corporateId },
        }
    );

    const { data: staffList, loadingStaff } = useQueryProxy(GET_STAFF_PROFILES, {
        variables: { _corporateId: corporateId, status: { $ne: 'INACTIVE' } },
    });

    useEffect(() => {
        const data = sessionStorage.getItem('staffProfile');
        if (data) {
            setstaffProfile(JSON.parse(data));
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('staffProfile', JSON.stringify(staffProfile));
    }, [staffProfile]);

    useEffect(() => {
        if (!loadingStaff) {
            if (staffList.length !== 0) {
                let sum = staffList.filter(x => x.status !== STATUS_STAFF.inactive).length;
                const data = sessionStorage.getItem('staffProfile');
                if (data.length !== 0) {
                    sum = sum + JSON.parse(data).length;
                }
                setCount(sum);
            }
        }
    }, [staffList]);

    useEffect(() => {
        if (!loadingTemplateFields) {
            if (templateFields !== undefined && templateFields !== null) {
                if (templateFields.fields !== undefined && templateFields.fields !== null) {
                    let fields = JSON.parse(templateFields.fields);
                    let corpFields = [];
                    let staffFields = [];

                    fields = fields.map(field => {
                        if (field.name === 'company') {
                            field.value = corporateName;
                            corpFields.push(field);
                        } else if (field.name === 'website') {
                            field.value = website ? website : '';
                            corpFields.push(field);
                        } else if (field.name === 'address') {
                            field.value = address ? address : '';
                            corpFields.push(field);
                        } else if (field.name === 'logo') {
                            field.value = icon ? icon : '';
                            corpFields.push(field);
                        } else {
                            field.value = '';
                            if (field.dataType === 'image') {
                                field.fileName = '';
                            }
                            staffFields.push(field);
                        }
                    });
                    staffFields = staffFields.filter(
                        field =>
                            field.name !== 'email' &&
                            field.name !== 'phone' &&
                            field.name !== 'name' &&
                            field.name !== 'type'
                    );

                    setInitialStaffValue(staffFields);
                    setFormList({ staff: staffFields, corporate: corpFields });

                    setFieldsId(templateFields._id);
                }
            } else {
                setFormList([]);
            }
        }
    }, [templateFields]);

    const [createUser] = useMutation(ADD_CORPORATE_STAFF, {
        onError: error => setError(error.message),
        onCompleted: ({ addCorporateStaff }) => {
            // const { user: _id } = addCorporateStaff || {};
            // console.log(addCorporateStaff)
            setSuccess('Successfully added staff');
            setError('');
        },
    });

    const onCreateStaffProfile = () => {
        staffProfile.forEach(async staff => {
            delete staff.accType;
            delete staff.user;
            delete staff.userFields;
            delete staff._id;

            staff.status = 'PENDING';

            let userFields = [];

            const tempFields = JSON.parse(staff.templateFields);
            tempFields.fields.map(f => {
                userFields.push(...f.staff, ...f.corporate);
            });

            staff.templateFields = JSON.stringify({
                templateFieldsId: tempFields.templateFieldsId,
                fields: userFields,
            });

            createUser({
                variables: {
                    staffProfile: staff,
                },
            });
        });
        setstaffProfile([]);
    };

    const onSaveStaffProfile = () => {
        staffProfile.forEach(async staff => {
            delete staff.accType;
            delete staff.user;
            delete staff.userFields;
            delete staff._id;

            staff.status = 'DRAFT';

            let userFields = [];

            const tempFields = JSON.parse(staff.templateFields);
            tempFields.fields.map(f => {
                userFields.push(...f.staff, ...f.corporate);
            });

            staff.templateFields = JSON.stringify({
                templateFieldsId: tempFields.templateFieldsId,
                fields: userFields,
            });

            createUser({
                variables: {
                    staffProfile: staff,
                },
            });
        });
        sessionStorage.setItem('staffProfile', JSON.stringify(staffProfile));
    };

    const openEditModal = row => {
        let index;
        setSelectedValue(row.username);
        var isEmail = IsEmail.validate(row.username);
        if (isEmail) {
            index = staffProfile.findIndex(x => x.email === row.email);
        } else {
            index = staffProfile.findIndex(x => x.phone === row.phone);
        }

        setIsEditOpen(true);

        setStaff(staffProfile[index]);
        setIndex(index);
    };

    const openDeleteModal = row => {
        setSelectedValue(row.username);
        setIsDeleteOpen(true);
    };

    const onDeleteStaff = () => {
        if (selectedValue !== undefined && selectedValue !== null && selectedValue !== '') {
            var isEmail = IsEmail.validate(selectedValue);
            if (isEmail) {
                setstaffProfile(staffProfile.filter(a => a.email !== selectedValue));
            } else {
                setstaffProfile(staffProfile.filter(a => a.phone !== selectedValue));
            }

            setSelectedValue('');
            setCount(count - 1);
        }
    };

    const templateName = 'Default';

    return (
        <>
            <PageTitle title={'New Staff'} />
            <CardWrapper>
                <div>
                    <h5>Instruction</h5>
                    <p>{templateName} card template is currently in use.</p>
                </div>
                <br></br>
                <Boundary
                    modal
                    onClick={() => {
                        history.push(`/${origin}${Paths.STAFF}`);
                    }}
                    error={error}
                    success={success}
                    onClose={() => {
                        setSuccess('');
                        setError('');
                    }}>
                    <Button
                        buttonIcon={<i className="ki ki-plus icon-sm" />}
                        label="Add New Staff"
                        className=" floating-button font-weight-bold px-9"
                        onClick={() => setIsEditOpen(true)}
                    />
                    {staffProfile.length > 0 ? (
                        <Table keyField="email" columns={columns(openDeleteModal, openEditModal)} data={staffProfile} />
                    ) : (
                        ''
                    )}
                </Boundary>
                <br></br>
                {staffProfile.length > 0 ? (
                    <Button
                        buttonIcon={<i className="ki ki-plus icon-sm" />}
                        label="Done"
                        className=" floating-button btn-primary"
                        textAlign="right"
                        float="right"
                        onClick={() => {
                            setIsSaveOpen(true);
                        }}
                    />
                ) : (
                    ''
                )}

                <EditStaffModal
                    isOpen={isEditOpen}
                    setIsOpen={setIsEditOpen}
                    setstaffProfile={setstaffProfile}
                    staffProfile={staffProfile}
                    staff={staff}
                    setSelectedValue={setSelectedValue}
                    index={index}
                    selectedValue={selectedValue}
                    corporateId={corporateId}
                    count={count}
                    maxStaff={maxStaff}
                    setCount={setCount}
                    setError={setError}
                    setSuccess={setSuccess}
                    formList={formList}
                    setFormList={setFormList}
                    fieldsId={fieldsId}
                    refetchCorpUser={refetchCorpUser}
                    initialStaffValue={initialStaffValue}
                />
                <DeleteStaffModal onDelete={onDeleteStaff} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
                <SaveStaffModal
                    onSave={onCreateStaffProfile}
                    onDraft={onSaveStaffProfile}
                    isOpen={isSaveOpen}
                    setIsOpen={setIsSaveOpen}
                />
            </CardWrapper>
        </>
    );
};

const SaveStaffModal = props => {
    const { onSave, isOpen, setIsOpen, onDraft } = props;
    return (
        <Modal
            show={isOpen}
            title="Add staff"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Save Draft',
                onClick: onDraft,
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                onClick: onSave,
            }}>
            Are you sure?
        </Modal>
    );
};

const initialValues = staffProfile => {
    let values = {
        ...staffProfile,
        name: '',
        username: '',
        email: '',
        phone: '',
        accType: '',
        type: 3,
    };

    return values;
};

const EditStaffModal = props => {
    const {
        isOpen,
        setIsOpen,
        setstaffProfile,
        staffProfile,
        staff,
        setSelectedValue,
        index,
        selectedValue,
        corporateId,
        count,
        maxStaff,
        setCount,
        setError,
        setSuccess,
        formList,
        setFormList,
        fieldsId,
        refetchCorpUser,
        initialStaffValue,
    } = props;

    let individualFormList = formList.staff;
    const corporateFormList = formList.corporate;

    useEffect(() => {
        if (staff.userFields !== undefined && staff.userFields !== null) {
            const fields = JSON.parse(staff.userFields);

            let newTempValue = formList.staff;
            fields.fields.map(f => {
                const name = f.name;
                let value = f.value;

                if (newTempValue && newTempValue.length > 0) {
                    newTempValue = newTempValue.map(v => {
                        if (v.name === name) {
                            if (value === '') {
                                value = v.value;
                            }

                            if (value === '') {
                                if (staff[v.name] !== undefined && staff[v.name] !== null && staff[v.name] !== '') {
                                    let val = staff[v.name];
                                    value = val;
                                }
                            }

                            return {
                                ...v,
                                name: name,
                                value: value,
                            };
                        } else {
                            return {
                                ...v,
                                name: v.name,
                                value: v.value,
                            };
                        }
                    });
                }
            });
            setFormList({ staff: newTempValue, corporate: corporateFormList });
            individualFormList = newTempValue;
        } else {
            let newTempValue = formList.staff;

            if (newTempValue && newTempValue.length > 0) {
                newTempValue = newTempValue.map(v => {
                    let value = staff[v.name];

                    if (value === '') {
                        if (staff[v.name] !== undefined && staff[v.name] !== null && staff[v.name] !== '') {
                            let val = staff[v.name];
                            value = val;
                        }
                    }

                    return {
                        ...v,
                        name: v.name,
                        value: value,
                    };
                });
            }

            setFormList({ staff: newTempValue, corporate: corporateFormList });
            individualFormList = newTempValue;
        }
    }, [staff]);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        isValid,
        resetForm,
    } = useFormik({
        initialValues: selectedValue ? staff : initialValues(staffProfile),
        validationSchema: staffProfileInputSchema(refetchCorpUser, staff, corporateId, staffProfile),
        validateOnMount: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async values => {
            individualFormList = await Promise.all(
                individualFormList.map(async (z, i) => {
                    const { name, value, dataType } = z;

                    if (dataType === 'image' && value instanceof File) {
                        const fileId = await uploadFile(value);
                        // individualFormList[i].value = fileId;
                        return {
                            ...z,
                            value: fileId,
                        };
                    } else {
                        return {
                            ...z,
                        };
                    }
                })
            );

            const templateFields = {
                templateFieldsId: fieldsId,
                fields: [formList],
            };

            const temp = {
                _id: selectedValue ? selectedValue : parseInt(values.accType) === 1 ? values.email : values.phone,
                name: values.name,
                email: values.email !== undefined ? values.email : '',
                phone: values.phone !== undefined ? values.phone : '',
                username: values.accType === '1' ? values.email : values.phone,
                _corporateId: corporateId,
                type: parseInt(values.type),
                accType: values.accType,
                templateFields: JSON.stringify(templateFields),
            };
            temp.userFields = JSON.stringify({
                user: selectedValue ? selectedValue : temp.username,
                fields: individualFormList,
            });

            if (selectedValue !== undefined && selectedValue !== null && selectedValue !== '') {
                if (index !== undefined && index !== null) {
                    const newArr = [...staffProfile];
                    newArr[index] = temp;
                    setstaffProfile(newArr);
                }
            } else {
                if (count < maxStaff) {
                    let newStaff = staffProfile.concat(temp);
                    setstaffProfile(newStaff);
                    setCount(count + 1);
                } else {
                    setSuccess('');
                    setError('Max staff limit reached');
                }
            }
            setSelectedValue('');
            setIsOpen(false);
        },
    });

    const roles = [
        { type: 'Corporate User', val: 3 },
        { type: 'Corporate Admin', val: 2 },
    ];

    const types = [
        { accType: 'Email Account', val: 1 },
        { accType: 'Mobile Account', val: 2 },
    ];

    const onChange = e => {
        if (e.target !== undefined) {
            const name = e.target.name;
            var value = e.target.value;

            if (name === 'type') {
                value = parseInt(e.target.value);
            }

            let newTempValue = individualFormList;

            if (newTempValue.length > 0) {
                let exist = false;
                newTempValue = newTempValue.map(v => {
                    if (v.name === name) {
                        v.value = value;
                        exist = true;
                        return {
                            ...v,
                            name: name,
                            value: value,
                        };
                    } else {
                        return {
                            ...v,
                            name: v.name,
                            value: v.value,
                        };
                    }
                });
            } else {
                newTempValue = [
                    {
                        name: name,
                        value: value,
                    },
                ];
            }

            setFieldValue(name, value);
            individualFormList = newTempValue;
        }
    };

    const onFileChange = (name, value) => {
        let newTempValue = individualFormList;

        if (newTempValue.length > 0) {
            let exist = false;
            newTempValue = newTempValue.map(v => {
                if (v.name === name) {
                    v.value = value;
                    exist = true;
                    return {
                        ...v,
                        name: name,
                        value: value,
                    };
                } else {
                    return {
                        ...v,
                        name: v.name,
                        value: v.value,
                    };
                }
            });
        } else {
            newTempValue = [
                {
                    name: name,
                    value: value,
                },
            ];
        }

        setFieldValue(name, value);
        individualFormList = newTempValue;
    };

    return (
        <Modal
            closeButton={false}
            backdrop="static"
            keyboard={false}
            show={isOpen}
            title={selectedValue ? 'Edit Staff' : 'Add Staff'}
            onToggle={() => {
                setIsOpen(!isOpen);
            }}
            cancelButton={{
                show: true,
                label: 'Cancel',
                onClick: () => {
                    setFormList({ staff: initialStaffValue, corporate: corporateFormList });
                    setSelectedValue('');
                    resetForm();
                },
            }}
            okButton={{
                show: true,
                label: 'Done',
                disabled: !isValid,
                onClick: handleSubmit,
            }}>
            <Form>
                <Tabs defaultActiveKey="personal" className="mb-7">
                    <Tab eventKey="personal" title="Personal">
                        <Select
                            mandatory={true}
                            name="type"
                            label="Role"
                            placeholder="Select Role"
                            value={values.type}
                            options={roles}
                            getOptionLabel={option => option.type}
                            getOptionValue={option => option.val}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabledplaceholder="true"
                            error={touched.type && errors.type}
                        />
                        <Select
                            mandatory={true}
                            name="accType"
                            label="Account Type"
                            description="**Credentials will be sent to the selected account type"
                            placeholder="Select Account Type"
                            value={values.accType}
                            options={types}
                            getOptionLabel={option => option.accType}
                            getOptionValue={option => option.val}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabledplaceholder="true"
                            error={touched.accType && errors.accType}
                        />
                        <Input
                            mandatory
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && errors.name}
                            placeholder="Enter name"
                        />
                        {values.accType === '2' ? (
                            <>
                                <InputPhoneNumber
                                    mandatory={values.accType === '2' ? true : false}
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={setFieldValue}
                                    onBlur={handleBlur}
                                    error={touched.phone && errors.phone}
                                />
                                <Input
                                    mandatory={values.accType === '2' ? false : true}
                                    name="email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && errors.email}
                                    placeholder="Enter email"
                                />
                            </>
                        ) : (
                            ''
                        )}
                        {values.accType === '1' ? (
                            <>
                                <Input
                                    mandatory={values.accType === '1' ? true : false}
                                    name="email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && errors.email}
                                    placeholder="Enter email"
                                />
                                <InputPhoneNumber
                                    mandatory={values.accType === '1' ? false : true}
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={setFieldValue}
                                    onBlur={handleBlur}
                                    error={touched.phone && errors.phone}
                                />
                            </>
                        ) : (
                            ''
                        )}
                        {individualFormList &&
                            individualFormList.map((x, i) => {
                                let value =
                                    individualFormList.length > 0
                                        ? individualFormList.filter(f => f.name === x.name)[0].value
                                        : '';

                                const dataType = x.dataType;

                                if (dataType === 'text' || dataType === 'link' || dataType === 'address') {
                                    return (
                                        <Input
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={handleBlur}
                                            placeholder={x.name}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'phone' || dataType === 'phoneNum') {
                                    return (
                                        <InputPhoneNumber
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={handleBlur}
                                            error={touched.phone && errors.phone}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'image') {
                                    return (
                                        <Uploader
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            description="*Only image files are accepted."
                                            key={x.name}
                                            width={450}
                                            previewImage
                                            value={value}
                                            onChange={f => onFileChange(x.name, f)}
                                            onBlur={handleBlur}
                                        />
                                    );
                                }
                            })}
                    </Tab>
                    <Tab eventKey="corporate" title="Corporate">
                        {corporateFormList &&
                            corporateFormList.map((x, i) => {
                                let value =
                                    corporateFormList.length > 0
                                        ? corporateFormList.filter(f => f.name === x.name)[0].value
                                        : '';

                                const dataType = x.dataType;

                                if (dataType === 'text' || dataType === 'link' || dataType === 'address') {
                                    return (
                                        <Input
                                            style={{ color: 'grey' }}
                                            disabled
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            placeholder={x.name}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'phone' || dataType === 'phoneNum') {
                                    return (
                                        <InputPhoneNumber
                                            disabled
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'image') {
                                    return (
                                        <Uploader
                                            disabled={true}
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            key={x.name}
                                            width={450}
                                            previewImage
                                            value={value}
                                        />
                                    );
                                }
                            })}
                    </Tab>
                </Tabs>
            </Form>
        </Modal>
    );
};

const DeleteStaffModal = props => {
    const { onDelete, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Delete Staff"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: id => {
                    onDelete(id);
                },
            }}>
            Do you want to delete this staff?
        </Modal>
    );
};

const columns = (openDeleteModal, openEditModal) => [
    {
        dataField: 'name',
        editable: false,
        text: 'Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            return (
                <div className="d-flex">
                    <span className="row-valign-center">{cell}</span>
                </div>
            );
        },
    },
    {
        dataField: 'username',
        editable: false,
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            if (cell === '' || cell === null) {
                return (
                    <div className="d-flex ">
                        <span className="row-valign-center">-</span>
                    </div>
                );
            } else {
                return (
                    <div className="d-flex ">
                        <span className="row-valign-center">{cell}</span>
                    </div>
                );
            }
        },
    },
    {
        dataField: 'type',
        editable: false,
        text: 'Role',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            if (cell === 2) {
                var roleName = 'Corporate Admin';
            } else if (cell === 3) {
                var roleName = 'Corporate User';
            }

            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{roleName}</span>
                </div>
            );
        },
    },
    {
        dataField: 'id',
        editable: false,
        text: 'Action',
        formatter: (cell, row) => (
            <div className="d-flex">
                <span>
                    <IconButton
                        title="Edit"
                        type="primary"
                        onClick={() => openEditModal(row)}
                        iconUrl="/media/svg/icons/Home/edit-solid.svg"
                        className="mr-3"
                    />
                </span>
                <span>
                    <IconButton
                        title="Delete"
                        type="danger"
                        onClick={() => openDeleteModal(row)}
                        iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                        className="mr-3"
                    />
                </span>
            </div>
        ),
    },
];

const staffProfileInputSchema = (refetchCorpUser, staff, corporateId, staffProfile) => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            name: Yup.string().required('This field is required'),
            email: Yup.string().when('accType', {
                is: '1',
                then: Yup.string()
                    .email('Wrong email format')
                    .test({
                        message: 'Staff already exists in this corporate',
                        test: async values => {
                            let corpUserExist = false;

                            if (values !== undefined && values !== null) {
                                let res = await refetchCorpUser({
                                    username: values,
                                    _corporateId: corporateId,
                                });

                                if (staff !== [] || staff !== undefined) {
                                    if (staff.email === values) {
                                        return true;
                                    }
                                }
                                corpUserExist = res.data[`checkCorporateUserExist`];

                                staffProfile.map(v => {
                                    if (v.email === values) {
                                        corpUserExist = true;
                                    }
                                });

                                if (corpUserExist) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    }),
                otherwise: Yup.string()
                    .nullable()
                    .notRequired(),
            }),
            phone: Yup.string().when('accType', {
                is: '2',
                then: Yup.string().test({
                    message: 'Staff already exists in this corporate',
                    test: async values => {
                        let corpUserExist = false;

                        if (values !== undefined && values !== null) {
                            let res = await refetchCorpUser({
                                username: values,
                                _corporateId: corporateId,
                            });

                            if (staff !== [] || staff !== undefined) {
                                if (staff.phone === values) {
                                    return true;
                                }
                            }
                            corpUserExist = res.data[`checkCorporateUserExist`];

                            staffProfile.map(v => {
                                if (v.phone === values) {
                                    corpUserExist = true;
                                }
                            });

                            if (corpUserExist) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    },
                }),
                otherwise: Yup.string()
                    .nullable()
                    .notRequired(),
            }),
        });
    });
};

export default StaffForm;
