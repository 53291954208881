/** @format */

import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
    query getSettings {
        getSettings {
            key
            name
            valueSingle
            valueMultiple
            createdDt
            createdBy
            updatedDt
            updatedBy
        }
    }
`;

export const UPDATE_SETTINGS = gql`
    mutation updateSettings($settings: String!) {
        updateSettings(settings: $settings)
    }
`;
