/** @format */

import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import { login, checkTFA, requestOTP } from 'src/app/api';
import * as Paths from 'src/app/routes/paths';

export const AuthContext = createContext({});

const isValidToken = () => {
    const token = sessionStorage.getItem('token');
    return token ? true : false;
};

export const getUserInfo = () => {
    const userJson = JSON.parse(sessionStorage.getItem('user')) || {};
    return userJson;
};

export const getCorporate = () => {
    const corporateJson = JSON.parse(sessionStorage.getItem('corporate')) || {};
    return corporateJson;
};

export const getTimeout = () => {
    const { time } = JSON.parse(sessionStorage.getItem('user')) || {};
    if (!time) return true;

    const formatTime = time.replace(/(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1-$2-$3 $4:$5');
    const diff = (new Date().getTime() - new Date(formatTime).getTime()) / 60000;
    if (diff > 120) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('corporate');
    }

    return diff > 120;
};

export const AuthProvider = props => {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());
    const [selectedCorpId, setSelectedCorpId] = useState('');

    const getSelectedCorpId = () => {
        let id = selectedCorpId;
        const { corporateId } = getSessionStorage();

        if (id === undefined || id === null || id === '') {
            if (corporateId !== undefined && (corporateId !== null) & (corporateId !== '')) {
                id = corporateId;
            }
        }

        return id;
    };

    const getSessionStorage = () => {
        let user = JSON.parse(sessionStorage.getItem('user') || '{}');
        const { roles = '' } = user;
        if (roles.includes('corporatesuperadmin')) user.roles = 'corporatesuperadmin';
        else if (roles.includes('superadmin')) user.roles = 'superadmin';
        else if (roles.includes('admin')) user.roles = 'admin';
        else user.roles = 'user';

        return user;
    };

    const setSessionStorage = tokenData => {
        const { roles, corporateId } = tokenData;

        setSelectedCorpId(corporateId);
        var roleName = 'user';

        if (parseInt(roles) === 1) {
            if (corporateId !== undefined && corporateId !== 'null' && corporateId !== '' && corporateId !== null) {
                roleName = 'corporatesuperadmin';
            } else {
                roleName = 'superadmin';
            }
        } else if (parseInt(roles) === 2) {
            roleName = 'admin';
        } else if (parseInt(roles) === 3) {
            roleName = 'user';
        } else {
            roleName = 'user';
        }

        sessionStorage.setItem('token', JSON.stringify(tokenData));
        sessionStorage.setItem(
            'user',
            JSON.stringify({
                ...tokenData,
                roles: roleName,
                // roles: roles.toLowerCase(),
            })
        );
    };

    const decodeToken = token => {
        if (!token) return token;
        const decodedToken = atob(token);
        const data = decodedToken.split(';').reduce((acc, cur) => {
            const [key, value] = cur.split('=');
            acc[key] = value;
            return acc;
        }, {});

        return data;
    };

    const checkTFAEnabled = async payload => {
        try {
            const { data } = await checkTFA(payload);
            return data;
        } catch (e) {
            throw e;
        }
    };

    const verifyOTPToken = async payload => {
        try {
            // const res = await verifyOTP(payload);
            // return res;
            const otp = JSON.parse(sessionStorage.getItem('otp'));
            if (otp.key == payload.otp) {
                var res = {
                    data: {
                        verified: true,
                        user: { username: payload.username },
                    },
                };
                sessionStorage.removeItem('otp');
                return res;
            } else {
                return {};
            }
        } catch (e) {
            return e;
        }
    };

    const requestOTPToken = async payload => {
        try {
            const res = await requestOTP(payload);
            return res;
        } catch (e) {
            return e;
        }
    };

    const signIn = async credential => {
        try {
            const { data } = await login(credential);
            const tokenData = decodeToken(data.token);
            const corporateId = tokenData.corporateId;
            const corporateList = JSON.parse(tokenData.corporateList);

            if (corporateList.length === 0) {
                sessionStorage.setItem('corporate', JSON.stringify({}));
            } else {
                let corporate = corporateList.filter(corp => corp._id === corporateId)[0];
                sessionStorage.setItem('corporate', JSON.stringify(corporate._doc));
            }

            setSessionStorage(tokenData);
            setIsAuthenticated(true);
            return tokenData;
        } catch (e) {
            setIsAuthenticated(false);
            throw e;
        }
    };

    const changeCorporate = corporateId => {
        try {
            const token = JSON.parse(sessionStorage.getItem('token'));

            if (token !== undefined && token !== null) {
                const corporateList = JSON.parse(token.corporateList);
                let corporate = corporateList.filter(corp => corp._id === corporateId)[0];
                token._id = corporate.accountId;
                token.name = corporate.name;
                token.email = corporate.email;
                token.phone = corporate.phone;

                let newToken = {
                    ...token,
                    corporateId: corporate._id,
                    corporateName: corporate.corporateName,
                    corporateKey: corporate.corporateKey,
                };
                sessionStorage.setItem('corporate', JSON.stringify(corporate._doc));
                setSessionStorage(newToken);
                // window.location.reload(false);
            }
        } catch (e) {
            throw e;
        }
    };

    const updateUserInfo = userInfo => {
        try {
            const token = JSON.parse(sessionStorage.getItem('token'));

            if (token !== undefined && token !== null) {
                token._id = userInfo._id;
                token.name = userInfo.name;
                token.email = userInfo.email;
                token.phone = userInfo.phone;

                let newToken = {
                    ...token,
                };
                setSessionStorage(newToken);
                // window.location.reload(false);
            }
        } catch (e) {
            throw e;
        }
    };

    const updateCorporateList = list => {
        try {
            const token = JSON.parse(sessionStorage.getItem('token'));
            if (token !== undefined && token !== null) {
                let newToken = {
                    ...token,
                    corporateList: list,
                };
                setSessionStorage(newToken);
                // window.location.reload(false);
            }
        } catch (e) {
            throw e;
        }
    };

    const signOut = () => {
        sessionStorage.clear();
        setIsAuthenticated(false);
        history.push(`/public${Paths.PUBLIC_HOME}`);
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                selectedCorpId,
                getSelectedCorpId,
                getSessionStorage,
                requestOTPToken,
                verifyOTPToken,
                checkTFAEnabled,
                decodeToken,
                getUserInfo,
                getCorporate,
                signIn,
                signOut,
                changeCorporate,
                updateCorporateList,
                updateUserInfo,
            }}>
            <>{props.children}</>
        </AuthContext.Provider>
    );
};
