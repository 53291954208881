/** @format */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_helpers';
import SVG from 'react-inlinesvg';
import { SmallButton } from 'src/app/components';

export const Brand = props => {
    const { openCorpOptionsModal } = props;

    return (
        <>
            {/* begin::Brand */}
            <div className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5`}>
                {/* begin::Logo */}
                <Link
                    to=""
                    className="brand-logo procorp-menu-text"
                    style={{
                        fontSize: 40,
                    }}>
                    <img alt="logo" src={toAbsoluteUrl('/media/logos/logo-default.png')} className="max-h-30px" />
                    {/* SpeedCard */}
                </Link>
                {/* end::Logo */}
            </div>
            <div className={`aside-brand d-flex flex-column align-items-center flex-column-auto`}>
                <Corporate {...props} openCorpOptionsModal={openCorpOptionsModal} />

                {/* {corporateList  (
                    <div
                        className="text-secondary"
                        style={{
                            fontSize: 18,
                        }}
                        onClick={openCorpOptionsModal}>
                        <span className="svg-icon menu-icon text-secondary">
                            <SVG src={toAbsoluteUrl('/media/svg/icons/General/repeat-white.svg')} />
                        </span>{' '}
                        {corporateName}
                    </div>
                )} */}

                {/* end::Logo */}
            </div>
            {/* end::Brand */}
        </>
    );
};

const Corporate = props => {
    const { corporateList, openCorpOptionsModal, corporateName, corporateKey } = props;
    const [isCopied, setIsCopied] = useState('');

    const size = JSON.parse(corporateList).length;

    const copyToClipboard = text => {
        setIsCopied(text);
        setTimeout(() => setIsCopied(''), 3000);
        navigator.clipboard.writeText(text);
    };

    if (size <= 1)
        return (
            <>
                <div
                    className="text-secondary"
                    style={{
                        fontSize: 18,
                    }}>
                    {corporateName}
                </div>
                <div className="d-flex" onClick={() => copyToClipboard(corporateKey)}>
                    <SmallButton
                        className="ml-2"
                        iconUrl="/media/svg/icons/Home/copy.svg"
                        onClick={() => copyToClipboard(corporateKey)}
                        noBg></SmallButton>
                    <br />
                    <span className="row-valign-center ml-1 text-secondary">{corporateKey}</span>
                    {isCopied !== '' && isCopied === corporateKey ? (
                        <span className="text-success ml-2">Copied!</span>
                    ) : (
                        ''
                    )}
                </div>
            </>
        );

    return (
        <>
            <div
                className="text-secondary"
                style={{
                    fontSize: 18,
                }}
                onClick={openCorpOptionsModal}>
                <span className="svg-icon menu-icon text-secondary">
                    <SVG src={toAbsoluteUrl('/media/svg/icons/General/repeat-white.svg')} />
                </span>{' '}
                {corporateName}
            </div>
            <div className="d-flex" onClick={() => copyToClipboard(corporateKey)}>
                <SmallButton
                    className="ml-2"
                    iconUrl="/media/svg/icons/Home/copy.svg"
                    onClick={() => copyToClipboard(corporateKey)}
                    noBg></SmallButton>
                <br />
                <span className="row-valign-center ml-1 text-secondary">{corporateKey}</span>
                {isCopied !== '' && isCopied === corporateKey ? <span className="text-success ml-2">Copied!</span> : ''}
            </div>
        </>
    );
};
