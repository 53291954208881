/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useQueryProxy } from 'src/app/helpers/util';
import { AuthContext } from 'src/app/context';
import { PageTitle } from 'src/app/layout';
import {
    Input,
    Uploader,
    Button,
    Boundary,
    InputPhoneNumber,
    FormButtonWrapper,
    FormPanelWrapper,
    FormWrapper,
    CardWrapper,
    InputTextArea,
    TelInput,
} from 'src/app/components';

import { UPDATE_CORPORATE_INFO_2, CHECK_CORPORATE_EXIST, GET_CORPORATE_BY_ID } from 'src/app/api/graphql';
import * as Paths from 'src/app/routes/paths';
import { uploadFile } from 'src/app/api';
import IsEmail from 'isemail';

export default function Corporate(props) {
    const { history, origin } = props;
    const { getSessionStorage } = useContext(AuthContext);
    const { roles, corporateId, username } = getSessionStorage();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [corporate, setCorporate] = useState({});

    const { data: corp, loading: loading } = useQueryProxy(GET_CORPORATE_BY_ID, {
        variables: { _id: corporateId },
    });

    useEffect(() => {
        if (!loading) {
            if (corp !== undefined && corp !== null && corp !== '') {
                setCorporate(corp);
            } else {
                setCorporate('');
            }
        }
    }, [corp]);

    // console.log(corporateId);
    const [updateCorporate] = useMutation(UPDATE_CORPORATE_INFO_2, {
        onError: e => setError(e.message),
        onCompleted: val => {
            setSuccess('Successfully updated profile');
            //console.log(val.updateCorporate);
            const updated = val.updateCorporateV2;

            // console.log('updated',updated)
            const corp = JSON.parse(sessionStorage.getItem('corporate')) || {};
            corp.corporateName = updated.corporateName;
            corp.website = updated.website;
            corp.phone = updated.phone;
            corp.address = updated.address;
            corp.icon = updated.icon;

            sessionStorage.setItem('corporate', JSON.stringify(corp));
        },
    });

    const { refetch: refetchCorporate } = useQueryProxy(CHECK_CORPORATE_EXIST, {
        variables: { registrationNumber: '' },
    });

    const onSubmit = async values => {
        const fileId = values.logo instanceof File ? await uploadFile(values.logo) : values.logo;
        updateCorporate({
            variables: {
                input: {
                    _id: corporateId,
                    corporateName: values.corporateName,
                    website: values.website,
                    phone: values.phone || '',
                    address: values.address,
                    icon: fileId,
                },
            },
            refetchQueries: [`findCorporateById`],
        });
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        errors,
        touched,
        isValid,
        isSubmitting,
    } = useFormik({
        initialValues: {
            corporateName: corporate.corporateName,
            website: corporate.website ? corporate.website : '',
            phone: corporate.phone ? corporate.phone : '+65',
            address: corporate.address ? corporate.address : '',
            registrationNumber: corporate.registrationNumber,
            username: username,
            logo: corporate.icon ? corporate.icon : '',
        },
        validationSchema: profileSchema(refetchCorporate, corporate),
        validateOnMount: true,
        validateOnChange: true,
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: async values => {
            onSubmit(values);
        },
    });

    const onSelectFlag = (currentNumber, selectedCountryData, fullNumber, fieldName) => {
        const dialCode = selectedCountryData.dialCode;
        if (
            currentNumber === undefined ||
            currentNumber === null ||
            currentNumber === '' ||
            currentNumber.length <= 5
        ) {
            setFieldValue(fieldName, '+' + dialCode);
        }
    };

    const onPhoneNumberChange = (isValid, value, country, fullNumber, extension, fieldName) => {
        const dialCode = country.dialCode;
        const tempValue = value.replace('+', '');

        if (tempValue !== dialCode) {
            setFieldValue(fieldName, value);
        }
    };

    return (
        <>
            <PageTitle title="Edit Corporate " />
            <Boundary
                // loading={loading}
                modal
                onClick={() => {
                    history.push(`/${origin}${Paths.SETTINGS}`);
                }}
                error={error}
                success={success}
                onClose={() => {
                    setError('');
                    setSuccess('');
                }}>
                <CardWrapper>
                    <div className="text-center">
                        <Row>
                            <Col>Max Staff: {corporate.maxStaff}</Col>
                            <Col>Max Template : {corporate.maxTemplate}</Col>
                            <Col>Max Annex: {corporate.maxAnnex}</Col>
                        </Row>
                    </div>
                </CardWrapper>
                <FormPanelWrapper>
                    <FormWrapper>
                        <Form onSubmit={handleSubmit}>
                            <Input
                                name="corporateName"
                                label="Corporate Name"
                                value={values.corporateName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.corporateName && errors.corporateName}
                            />
                            {roles === 'corporatesuperadmin' ? (
                                <Input
                                    name="registrationNumber"
                                    label="Registration Number"
                                    value={values.registrationNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.registrationNumber && errors.registrationNumber}
                                />
                            ) : (
                                <Input
                                    readOnly
                                    name="registrationNumber"
                                    label="Registration Number"
                                    value={values.registrationNumber}
                                />
                            )}
                            {IsEmail.validate(username) ? (
                                <Input readOnly name="username" label="Admin Email" value={values.username} />
                            ) : (
                                <InputPhoneNumber
                                    readOnly
                                    name="username"
                                    label="Admin Phone"
                                    value={values.username}
                                />
                            )}
                            <Input
                                name="website"
                                label="Website"
                                value={values.website}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.website && errors.website}
                            />
                            {}
                            <InputTextArea
                                name="address"
                                label="Address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.address && errors.address}
                            />
                            <TelInput
                                placeholder="Phone"
                                name="phone"
                                label="Contact Number"
                                value={values.phone}
                                onSelectFlag={onSelectFlag}
                                onPhoneNumberChange={onPhoneNumberChange}
                                onBlur={handleBlur}
                                error={touched.phone && errors.phone}
                                key={'phone'}
                            />
                            {/* <TelInput
                                placeholder="Phone"
                                name="phone"
                                label="Contact Number"
                                value={values.phone}
                                onChange={setFieldValue}
                                onBlur={handleBlur}
                                error={touched.phone && errors.phone}
                            /> */}
                            <Uploader
                                width={450}
                                previewImage
                                label="Logo"
                                description="*Only image files are accepted."
                                name="logo"
                                value={values.logo}
                                onChange={f => setFieldValue('logo', f)}
                                onBlur={handleBlur}
                                error={touched.logo && errors.logo}
                            />
                            <FormButtonWrapper>
                                <Button
                                    // type="submit"
                                    className="floating-button mr-2"
                                    label="Cancel"
                                    onClick={() => {
                                        history.push(`/${origin}${Paths.SETTINGS}`);
                                    }}
                                />
                                <Button
                                    type="submit"
                                    className=" floating-button"
                                    label="Save"
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                />
                            </FormButtonWrapper>
                        </Form>
                    </FormWrapper>
                </FormPanelWrapper>
            </Boundary>
        </>
    );
}

const profileSchema = (refetchCorporate, corporate) => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            corporateName: Yup.string().required('Required field'),
            registrationNumber: Yup.string()
                .required('Required field')
                .test({
                    message: 'Corporate already exists',
                    test: async values => {
                        let corporateExist = false;

                        if (values !== corporate.registrationNumber) {
                            if (values !== undefined && values !== null) {
                                let res = await refetchCorporate({
                                    registrationNumber: values,
                                });

                                corporateExist = res.data.checkCorporateExist;

                                if (corporateExist) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        } else {
                            return true;
                        }
                    },
                }),
            logo: Yup.mixed()
                .notRequired()
                .nullable(),
            address: Yup.string().nullable(),
            phone: Yup.string().nullable(),
            website: Yup.string().nullable(),
        });
    });
};
