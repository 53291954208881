/** @format */

import { gql } from '@apollo/client';

export const GET_ANALYTICS = gql`
    query getAnalytics($from: String, $to: String) {
        getAnalytics(from: $from, to: $to) {
            users {
                _id
                name
                username
                email
                icon
                createdDt
                _corporateId
                corporateName
                type
            }
            cards {
                _id
                _userId
                corporateName
                cardName
                name
                user_name
                designation
                company
                email
                address
                phone
                category
                icon
                hidden
                isPrimary
                isPublic
                tags
                createdDt
                layout {
                    _id
                    layout
                }
            }
        }
    }
`;

export const GET_TOTAL_CONTACT = gql`
    query getTotalContact($corporateId: String!) {
        getTotalContact(corporateId: $corporateId) {
            totalNew
            totalAll
        }
    }
`;
