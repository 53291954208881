/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { PageTitle } from 'src/app/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { Form } from 'react-bootstrap';
import {
    Boundary,
    Input,
    Button,
    Modal,
    Table,
    IconButton,
    CardWrapper,
    Select,
    InputPhoneNumber,
} from 'src/app/components';
import {
    CREATE_CORPORATE,
    CHECK_EMAIL_EXIST,
    CHECK_CORPORATE_EXIST,
    GET_DRAFT_CORPORATE,
    DELETE_CORPORATE,
} from 'src/app/api/graphql';
import { useQueryProxy } from 'src/app/helpers/util';
import { AuthContext } from 'src/app/context';
import * as Paths from 'src/app/routes/paths';
import IsEmail from 'isemail';

const CorporateForm = props => {
    const { origin, history } = props;
    const { getSessionStorage } = useContext(AuthContext);
    const { corporateId } = getSessionStorage();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isSaveOpen, setIsSaveOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [corporateList, setCorporateList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [index, setIndex] = useState(null);
    const [corporate, setCorporate] = useState({});

    const { data: getDraft, loading } = useQueryProxy(GET_DRAFT_CORPORATE);

    useEffect(() => {
        const data = sessionStorage.getItem('corporateList');
        // console.log(getDraft)
        if (!loading) {
            let tempData = [];
            if (getDraft !== undefined && getDraft !== null && getDraft.length > 0) {
                getDraft.map(corp => {
                    // console.log(corp)
                    if (corp.adminUsername !== null && corp.adminUsername !== undefined && corp.adminUsername !== '') {
                        var isEmail = IsEmail.validate(corp.adminUsername);
                        if (isEmail) {
                            corp.type = '1';
                            corp.email = corp.adminUsername;
                            corp.phone = null;
                        } else {
                            corp.type = '2';
                            corp.phone = corp.adminUsername;
                            corp.email = '';
                        }
                        tempData.push(corp);
                    } else {
                        corp.adminUsername = '';
                        corp.email = '';
                        corp.phone = '';
                        corp.type = null;
                        tempData.push(corp);
                    }
                });
                if (data) {
                    // console.log('1')
                    const local = JSON.parse(data);
                    setCorporateList([...local, ...tempData]);
                    // setCorporateList(JSON.parse(data));
                } else {
                    // console.log('2')
                    setCorporateList(tempData);
                }
            } else if (data) {
                // console.log('3')
                setCorporateList(JSON.parse(data));
            } else {
                // console.log('4')
                setCorporateList('');
            }
        }
    }, [getDraft]);
    // }, []);
    // console.log('corporateList',corporateList)

    useEffect(() => {
        sessionStorage.setItem('corporateList', JSON.stringify(corporateList));
    }, [corporateList]);

    const [createCorporate] = useMutation(CREATE_CORPORATE, {
        onError: error => setError(error.message),
        // onCompleted: () => {
        //     setSuccess('Successfully created corporate');
        // },
    });

    const { refetch: refetchEmail } = useQueryProxy(CHECK_EMAIL_EXIST, {
        variables: { username: '' },
    });

    const { refetch: refetchCorporate } = useQueryProxy(CHECK_CORPORATE_EXIST, {
        variables: { registrationNumber: '' },
    });

    const [deleteCorporate] = useMutation(DELETE_CORPORATE, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully deleted corporate'),
    });

    const onCreateCorporate = () => {
        corporateList.forEach(async corporate => {
            const input = {
                corporateName: corporate.corporateName,
                registrationNumber: corporate.registrationNumber,
                maxStaff: corporate.maxStaff,
                maxCard: 1,
                adminUsername: corporate.adminUsername,
                maxTemplate: corporate.maxTemplate,
                maxAnnex: corporate.maxAnnex,
                status: 'ACTIVE',
            };
            // console.log(input)
            await createCorporate({
                variables: {
                    input: JSON.stringify(input),
                },
                onCompleted: () => {
                    setSuccess('Successfully created corporate');
                },
            });
        });
        setCorporateList([]);
    };

    const onSaveCorporate = () => {
        corporateList.forEach(async corporate => {
            const input = {
                corporateName: corporate.corporateName,
                registrationNumber: corporate.registrationNumber,
                maxStaff: corporate.maxStaff,
                maxCard: 1,
                adminUsername: corporate.adminUsername,
                maxTemplate: corporate.maxTemplate,
                maxAnnex: corporate.maxAnnex,
                status: 'DRAFT',
            };
            const user = await createCorporate({
                variables: {
                    input: JSON.stringify(input),
                },
                onCompleted: () => {
                    setSuccess('Saved as draft');
                },
            });
        });
        sessionStorage.setItem('corporateList', JSON.stringify(corporateList));
    };

    const openEditModal = row => {
        // console.log('row',row.registrationNumber);
        setSelectedValue(row.registrationNumber);
        setIsEditOpen(true);

        let index = corporateList.findIndex(x => x.registrationNumber === row.registrationNumber);
        setCorporate(corporateList[index]);
        // console.log(corporateList[index]);
        setIndex(index);
    };

    const openDeleteModal = (cell, row) => {
        setSelectedValue(row.registrationNumber);
        setIsDeleteOpen(true);
    };

    const onDeleteCorporate = () => {
        if (selectedValue !== undefined && selectedValue !== null && selectedValue !== '') {
            setCorporateList(corporateList.filter(a => a.registrationNumber !== selectedValue));
            setSelectedValue('');
            sessionStorage.setItem('corporateList', JSON.stringify(corporateList));

            corporateList.map(corp => {
                if (corp.registrationNumber === selectedValue && corp.status === 'DRAFT') {
                    deleteCorporate({
                        variables: { _id: corp._id },
                        onCompleted: e => {
                            setError('');
                        },
                        onError: e => {
                            setSuccess('');
                            setError(e.message);
                        },
                    });
                }
            });
        }
    };

    return (
        <>
            <PageTitle title={'New Corporate'} />
            <CardWrapper>
                <div>
                    <h5>Instruction</h5>
                    <p>
                        Upon successful account creation, login credentials will be sent to registered corporate admin
                        through email/phone.
                    </p>
                </div>
                <br></br>
                <Boundary
                    modal
                    onClick={() => {
                        history.push(`/${origin}${Paths.CORPORATE}`);
                    }}
                    // loading={loading}
                    error={error}
                    success={success}
                    onClose={() => {
                        setSuccess('');
                        setError('');
                    }}>
                    <Button
                        buttonIcon={<i className="ki ki-plus icon-sm" />}
                        label="Add New Corporate"
                        className=" floating-button font-weight-bold px-9"
                        onClick={() => setIsEditOpen(true)}
                    />
                    {corporateList.length > 0 ? (
                        <Table
                            keyField="registrationNumber"
                            columns={columns(openDeleteModal, openEditModal)}
                            data={corporateList}
                        />
                    ) : (
                        ''
                    )}
                </Boundary>
                <br></br>
                {corporateList.length > 0 ? (
                    <Button
                        buttonIcon={<i className="ki ki-plus icon-sm" />}
                        label="Done"
                        className=" floating-button btn-primary"
                        textAlign="right"
                        float="right"
                        onClick={() => {
                            if (corporateList.length > 0) {
                                setIsSaveOpen(true);
                            } else {
                                setIsSaveOpen(false);
                            }
                        }}
                    />
                ) : (
                    ''
                )}

                <EditCorporateModal
                    isOpen={isEditOpen}
                    setIsOpen={setIsEditOpen}
                    setCorporateList={setCorporateList}
                    selectedValue={selectedValue}
                    index={index}
                    corporate={corporate}
                    corporateList={corporateList}
                    setSelectedValue={setSelectedValue}
                    refetchCorporate={refetchCorporate}
                    refetchEmail={refetchEmail}
                    setIndex={setIndex}
                    corporateId={corporateId}
                />
                <DeleteCorporateModal onDelete={onDeleteCorporate} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
                <SaveCorporateModal
                    onSave={onCreateCorporate}
                    onDraft={onSaveCorporate}
                    isOpen={isSaveOpen}
                    setIsOpen={setIsSaveOpen}
                />
            </CardWrapper>
        </>
    );
};

const SaveCorporateModal = props => {
    const { onSave, isOpen, setIsOpen, onDraft } = props;
    return (
        <Modal
            show={isOpen}
            title="Add Corporate"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Save Draft',
                onClick: onDraft,
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                onClick: onSave,
            }}>
            Are you sure to create SpeedCard account for the listed corporate? This action cannot be undone.
        </Modal>
    );
};

const initialValues = corporateList => {
    let values = {
        ...corporateList,
        corporateName: '',
        adminUsername: '',
        email: '',
        phone: '',
        type: '',
        registrationNumber: '',
        maxStaff: '',
        maxTemplate: '',
        maxAnnex: '',
    };

    return values;
};

const EditCorporateModal = props => {
    const {
        isOpen,
        setIsOpen,
        setCorporateList,
        corporateList,
        corporate,
        setSelectedValue,
        index,
        refetchCorporate,
        refetchEmail,
        selectedValue,
    } = props;
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue } = useFormik({
        initialValues: selectedValue ? corporate : initialValues(corporateList),
        validationSchema: corporateProfileInputSchema(corporate, refetchCorporate, refetchEmail, corporateList),
        validateOnMount: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async values => {
            let temp = {
                corporateName: values.corporateName,
                adminUsername: values.type === '1' ? values.email : values.phone,
                phone: values.type === '1' ? '' : values.phone,
                email: values.type === '1' ? values.email : '',
                registrationNumber: values.registrationNumber,
                maxStaff: parseInt(values.maxStaff),
                maxTemplate: parseInt(values.maxTemplate),
                maxAnnex: parseInt(values.maxAnnex),
                type: values.type,
            };

            // console.log(temp);

            if (selectedValue !== undefined && selectedValue !== null && selectedValue !== '') {
                if (index !== undefined && index !== null) {
                    // console.log(temp);
                    const newArr = [...corporateList];
                    newArr[index] = temp;
                    // console.log(newArr);
                    setCorporateList(newArr);
                }
            } else {
                let newCorporate = corporateList.concat(temp);
                setCorporateList(newCorporate);
            }
            setSelectedValue('');
        },
    });

    const types = [
        { type: 'Email Account', val: 1 },
        { type: 'Mobile Account', val: 2 },
    ];

    return (
        <Modal
            backdrop="static"
            keyboard={false}
            show={isOpen}
            title={selectedValue ? 'Edit Corporate' : 'Add Corporate'}
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Cancel',
                onClick: () => setSelectedValue(''),
            }}
            okButton={{
                show: true,
                label: 'Done',
                disabled: !isValid,
                onClick: handleSubmit,
            }}>
            <Form>
                <Input
                    mandatory
                    name="corporateName"
                    label="Name"
                    value={values.corporateName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.corporateName && errors.corporateName}
                    placeholder="Enter corporate name"
                />
                <Select
                    mandatory
                    name="type"
                    label="Account Type"
                    description="**Credentials will be sent to the selected account type"
                    placeholder="Select Account Type"
                    value={values.type}
                    options={types}
                    getOptionLabel={option => option.type}
                    getOptionValue={option => option.val}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // disabledplaceholder={true}
                    error={touched.type && errors.type}
                />
                {values.type === '2' ? (
                    <InputPhoneNumber
                        mandatory={values.type === '2' ? true : false}
                        name="phone"
                        label="Admin Phone"
                        // description="Phone number of primary contact"
                        value={values.phone}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        error={touched.phone && errors.phone}
                    />
                ) : (
                    ''
                )}
                {values.type === '1' ? (
                    <Input
                        mandatory={values.type === '1' ? true : false}
                        name="email"
                        label="Admin Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && errors.email}
                        placeholder="Enter email"
                    />
                ) : (
                    ''
                )}
                <Input
                    mandatory
                    name="registrationNumber"
                    label="Corporate Registration Number"
                    value={values.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.registrationNumber && errors.registrationNumber}
                    placeholder="Enter corporate registration number"
                />
                <Input
                    mandatory
                    type="number"
                    name="maxStaff"
                    label="Max Staff"
                    width={100}
                    min={1}
                    value={values.maxStaff}
                    onChange={e => {
                        // e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                            setFieldValue('maxStaff', value);
                        }
                    }}
                    onBlur={handleBlur}
                    error={touched.maxStaff && errors.maxStaff}
                />
                <Input
                    mandatory
                    type="number"
                    name="maxTemplate"
                    label="Max Template"
                    width={100}
                    min={1}
                    max={3}
                    value={values.maxTemplate}
                    onChange={e => {
                        // e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                            setFieldValue('maxTemplate', value);
                        }
                    }}
                    onBlur={handleBlur}
                    error={touched.maxTemplate && errors.maxTemplate}
                />
                <Input
                    mandatory
                    type="number"
                    name="maxAnnex"
                    label="Max Annex Card"
                    width={100}
                    min={1}
                    max={20}
                    value={values.maxAnnex}
                    onChange={e => {
                        // e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                            setFieldValue('maxAnnex', value);
                        }
                    }}
                    onBlur={handleBlur}
                    error={touched.maxAnnex && errors.maxAnnex}
                />
            </Form>
        </Modal>
    );
};

const DeleteCorporateModal = props => {
    const { onDelete, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Delete Corporate"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: id => {
                    onDelete(id);
                },
            }}>
            Do you want to delete this corporate?
        </Modal>
    );
};

const columns = (openDeleteModal, openEditModal) => [
    {
        dataField: 'corporateName',
        editable: false,
        text: 'Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            return (
                <div className="d-flex">
                    <span className="row-valign-center">{cell}</span>
                </div>
            );
        },
    },
    {
        dataField: 'registrationNumber',
        editable: false,
        text: 'Registration Number',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{cell}</span>
                </div>
            );
        },
    },
    {
        dataField: 'adminUsername',
        editable: false,
        text: 'Admin Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => {
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{cell}</span>
                </div>
            );
        },
    },
    {
        dataField: 'maxStaff',
        editable: false,
        text: 'Max Staff',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div>
                <div className="text-dark-75 font-size-lg mb-0 text-center">{row.maxStaff}</div>
            </div>
        ),
    },
    {
        dataField: 'maxTemplate',
        editable: false,
        text: 'Max Template',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div>
                <div className="text-dark-75 font-size-lg mb-0 text-center">{row.maxTemplate}</div>
            </div>
        ),
    },
    {
        dataField: 'maxAnnex',
        editable: false,
        text: 'Max Annex',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div>
                <div className="text-dark-75 font-size-lg mb-0 text-center">{row.maxAnnex}</div>
            </div>
        ),
    },
    {
        dataField: 'id',
        editable: false,
        text: 'Action',
        formatter: (cell, row) => (
            <div className="d-flex">
                <span>
                    <IconButton
                        title="Edit"
                        type="primary"
                        onClick={() => openEditModal(row)}
                        iconUrl="/media/svg/icons/Home/edit-solid.svg"
                        className="mr-3"
                    />
                </span>
                <span>
                    <IconButton
                        title="Delete"
                        type="danger"
                        onClick={() => openDeleteModal(cell, row)}
                        iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                        className="mr-3"
                    />
                </span>
            </div>
        ),
    },
];

const corporateProfileInputSchema = (corporate, refetchCorporate, refetchName, corporateList) => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            corporateName: Yup.string().required('Required field'),
            registrationNumber: Yup.string()
                .required('Required field')
                .test({
                    message: 'Corporate already exists',
                    test: async values => {
                        let corporateExist = false;
                        if (values !== undefined && values !== null) {
                            let res = await refetchCorporate({
                                registrationNumber: values,
                            });

                            if (values === corporate.registrationNumber) {
                                return true;
                            }

                            if (corporate.length > 0) {
                                if (corporate.registrationNumber === values) {
                                    return true;
                                }
                            }
                            corporateExist = res.data.checkCorporateExist;

                            corporateList.map(v => {
                                if (v.registrationNumber === values) {
                                    corporateExist = true;
                                }
                            });

                            if (corporateExist) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    },
                }),
            email: Yup.string().when('type', {
                is: '2',
                then: Yup.string()
                    .nullable()
                    .notRequired(),
                otherwise: Yup.string()
                    .required('Required field')
                    .email('Wrong email format'),
            }),
            phone: Yup.string().when('type', {
                is: '2',
                then: Yup.string().required('Required field'),
                otherwise: Yup.string()
                    .nullable()
                    .notRequired(),
            }),
            maxStaff: Yup.string().required('Required field'),
            maxTemplate: Yup.string()
                .required('Required field')
                .test({
                    message: 'Max template is 3',
                    test: async values => {
                        if (parseInt(values) > 3) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                }),
            maxAnnex: Yup.string()
                .required('Required field')
                .test({
                    message: 'Max annex card is 20',
                    test: async values => {
                        if (parseInt(values) > 20) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                }),
        });
    });
};

export default CorporateForm;
