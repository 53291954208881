/** @format */

import React, { useState, useEffect } from "react";
import { Boundary } from "src/app/components";

const qrcode = require("qrcode");

export const QRCode = props => {
  const { input } = props;
  const [error, setError] = useState(null);

  useEffect(() => {
    const canvas = document.getElementById("qrcode_canvas");
    qrcode.toCanvas(canvas, input || "a", function(e) {
      if (e) setError(e);
    });
  }, []);

  return (
    <Boundary error={error} onClose={() => setError("")}>
      <canvas id="qrcode_canvas"></canvas>
    </Boundary>
  );
};
