/** @format */

import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { PageTitle } from 'src/app/layout';
import { Boundary, FormPanelWrapper, FormWrapper, Input, Form, FormButtonWrapper, Button } from 'src/app/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AuthContext } from 'src/app/context';
import * as Paths from 'src/app/routes/paths';
import { GET_SYSTEM_SETTINGS, UPDATE_SYSTEM_SETTINGS } from 'src/app/api/graphql';
import { useQueryProxy } from 'src/app/helpers/util';

const SystemSettings = props => {
    const { history, origin } = props;
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const { getSessionStorage } = useContext(AuthContext);
    // const { _id } = getSessionStorage() || {};

    const { data: systemSettings, loading, refetch } = useQueryProxy(GET_SYSTEM_SETTINGS, {
        variables: {},
    });

    const [updateSystemSettings, updateSystemSettingsCtx] = useMutation(UPDATE_SYSTEM_SETTINGS, {
        onError: error => setError(error.message),
        onCompleted: () => setSuccess('Successfully update system settings'),
    });

    const onSubmit = async values => {
        updateSystemSettings({
            variables: {
                input: {
                    _id: systemSettings._id,
                    maxMainCard: values.maxMainCard,
                    maxAnnexCard: values.maxAnnexCard,
                    maxTags: values.maxTags,
                },
            },
            onCompleted: e => {
                setError('');
                refetch();
            },
            onError: e => {
                setSuccess('');
                setError(e.message);
            },
        });
    };

    return (
        <>
            <PageTitle title="System Settings" />
            <Boundary
                error={error}
                success={success}
                onClose={() => {
                    setError('');
                    setSuccess('');
                }}>
                <FormPanelWrapper>
                    <FormWrapper>
                        <SystemSettingsForm
                            onSubmit={onSubmit}
                            systemSettings={systemSettings}
                            history={history}
                            origin={origin}
                        />
                    </FormWrapper>
                </FormPanelWrapper>
            </Boundary>
        </>
    );
};

export const SystemSettingsForm = props => {
    const { onSubmit, systemSettings, history, origin } = props;

    const {
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
    } = useFormik({
        initialValues: {
            maxMainCard: systemSettings.maxMainCard,
            maxAnnexCard: systemSettings.maxAnnexCard,
            maxTags: systemSettings.maxTags,
        },
        validationSchema: SystemSettingsSchema,
        validateOnMount: true,
        validateOnChange: true,
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: async (values, { _, setSubmitting }) => {
            try {
                setSubmitting(true);
                await onSubmit(values);
                setSubmitting(false);
                resetForm();
            } catch (e) {}
        },
    });

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                name="maxMainCard"
                label="Max Main Card"
                value={values.maxMainCard}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.maxMainCard && errors.maxMainCard}
                type="number"
            />
            <Input
                name="maxAnnexCard"
                label="Max Annex Card"
                value={values.maxAnnexCard}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.maxAnnexCard && errors.maxAnnexCard}
                type="number"
            />
            <Input
                name="maxTags"
                label="Max Card Tags"
                value={values.maxTags}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.maxTags && errors.maxTags}
                type="number"
            />
            <FormButtonWrapper>
                <Button
                    // type="submit"
                    className="floating-button mr-2"
                    label="Cancel"
                    onClick={() => {
                        history.push(`/${origin}${Paths.SETTINGS}`);
                    }}
                />
                <Button
                    className=" floating-button "
                    label="Save"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid}
                />
            </FormButtonWrapper>
        </Form>
    );
};

export const SystemSettingsSchema = Yup.object({
    maxMainCard: Yup.number().required('Required field'),
    maxAnnexCard: Yup.number().required('Required field'),
    maxTags: Yup.number().required('Required field'),
});

export default SystemSettings;
