/** @format */

import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

export const fetcher = (options = {}) => {
    return axios.create({
        baseURL: REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...options,
        },
    });
};

export const fileFetcher = (options = {}) => {
    const token = sessionStorage.getItem('token');
    return axios.create({
        baseURL: REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            ...options,
        },
    });
};

// export const templateFetcher = (options = {}) => {
//   return axios.create({
//     baseURL: REACT_APP_DOCUMENT_API_URL,
//     headers: {
//       "Content-Type": "application/json",
//       ...options
//     }
//   });
// };
