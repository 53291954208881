/** @format */

export const STATUS = {
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    deleted: 'DELETED',
    pending: 'PENDING',
};

export const STATUS_ACTIVITY = {
    ADDED: 'ADDED',
    MODIFIED: 'MODIFIED',
    DELETED: 'DELETED',
};

export const STATUS_INVOICE = {
    PROFORMA: 'PROFORMA',
    ISSUED: 'ISSUED',
    PAID: 'PAID',
    VOID: 'VOID',
    CREDIT_NOTE: 'CREDIT NOTE',
};

export const STATUS_ENTITY = {
    draft: 'DRAFT',
    submitted: 'SUBMITTED',
    requestForAmendment: 'RETURNED',
    pendingForPayment: 'PENDING FOR PAYMENT',
    active: 'ACTIVE',
    closed: 'CLOSED',
};

export const STATUS_STAFF = {
    draft: 'DRAFT',
    confirmed: 'CONFIRMED',
    inactive: 'INACTIVE',
    pending: 'PENDING',
    active: 'ACTIVE',
    closed: 'CLOSED',
};

export const STATUS_APPLICATION = {
    incomplete: 'INCOMPLETE',
    completed: 'COMPLETED',
    unverified: 'UNVERIFIED',
    requestForAmendment: 'RETURNED',
    verified: 'VERIFIED',
    notSigned: 'NOT SIGNED',
    signed: 'SIGNED',
    paid: 'PAID',
    closed: 'CLOSED',
};

export const STAKEHOLDER_PRESET_ROLE_CODE = {
    individualShareholder: 'INDIVIDUAL_SHAREHOLDER',
    localComapnyShareholder: 'LOCAL_COMPANY_SHAREHOLDER',
    foreignCompanyShareholder: 'FOREIGN_COMPANY_SHAREHOLDER',
    director: 'DIRECTOR',
    ubo: 'UBO',
    auditor: 'c7f384b5-ff7b-4f88-a1cb-610abafbeedf',
};

export const STAKEHOLDER_PRESET_ROLES = [
    {
        code: STAKEHOLDER_PRESET_ROLE_CODE.individualShareholder,
        label: 'Individual Shareholder',
    },
    {
        code: STAKEHOLDER_PRESET_ROLE_CODE.localComapnyShareholder,
        label: 'Local Company Shareholder',
    },
    {
        code: STAKEHOLDER_PRESET_ROLE_CODE.foreignCompanyShareholder,
        label: 'Foreign Company Shareholder',
    },
    { code: STAKEHOLDER_PRESET_ROLE_CODE.director, label: 'Director' },
    { code: STAKEHOLDER_PRESET_ROLE_CODE.ubo, label: 'UBO' },
    { code: STAKEHOLDER_PRESET_ROLE_CODE.auditor, label: 'Auditor' },
];

export const STATUS_TRUSTS = {
    ACTIVE: 'ACTIVE',
    CLOSED: 'CLOSED',
    CLOSING: 'CLOSING',
};

export const TYPE_INVOICE = {
    INVOICE: 'INVOICE',
    BILLING: 'BILLING',
};

export const TYPE_LETTER_OF_WISHES = {
    DURING_LIFETIME: 'During Lifetime',
    AFTER_LIFETIME: 'After Lifetime',
};

export const TEMPLATE_CODE = {
    directorConsent: '9481cbe1-2d9c-4ba2-8d87-b4e5b97d1b55',
    directorConsentWeb: '85311f09-8e8a-40ae-89e8-1e40b98217da',
    draftConstitution: 'eb7df692-620c-49f5-9fd6-73bf03ed06a9',
    draftConstitutionWeb: '05f4db99-bab3-404c-8508-0e5769eb549e',
    letterOfEngagement: 'f399d06d-ffbc-489e-ba55-cc3afe22ae99',
    letterOfEngagementWeb: 'c8bafc8e-a997-4406-b264-63f8aeee10b5',
};

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const MYCORP_LOGIN = 'myCorp';
export const WEB_LOGIN = 'web';
export const defaultColor = '#3F4254';
export const defaultBackgroundColor = '#FFFFFF';
export const defaultLogoUrl =
    'https://mcusercontent.com/16c33aaf9dec402563adea72c/images/52013f12-2c54-401f-9cd8-cd837853b581.png';

export const logos = ['/media/logos/logo-default.png'];

export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_FORMAT_DETAILED = 'DD MMM YY HH:mm:ss';
