/** @format */

import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Form, Button, Input, PasswordInput, Checkbox, Boundary } from 'src/app/components';

import { registration } from 'src/app/api';
import * as Paths from 'src/app/routes/paths';

const btoa = require('btoa');
// const { REACT_APP_SPEEDCARD_ORGANIZATION_CODE } = process.env;

export const ADD_NEW_PERSON = gql`
    mutation addNewPerson($person: PersonInput!) {
        addNewPerson(person: $person)
    }
`;

const initialValues = {
    username: '',
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    company: '',
    address1: '',
    address2: '',
    state: '',
    postal: '',
    userEmail: '',
    acceptTerms: false,
    file: null,
};

const Registration = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const onSubmit = async values => {
        try {
            const type = 2;
            const address = values.address1 + ' ' + values.address2 + ' ' + values.postal + ' ' + values.state;
            const credentials = `company=${values.company};name=${values.name};email=${values.email};phone=${values.phone};userEmail=${values.userEmail};password=${values.password};address=${address};type=${type}`;
            const res = await registration({
                credentials: btoa(credentials),
                status: 'INACTIVE',
            });

            const { message } = res.data || {};

            if (res.data) setSuccess(message);
            else setError(message);
        } catch (e) {
            const { response } = e || {};
            const { data } = response || {};
            setError(typeof data === 'string' ? data : e.message);
        }
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        // setFieldValue,
        values,
        errors,
        touched,
        isValid,
        isSubmitting,
    } = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        validateOnMount: true,
        validateOnChange: true,
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: async values => {
            onSubmit(values);
        },
    });

    return (
        <>
            {success ? (
                <div className="registration-form login-signin" style={{ display: 'block' }}>
                    <div className="text-cmb-10 mb-lg-20">
                        <h4 className="mb-6">Your registration is successful. </h4>
                        <p>
                            You will receive a confirmation email from us and will be required to acknowledge this
                            registration.
                        </p>
                    </div>
                    <div className="d-flex flex-column justify-content-calign-items-center">
                        <Link to="/">
                            <button
                                type="button"
                                className="floating-button btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
                                Login
                            </button>
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="registration-form login-signin" style={{ display: 'block' }}>
                    <div className="text-cmb-10 mb-lg-10 textalign-center ">
                        <h3 className="font-size-h1">Sign Up</h3>

                        <br></br>
                        <br></br>
                        <h4 className="font-weight-bold">Corporate Details</h4>
                    </div>
                    <Boundary
                        loading={isSubmitting}
                        error={error}
                        onClose={() => {
                            setSuccess('');
                            setError('');
                        }}>
                        <Form onSubmit={handleSubmit}>
                            <Input
                                placeholder="Corporate Name"
                                name="company"
                                label="Corporate Name"
                                value={values.company}
                                onChange={handleChange}
                                width={400}
                                onBlur={handleBlur}
                                error={touched.company && errors.company}
                            />
                            <Input
                                placeholder="Address Line 1"
                                name="address1"
                                label="Address"
                                value={values.address1}
                                onChange={handleChange}
                                width={400}
                                onBlur={handleBlur}
                                error={touched.address1 && errors.address2}
                            />
                            <Input
                                placeholder="Address Line 2"
                                name="address2"
                                label=" "
                                value={values.address2}
                                onChange={handleChange}
                                width={400}
                                onBlur={handleBlur}
                                error={touched.address2 && errors.address2}
                            />
                            <Input
                                label=" "
                                placeholder="State"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                width={400}
                                error={touched.state && errors.state}
                                // inline={true}
                            />
                            <Input
                                placeholder="Postal Code"
                                label=" "
                                name="postal"
                                value={values.postal}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                width={200}
                                error={touched.postal && errors.postal}
                                // inline={true}
                            />

                            {/* <div className="form-group d-flex flex-center">
                <Row>
              <Col>
              <Input 
                label=" "
                placeholder="State"
                name="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                
                error={touched.state && errors.state}
                />
                  </Col><br></br>
                  <Col>
                    <Input 
                  placeholder="Postal Code"
                  name="postal"
                  value={values.postal}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.postal && errors.postal}
                  />

                  </Col></Row>
              </div> */}
                            <Input
                                placeholder="Phone"
                                name="phone"
                                width={400}
                                label="Phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.phone && errors.phone}
                            />
                            <Input
                                placeholder="Email"
                                name="email"
                                width={400}
                                label="Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && errors.email}
                            />
                            {/* <Input
                type="file"
                name="acra"
                label="Relevant Document"
                value={values.file}
                onChange={handleChange}
                width={400}
                onBlur={handleBlur}
                error={touched.file && errors.file}
              /> */}
                            <Input
                                id="file"
                                name="file"
                                type="file"
                                label="BizFile ACRA"
                                description="*Upload official documents to verify your business"
                                onChange={handleChange}
                                width={400}
                                onBlur={handleBlur}
                                value={values.file}
                                error={errors.file && touched.file}
                            />

                            <div className="text-cmb-10 mb-lg-10 textalign-center ">
                                <br></br>
                                <h4 className="font-weight-bold">User Details</h4>
                            </div>
                            <Input
                                placeholder="Email"
                                name="userEmail"
                                label="User Email"
                                value={values.userEmail}
                                width={400}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.userEmail && errors.userEmail}
                            />
                            <Input
                                placeholder="Name"
                                name="name"
                                label="Full Name"
                                value={values.name}
                                width={400}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.name && errors.name}
                            />
                            <PasswordInput
                                autoComplete="new-password"
                                placeholder="Password"
                                width={400}
                                name="password"
                                label="Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && errors.password}
                            />
                            <PasswordInput
                                autoComplete="new-password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                width={400}
                                label="Confirm Password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.confirmPassword && errors.confirmPassword}
                            />
                            <div className="form-group d-flex flex-wrap flex-center">
                                <Row>
                                    <Col xs="12">
                                        <div className="px-5">
                                            <Checkbox
                                                label={
                                                    <Link
                                                        to={`/public${Paths.TERMS}`}
                                                        target="_blank"
                                                        className="mr-1"
                                                        rel="noopener noreferrer">
                                                        {`I agree the Terms & Conditions`}
                                                    </Link>
                                                }
                                                name="acceptTerms"
                                                value={values.acceptTerms}
                                                onChange={handleChange}
                                                error={touched.acceptTerms && errors.acceptTerms}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="6">
                                        <Button
                                            type="submit"
                                            label="Submit"
                                            disabled={!isValid}
                                            loading={isSubmitting}
                                            className=" floating-button font-weight-bold px-9 py-4 my-3 mx-4"
                                        />
                                    </Col>
                                    <Col xs="6" className="textalign-right">
                                        <Link to="/">
                                            <Button
                                                variant="light"
                                                label="Cancel"
                                                className=" floating-button font-weight-bold px-9 py-4 my-3 mx-4"
                                            />
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Boundary>
                </div>
            )}
        </>
    );
};

const RegistrationSchema = Yup.object().shape({
    company: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    userEmail: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    phone: Yup.string(),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    confirmPassword: Yup.string()
        .required('Required field')
        .when('password', {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        }),
    address1: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    address2: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    state: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    postal: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Required field'),
    acceptTerms: Yup.bool()
        .required('You must accept the terms and conditions')
        .oneOf([true], 'You must accept the terms and conditions'),
    file: Yup.mixed().required('File is required'),
    // .test('fileSize', 'File too large', (value) => value === null || (value && value.size <= FILE_SIZE))
    // .test(
    //   'fileFormat',
    //   'Unsupported file type',
    //   (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type))
});

export default Registration;
