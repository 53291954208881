/** @format */

import React, { useContext, useState } from 'react';

import { Aside, QuickUser, Breadcrumb } from 'src/app/components';
import { HeaderMobile } from './index';
import { LayoutInit } from './LayoutInit';
import { PageHeader } from './';
import { ScrollTop } from 'src/_metronic/layout/components/extras/ScrollTop';
import { AnimateLoading } from 'src/_metronic/_partials/controls';
import clsx from 'clsx';
import { Modal } from 'src/app/components';
import { AuthContext } from 'src/app/context';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

export const UserLayout = props => {
    const { location, children } = props;
    const { getSessionStorage, changeCorporate } = useContext(AuthContext);

    // const {
    //   data: personNotification,
    //   loading: loadingPersonNotification,
    //   refetch: refetchPersonNotification
    // } = useQueryProxy(GET_NOTIFICATIONS, {
    //   variables: {
    //     type: `Reminder`,
    //     to: username
    //   }
    // });

    const isDashboard = () => {
        const { pathname = '' } = location || {};
        if (pathname.includes('dashboard')) return true;
        return false;
    };

    // const isFaq = () => {
    //     const { pathname = '' } = location || {};
    //     if (pathname.includes('help')) return true;
    //     return false;
    // };

    const hideLayout = () => {
        const { pathname = '' } = location || {};
        if (pathname.includes('entity-card') || pathname.includes('individual-card')) return true;
        return false;
    };

    const [isCorpOptionOpen, setIsCorpOptionOpen] = useState(false);

    const openCorpOptionsModal = () => {
        setIsCorpOptionOpen(true);
    };

    return hideLayout() ? (
        <div className={`container`}>{children}</div>
    ) : (
        <>
            <HeaderMobile />
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                    <Aside {...props} openCorpOptionsModal={openCorpOptionsModal} />
                    <div className="d-flex flex-column flex-row-fluid wrapper">
                        <PageHeader
                            title="Dashboard"
                            showGreeting
                            // personNotification={personNotification}
                            // loadingPersonNotification={loadingPersonNotification}
                        />
                        <CorpOptionModal
                            isOpen={isCorpOptionOpen}
                            setIsOpen={setIsCorpOptionOpen}
                            getSessionStorage={getSessionStorage}
                            changeCorporate={changeCorporate}
                        />
                        {!isDashboard() && <Breadcrumb location={location} />}
                        <div
                            className={clsx('d-flex flex-column flex-column-fluid procorp-content-grey', {
                                content: !isDashboard(),
                            })}>
                            {!isDashboard() && <br />}
                            <AnimateLoading />
                            <div className="d-flex flex-column-fluid">
                                <div
                                    className={`container ${isDashboard() &&
                                        'full-screen-container procorp-dashboard'}`}>
                                    <div>{children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuickUser />
            <ScrollTop />
            <LayoutInit />
        </>
    );
};

const CorpOptionModal = props => {
    const { isOpen, setIsOpen, getSessionStorage, changeCorporate } = props;
    return (
        <Modal
            show={isOpen}
            title="Switch Corporate"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Close',
            }}
            okButton={{
                show: false,
                label: 'Yes',
            }}>
            <CorpList getSessionStorage={getSessionStorage} changeCorporate={changeCorporate} />
        </Modal>
    );
};

const CorpList = props => {
    const { getSessionStorage, changeCorporate } = props;
    const { corporateList, corporateId } = getSessionStorage();

    const newList = JSON.parse(corporateList).filter(
        corp => corp.status !== 'INACTIVE' && corp._doc.status !== 'INACTIVE'
    );

    const customHandleChange = e => {
        const corpId = e.target.value;
        if (corpId !== undefined && corpId !== null) {
            changeCorporate(corpId);
        }
    };

    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={customHandleChange}
                value={corporateId}>
                {newList.map(corp => {
                    return (
                        <FormControlLabel
                            key={corp._id}
                            value={corp._id}
                            control={<Radio />}
                            label={`${corp.corporateName} (${corp.corporateKey})`}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};
