/** @format */

import { gql } from '@apollo/client';

export const GET_CARD_BY_USERID = gql`
    query($_userId: String!) {
        getCardByUserId(_userId: $_userId) {
            _id
            name
            category
        }
    }
`;

export const UPDATE_CARD = gql`
    mutation updateCard($input: CardInputUpdate!) {
        updateCard(input: $input) {
            _userId
        }
    }
`;

export const CORPORATE_RECALL_CARD = gql`
    mutation($staff: String!) {
        corporateRecallCard(staff: $staff)
    }
`;

export const CORPORATE_ASSIGN_CARD = gql`
    mutation($staff: String!, $templateId: String!, $corporateName: String!, $corporateId: String!) {
        corporateAssignCard(
            staff: $staff
            templateId: $templateId
            corporateName: $corporateName
            corporateId: $corporateId
        )
    }
`;
