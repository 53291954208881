/** @format */

import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import * as Pages from 'src/app/pages';
import * as Paths from 'src/app/routes/paths';
import { PublicRoute } from './Routes';

export const PublicRoutes = props => {
    const { path } = props.match;

    return (
        <Switch>
            <Redirect exact from="/public" to={`/public${Paths.PUBLIC_HOME}`} />
            <PublicRoute path={path + Paths.LOGIN} component={Pages.Login} />
            <PublicRoute path={path + Paths.FORGOT_PASSWORD} component={Pages.ForgotPassword} />
            <PublicRoute path={path + Paths.FORGOT_USERNAME} component={Pages.ForgotUsername} />
            <PublicRoute path={path + Paths.CHANGE_PASSWORD} component={Pages.ChangePassword} />
            <PublicRoute path={path + Paths.REGISTRATION} component={Pages.Registration} />
            <PublicRoute path={path + Paths.VERIFY_EMAIL} component={Pages.VerifyEmail} />
            <PublicRoute path={path + Paths.SYSTEM_SETTINGS} component={Pages.SystemSettings} />

            <PublicRoute path={path + Paths.PUBLIC_HOME} component={Pages.PublicHome} />
            <PublicRoute path={path + Paths.TERMS} component={Pages.Terms} />
            <PublicRoute path={path + Paths.PRIVACY} component={Pages.Privacy} />
            <PublicRoute path={path + Paths.LOGOUT} component={Pages.Logout} />
            <PublicRoute path={path + '*'} component={Pages.ErrorsPage} />
        </Switch>
    );
};
