/** @format */

import { gql } from '@apollo/client';

export const GET_CORPORATE_BY_USERID = gql`
    query($_userId: String!) {
        getCorporateByUserId(_userId: $_userId) {
            _id
            corporateName
            corporateKey
            adminUsername
            website
            phone
            address
            registrationNumber
            icon
        }
    }
`;

export const GET_CORPORATE_BY_ID = gql`
    query($_id: String!) {
        getCorporateById(_id: $_id) {
            _id
            corporateName
            website
            adminUsername
            phone
            address
            registrationNumber
            icon
            maxStaff
            maxAnnex
            maxTemplate
            icon
        }
    }
`;

export const UPDATE_CORPORATE_INFO = gql`
    mutation($input: CorporateInputUpdate!) {
        updateCorporate(input: $input) {
            corporateName
            adminUsername
            maxStaff
            maxTemplate
            maxCard
            phone
            icon
            address
            registrationNumber
        }
    }
`;

export const UPDATE_CORPORATE_INFO_2 = gql`
    mutation($input: CorporateInputUpdate!) {
        updateCorporateV2(input: $input) {
            corporateName
            adminUsername
            maxStaff
            maxTemplate
            maxCard
            phone
            icon
            address
            registrationNumber
            website
        }
    }
`;

export const CREATE_CORPORATE = gql`
    mutation($input: String!) {
        createCorporate(input: $input) {
            corporate {
                _id
                corporateName
                maxStaff
                maxCard
                maxTemplate
                maxAnnex
                registrationNumber
            }
            user {
                _id
            }
        }
    }
`;

export const GET_CORPORATE = gql`
    query listAllCorporate {
        listAllCorporate {
            _id
            corporateName
            corporateKey
            registrationNumber
            status
            adminUsername
            createdDt
            maxStaff
            maxCard
            maxAnnex
            maxTemplate
        }
    }
`;

export const GET_DRAFT_CORPORATE = gql`
    query listAllDraftCorporate {
        listAllDraftCorporate {
            _id
            corporateName
            registrationNumber
            status
            phone
            adminUsername
            createdDt
            maxStaff
            maxCard
            maxAnnex
            maxTemplate
        }
    }
`;

export const CHECK_CORPORATE_EXIST = gql`
    query($registrationNumber: String!) {
        checkCorporateExist(registrationNumber: $registrationNumber)
    }
`;

export const DELETE_CORPORATE = gql`
    mutation($_id: String!) {
        deleteCorporateById(_id: $_id) {
            _id
            status
        }
    }
`;

export const GET_CORPORATE_TEMPLATE_LIST = gql`
    query($corporateId: String!) {
        getCorporateTemplates(corporateId: $corporateId) {
            _id
        }
    }
`;
