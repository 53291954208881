/** @format */

import React, { useState, createContext } from "react";
import { Modal } from "src/app/components";

export const FormContext = createContext({});

export const FormProvider = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [formConfig, setFormConfig] = useState({
    form: null,
    onFormSubmit: () => {},
    onFormCancel: () => {},
    values: {},
    title: "Form"
  });

  const openForm = config => {
    setFormConfig({
      ...formConfig,
      ...config,
      values: config.values || {}
    });
    setIsOpen(true);
  };

  const closeForm = () => {
    formConfig.onFormCancel && formConfig.onFormCancel(formConfig);
    setIsOpen(false);
  };

  return (
    <FormContext.Provider
      value={{
        openForm,
        closeForm,
        ...formConfig
      }}
    >
      <Modal
        show={isOpen}
        title={formConfig.title}
        onToggle={() => setIsOpen(!isOpen)}
        okButton={{
          show: formConfig.okButton || false,
          onClick: () => formConfig.onFormSubmit(formConfig),
          ...formConfig.okButton
        }}
        cancelButton={{
          show: formConfig.cancelButton || false,
          onClick: closeForm,
          ...formConfig.cancelButton
        }}
      >
        {formConfig.form && (
          <>
            {typeof formConfig.form === "function" && (
              <formConfig.form {...props} />
            )}
            {typeof formConfig.form === "string" && formConfig.form}
          </>
        )}
      </Modal>
      {props.children}
    </FormContext.Provider>
  );
};
