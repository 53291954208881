/** @format */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { capitalize } from 'lodash';

import * as Paths from 'src/app/routes/paths';

export const Breadcrumb = props => {
    const { location } = props;
    const { pathname } = location || {};
    const [items, setItems] = useState([]);

    useEffect(() => {
        const split = pathname.split('/');
        let tempItems = [];

        if (split.length > 2) {
            const pathsObj = JSON.parse(JSON.stringify(Paths));
            tempItems.push({ name: 'Dashboard', path: `/${split[1]}` });

            for (let i = 2; i < split.length; i++) {
                const prevPathname = '/' + split[i - 2];
                const currentPathname = '/' + split[i];
                const { path: prevPath } = tempItems[tempItems.length - 1] || {};

                if (Object.values(pathsObj).indexOf(`/${split[i]}`) > -1) {
                    switch (currentPathname) {
                        case Paths.STAFF_FORM:
                            tempItems.push({
                                name: 'Staff',
                                path: `${prevPath}${Paths.STAFF}`,
                            });
                            break;
                        case Paths.CORPORATE_FORM:
                            tempItems.push({
                                name: 'Corporate',
                                path: `${prevPath}${Paths.CORPORATE}`,
                            });
                            break;
                        case Paths.USER_FORM:
                            tempItems.push({
                                name: 'Users',
                                path: `${prevPath}${Paths.USERS}`,
                            });
                            break;

                        case Paths.ASSIGN_CARD:
                            tempItems.push({
                                name: 'Manage Cards',
                                path: `${prevPath}${Paths.MANAGE_CARDS}`,
                            });
                            break;
                        case Paths.TEMPLATE_FIELDS:
                            tempItems.push({
                                name: 'Cards',
                                path: `${prevPath}${Paths.CARDS}`,
                            });
                            break;
                        // case Paths.CREATE_TEMPLATE_INPUT:
                        //     tempItems.push({
                        //         name: 'Template Input',
                        //         path: `${prevPath}${Paths.TEMPLATE_INPUT}`,
                        //     });
                        //     break;
                        // case Paths.EDIT_TEMPLATE_INPUT:
                        //     tempItems.push({
                        //         name: 'Template Input',
                        //         path: `${prevPath}${Paths.TEMPLATE_INPUT}`,
                        //     });
                        //     break;

                        case Paths.EDIT_CORPORATE:
                        case Paths.CHANGE_PASSWORD:
                        case Paths.USERS:
                        case Paths.AUDIT_LOG:
                        case Paths.COUNTRY:
                        case Paths.SITE_SETTINGS:
                        case Paths.ENTITY_TYPE:
                        case Paths.SUB_ENTITY_TYPE:
                        case Paths.ENTITY_INDUSTRY:
                        case Paths.CURRENCY:
                        case Paths.ENTITY_INDIVIDUAL_ROLE:
                        case Paths.IDENTIFICATION_TYPE:
                        case Paths.NATIONALITY:
                        case Paths.TRUST_TYPE:
                        case Paths.TRUST_SUB_TYPE:
                        case Paths.PROFESSION:
                        case Paths.SPECIALISATION:
                        case Paths.DOCUMENT_TEMPLATE:
                        case Paths.DOCUMENT_TEMPLATE_CATEGORY:
                        // case Paths.ASSET_TYPE:
                        case Paths.SOURCE_OF_FUNDS:
                        case Paths.SOURCE_OF_WEALTH:
                        case Paths.RISK_TYPE:
                        case Paths.BUSINESS_ACTIVITY:
                        case Paths.HELP_SETTINGS:
                            tempItems.push({
                                name: 'Settings',
                                path: `${prevPath}${Paths.SETTINGS}`,
                            });
                            break;
                    }

                    const name = split[i].replace('-', ' ').replace(/\w+/g, function(match) {
                        return capitalize(match);
                    });
                    tempItems.push({ name, path: `${prevPath}/${split[i]}` });
                } else {
                    // switch (prevPathname) {
                    //     case Paths.ENTITY_CARD:
                    //         tempItems.push({
                    //             name: 'Entity',
                    //             path: `${prevPath}/${split[i]}`,
                    //         });
                    //         break;
                    //     case Paths.INDIVIDUAL_CARD:
                    //         tempItems.push({
                    //             name: 'Stakeholder',
                    //             path: `${prevPath}/${split[i]}`,
                    //         });
                    //         break;
                    // }
                }
            }
            tempItems[0].path = tempItems[0].path + Paths.DASHBOARD;
        }

        setItems(tempItems);
    }, [pathname]);

    const handleClick = (e, disabled) => {
        if (disabled) e.preventDefault();
    };

    if (items.length === 0) return <></>;

    return (
        <nav className="border-bottom">
            <ol className="custom-breadcrumb">
                {items.map((x, i) => {
                    const isActive = i === items.length - 1;
                    return (
                        <li key={i} className="breadcrumb-item">
                            <NavLink
                                to={x.path}
                                className={clsx('breadcrumb-item custom-breadcrumb-item', {
                                    active: isActive,
                                })}
                                onClick={e => handleClick(e, isActive)}>
                                {x.name}
                            </NavLink>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};
