/** @format */

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { Button, CenterWrapper } from "src/app/components";
import { verifyEmail } from "src/app/api/auth";
import * as Paths from "src/app/routes/paths";

export default function VerifyEmail() {
  // Get token from URL
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    async function requestVerification() {
      const res = await verifyEmail({ token });
      const { success, message } = res.data || {};

      if (success) setSuccess(message);
      else setError(message);
    }

    requestVerification();
    setLoading(false);
  }, []);

  return (
    <CenterWrapper className="h-100 text-center">
      {loading ? (
        <p>Verifying email...</p>
      ) : (
        <>
          {success && (
            <div>
              <h4>{success}</h4>
              <Link to={`/public${Paths.LOGIN}`}>
                <Button
                  label="Login"
                  className=" floating-button font-weight-bold px-9 py-4 my-12"
                />
              </Link>
            </div>
          )}
          {error && <h4>{error}</h4>}
        </>
      )}
    </CenterWrapper>
  );
}
