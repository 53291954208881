/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import 'src/_metronic/_assets/sass/pages/login/login.scss';
import { Footer } from 'src/app/layout/Footer';
import * as Paths from 'src/app/routes/paths';

export const PublicLayout = props => {
    const { location, children } = props;
    const home = location.pathname === `/public${Paths.PUBLIC_HOME}`;

    // Simple hack, coz this layout is shared by edit stakeholder form which requires 100% width and rest of public
    // routes which requires 75% width, aligned center
    const loginClz =
        location.pathname.indexOf('edit-stakeholder') >= 0
            ? 'login login-1 login-signin-on flex-center'
            : 'login login-1 login-signin-on d-flex flex-grow-1 flex-center';

    return (
        <div className="d-flex flex-column flex-root">
            <div style={{ backgroundColor: '#212529' }}>
                <div className="d-flex align-items-center py-3 px-3 py-md-5 px-md-8">
                    <div className="col">
                        {/* <Link to="/"> */}
                        <img
                            alt="SpeedCard"
                            src={toAbsoluteUrl('/media/logos/logo-default.png')}
                            style={{
                                marginTop: '10px',
                                maxHeight: '51px',
                                maxWidth: '100%',
                            }}
                        />
                        {/* </Link> */}
                    </div>
                    <div className="d-flex justify-content-end col">
                        {/* {home && (
                            <Link to="/public/login">
                                <Button label="Sign in" />
                            </Link>
                        )} */}
                    </div>
                </div>
            </div>
            <div className={clsx(loginClz, !home && 'p-7')}>{children}</div>
            {/* </div> */}
            <Footer publicFooter />
        </div>
    );
};
