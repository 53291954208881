/** @format */

// auth
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTRATION = '/registration';
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGOT_USERNAME = '/forgot-username';
export const CHANGE_PASSWORD = '/change-password';
export const VERIFY_EMAIL = '/verify-email/:token';

// general
export const PUBLIC_HOME = '/login';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const ERROR = '/error';

// pages
export const DASHBOARD = '/dashboard';
export const PROFILE = '/profile';
export const EDIT_CORPORATE = '/edit-corporate';
export const NOTIFICATION = '/notification';
export const USER_CHANGE_PASSWORD = '/change-password';

export const MY_ENTITY = '/my-entity';
export const ENTITY_FORM = '/entity-form';
export const DOCUMENT_FORM = '/document-form/:documentType/:token';
export const ENTITY_CARD = '/entity-card/:code';
export const INDIVIDUAL_CARD = '/individual-card/:code';
export const INDIVIDUALS = '/individuals';

// speedcard
export const CONTACTS = '/contacts';
export const STAFF = '/staff';
export const STAFF_FORM = '/staff-form';
export const CREATE_TEMPLATE = '/create-template';
export const ANALYTICS = '/analytics';
export const ADMIN_ANALYTICS = '/admin-analytics';
export const GENERAL_ANALYTICS = '/general-analytics';
export const CORPORATE = '/corporate';
export const CORPORATE_FORM = '/corporate-form';
export const USER_FORM = '/user-form';

// entity management
export const ACTIVITY = '/activity/';
export const ASSET = '/asset/';

// settings
export const SETTINGS = '/settings';
export const USERS = '/users';
export const AUDIT_LOG = '/audit-log';
export const COUNTRY = '/country';
export const SITE_SETTINGS = '/site-settings';
export const ENTITY_TYPE = '/entity-type';
export const SUB_ENTITY_TYPE = '/sub-entity-type';
export const ENTITY_INDUSTRY = '/entity-industry';
export const CURRENCY = '/currency';
export const ENTITY_INDIVIDUAL_ROLE = '/entity-individual-role';
export const IDENTIFICATION_TYPE = '/identification-type';
export const NATIONALITY = '/nationality';
export const TRUST_TYPE = '/trust-type';
export const TRUST_SUB_TYPE = '/trust-sub-type';
export const PROFESSION = '/profession';
export const SPECIALISATION = '/specialisation';
export const DOCUMENT_TEMPLATE = '/document-template';
export const DOCUMENT_TEMPLATE_CATEGORY = '/document-template-category';
// export const ASSET_TYPE = '/asset-type';
export const SOURCE_OF_FUNDS = '/source-of-funds';
export const SOURCE_OF_WEALTH = '/source-of-wealth';
export const RISK_TYPE = '/risk-type';
export const BUSINESS_ACTIVITY = '/business-activity';
export const HELP_SETTINGS = '/faq';
export const SYSTEM_SETTINGS = '/system-settings';

// external users
export const EDIT_STAKEHOLDER = '/edit-stakeholder/:token';
// invoices
export const INVOICES = '/invoices';
export const PAYMENT = '/payment/:invoiceCode';

// unknown
export const COMPLETED = '/completed';
export const TEMPLATE = '/template';
export const SURVEY = '/survey';
export const CONFIRMED = '/confirmed';
export const RECIPIENT = '/manage-recipients';

export const MY_TRUST = '/my-trust';
export const TRUST_FORM = '/trust-form';

export const HELP = '/help';

// cards
export const CARDS = '/cards';
export const MANAGE_CARDS = '/manage-cards';
export const ASSIGN_CARD = '/assign-card';

export const TEMPLATE_FIELDS = '/template-fields';
export const CREATE_TEMPLATE_INPUT = '/create-template-input';
export const EDIT_TEMPLATE_INPUT = '/edit-template-input';
