/** @format */

let initialState = {
    pageTitle: 'SpeedCard',
};

export default function reducer(currentState = initialState, action) {
    switch (action.type) {
        case 'SET_PAGE_TITLE':
            return {
                pageTitle: action.pageTitle,
            };
        default:
            return currentState;
    }
}
