/** @format */

import React, { useContext, Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import * as Pages from 'src/app/pages';
import { PublicRoutes, ProCorpRoutes, UserRoutes } from 'src/app/routes';
import { UserLayout, PublicLayout } from 'src/app/layout';

import { AuthContext } from 'src/app/context';
import { Spinner } from 'src/app/components';

const { REACT_APP_SPEEDCARD_ORGANIZATION_CODE } = process.env;

export const Routes = () => {
    const { isAuthenticated, getSessionStorage } = useContext(AuthContext);
    const { corporateId } = getSessionStorage() || {};

    const getRedirectTo = () => {
        if (!isAuthenticated || !corporateId) return '/public';
        if (corporateId === REACT_APP_SPEEDCARD_ORGANIZATION_CODE) return '/procorp';
        return '/user';
    };

    return (
        <Switch>
            <Redirect exact from="/" to={getRedirectTo()} />
            <Route path="/user" component={UserRoutes} />
            <Route path="/admin" component={ProCorpRoutes} />
            <Route path="/public" component={PublicRoutes} />
            <Route path="*" component={Pages.ErrorsPage} />
        </Switch>
    );
};

export const PrivateRoute = props => {
    const { children, component: Component, path, ...rest } = props;
    const { isAuthenticated, getSessionStorage } = useContext(AuthContext);
    const { corporateId, roles } = getSessionStorage() || {};

    const isValid = () => {
        const [origin] = path.split('/').filter(x => x);

        // NOTE: order matters
        if (!isAuthenticated) return false;
        if (!corporateId && roles !== 'superadmin') return false;
        if (origin === 'procorp' && corporateId !== REACT_APP_SPEEDCARD_ORGANIZATION_CODE) return false;
        if (origin === 'user' && corporateId === REACT_APP_SPEEDCARD_ORGANIZATION_CODE) return false;
        return true;
    };

    const render = props => {
        const { location } = props;
        const [origin] = location.pathname.split('/').filter(x => x);

        if (isValid() && Component) {
            return (
                <UserLayout origin={origin} {...props}>
                    <Suspense fallback={<Spinner />}>
                        <Component origin={origin} {...props} />
                    </Suspense>
                </UserLayout>
            );
        }

        return (
            <Redirect
                to={{
                    pathname: '*',
                    state: { from: location },
                }}
            />
        );
    };

    return <Route {...rest} render={render} />;
};

export const PublicRoute = props => {
    const { children, component: Component, ...rest } = props;

    const render = props => {
        const { location } = props;
        const [origin] = location.pathname.split('/').filter(x => x);

        if (Component) {
            return (
                <PublicLayout origin={origin} {...props}>
                    <Suspense fallback={<Spinner />}>
                        <Component origin={origin} {...props} />
                    </Suspense>
                </PublicLayout>
            );
        }

        return (
            <Redirect
                to={{
                    pathname: '*',
                    state: { from: location },
                }}
            />
        );
    };

    return <Route {...rest} render={render} />;
};
