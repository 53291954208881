/** @format */

import React from "react";
import clsx from "clsx";

export const Tag = props => {
  const { label } = props;
  const newLabel = label === "PENDING FOR PAYMENT" ? "PAYMENT" : label;

  const getColor = () => {
    switch (newLabel && newLabel.toLowerCase()) {
      case "active":
      case "added":
      case "submitted":
      case "verified":
        return "label-light-success";
      case "draft":
      case "payment":
      case "modified":
        return "label-light-info";
      case "returned":
      case "closed":
      case "deleted":
        return "label-light-danger";
      case "closing":
        return "label-light-warning";
      default:
        return "label-light-dark";
    }
  };

  const color = getColor();

  return (
    <label className={clsx("label label-inline", color)}>{newLabel}</label>
  );
};
