/** @format */

import { useState, useEffect } from 'react';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import { getInitLayoutConfig } from 'src/_metronic/layout/_core/LayoutConfig';
import Moment from 'moment';
import { capitalize } from 'lodash';

import { DATE_FORMAT } from 'src/app/helpers/misc';

export const useQueryProxy = (params, ...rest) => {
    if (!params) return;

    const response = ApolloUseQuery(params, ...rest);
    if (!response.data) {
        return {
            ...response,
            data: [],
        };
    }

    const { data } = response;
    const queryName = Object.keys(data)[0];
    return {
        ...response,
        data: data[queryName],
    };
};

export const isEmpty = obj => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
};

export const getURLParam = name => {
    return new URLSearchParams(window.location.search).get(name);
};

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

export const getBreakpointWidth = breakpoint => {
    const { js } = getInitLayoutConfig();
    const { breakpoints } = js || {};
    return breakpoints[breakpoint];
};

export const FORMAT_DATE = (date, format) => {
    return Moment(date)
        .local()
        .format(format || DATE_FORMAT);
};

export const hyperlinkDownload = (url, filename) => {
    let hyperlink = document.createElement('a');
    hyperlink.href = url;
    hyperlink.setAttribute('download', filename || '');
    hyperlink.setAttribute('target', '_blank');
    hyperlink.onclick = e => e.stopPropagation();
    hyperlink.click();
};

export const hyperlinkDownloadWithFileNameAndContentType = (url, filename, contentType) => {
    let hyperlink = document.createElement('a');
    hyperlink.href = url + '&filename=' + filename + '&contenttype=' + contentType;
    hyperlink.setAttribute('download', filename || '');
    hyperlink.setAttribute('target', '_blank');
    hyperlink.onclick = e => e.stopPropagation();
    hyperlink.click();
};

export const NUMBER_FORMAT = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
});
export const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const CamelCase = string => {
    try {
        if (!string) return '';

        let result = string.replace(/\w+/g, function(match) {
            return capitalize(match);
        });

        const lower = [
            'a',
            'an',
            'and',
            'but',
            'or',
            'for',
            'at',
            'by',
            'to',
            'above',
            'after',
            'along',
            'before',
            'below',
            'between',
            'beyond',
            'during',
            'in',
            'on',
            'through',
            'toward',
            'within',
        ];

        lower.forEach(x => {
            result = result.replace(new RegExp(`\\s${x}\\s`, 'gi'), function(match) {
                return match.toLowerCase();
            });
        });

        const lowerExact = ['\\We.g.\\W'];

        lowerExact.forEach(x => {
            result = result.replace(new RegExp(x, 'gi'), function(match) {
                return match.toLowerCase();
            });
        });

        return result;
    } catch (e) {
        return '';
    }
};

export const formatAddress = (address, strOutput) => {
    try {
        if (!address) return ['', '', ''];

        let addressObj, unitNumber, blockNumber, roadName, building, postal;

        if (typeof address === 'object') addressObj = address;
        else addressObj = JSON.parse(address);

        unitNumber = addressObj.unitNumber;
        blockNumber = addressObj.blockNumber;
        roadName = addressObj.roadName;
        building = addressObj.building;
        postal = addressObj.postal;

        let output = [];
        let line1 = '',
            line2 = '',
            line3 = '';
        if (blockNumber) line1 += `${blockNumber} `;
        if (roadName) line1 += `${roadName} `;
        if (unitNumber) line1 += `${unitNumber}`;
        if (blockNumber || roadName || unitNumber) output.push(line1.trim());

        if (building) {
            line2 += `${building}`;
            output.push(line2);
        }

        if (postal) {
            line3 += `SINGAPORE ${postal}`;
            output.push(line3);
        }

        const diff = 3 - output.length;
        for (let i = 0; i < diff; i++) {
            output.push('');
        }

        if (strOutput) output = output.join('\n').trim();

        return output;
    } catch (e) {
        return [address, '', ''];
    }
};

export const parseJson = str => {
    try {
        const obj = JSON.parse(str);
        return obj;
    } catch (e) {
        return;
    }
};

export const isIntersectionExists = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;

    const intersections = arr1.filter(x => arr2.includes(x));
    return intersections.length > 0;
};

export const getErrorMsg = (e, defaultMsg) => {
    try {
        const { response, message } = e || {};
        const { data } = response || {};

        if (typeof data === 'string') return data;
        if (typeof message === 'string') return e.message;
        return defaultMsg;
    } catch (e) {
        return defaultMsg;
    }
};

export const convertToPlainText = rtf => {
    return rtf.replace(/\u0001/g, '\n');
};

export const capitalizeFirstString = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
