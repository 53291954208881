/** @format */

import React from 'react';

export const Hero = ({ children }) => {
    return (
        <div className="d-flex flex-row-fluid bgi-size-cover bgi-position-center mb-0 procorp-hero">
            <div className="container">
                <div className="d-flex align-items-stretch text-center flex-column py-5">
                    <h1 className="text-dark font-weight-bolder mb-3">Welcome to SpeedCard</h1>
                    <div className="mb-4">
                        <h6 className="dashboard-subheading">
                            Your Trusted Partner for Small Businesses and Entrepreneurs
                        </h6>
                    </div>

                    {/* <UserMainWidget /> */}
                </div>
            </div>
        </div>
    );
};
