/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import 'src/_metronic/_assets/sass/pages/login/login.scss';
import { Button } from 'src/app/components';

const PublicHome = props => {
    return (
        <div
            className="d-flex flex-column w-100 h-100 bgi-size-cover bgi-no-repeat"
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-8.jpg')})`,
            }}>
            <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-between px-6 pt-5">
                <div className="textalign-center flex-column-auto text-white w-md-50">
                    <h1 className="font-weight-boldest">PASSION LEADS</h1>
                    <h1 className="font-weight-lighter">PROFESSION</h1>
                    {/* <div className="mb-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </div> */}
                    <div className="mb-1">
                        <Link to="/public/registration">
                            <Button
                                label="Sign up"
                                variant="transparent"
                                className="floating-button bg-tertiary text-white font-weight-bold font-size-h6 px-8"
                            />
                        </Link>
                        &nbsp;&nbsp;
                        <Link to="/public/login">
                            <Button
                                label="Sign in"
                                className="floating-button text-white font-weight-bold font-size-h6 px-8"
                                // variant="transparent"
                            />
                        </Link>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img
                            alt="About"
                            src={toAbsoluteUrl('/media/svg/illustrations/login-visual-1.svg')}
                            style={{ maxWidth: '80%' }}
                        />
                    </div>
                </div>
                {/* <div className="d-flex text-white pb-5">
                    <Link to="/public/privacy" className="text-white">
                        Privacy
                    </Link>
                    <span className="mx-2">|</span>
                    <Link to="/public/terms" className="text-white">
                        Terms
                    </Link>
                </div> */}
            </div>
        </div>
    );
};

export default PublicHome;
