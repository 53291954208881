/** @format */

import { fetcher } from "./fetcher";

export const generateTemplate = async payload => {
  try {
    const response = await fetcher().post("/form/generate-template", payload);
    const { data } = response || {};
    const { fileId } = data || {};
    if (!fileId) throw "Failed to get template file";

    return fileId;
  } catch (e) {
    throw e;
  }
};

export const getDocumentTemplate = async documentTemplateCode => {
  try {
    const response = await fetcher().get(
      `/template/document-template?documentTemplateCode=${documentTemplateCode}`
    );
    const { data } = response || {};
    const { documentTemplateFile } = data || {};
    if (!documentTemplateFile) throw "Failed to get document template";

    return documentTemplateFile;
  } catch (e) {
    throw e;
  }
};
