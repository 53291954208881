/** @format */

import React from 'react';
import { Button as DefaultButton } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from 'src/_metronic/_helpers';

export const TransparentButton = props => {
    const { children, onClick } = props;
    return (
        <DefaultButton
            onClick={onClick}
            className="border-0 text-hover-primary bg-transparent bg-hover-light-primary p-3">
            {children}
        </DefaultButton>
    );
};

export const IconButton = props => {
    const { title, type = 'primary', onClick, iconUrl, className = '', loading, png, noBg, icon, disabled } = props;

    const ImageToRender = () => {
        if (icon) return icon;
        if (png) return <img src={toAbsoluteUrl(iconUrl)} style={{ width: 40, height: 40 }} />;
        return <SVG src={toAbsoluteUrl(iconUrl)} />;
    };

    if (disabled) {
        return (
            <a
                title={title}
                className={`${className} disabled btn btn-icon btn-light btn-hover-${type} btn-md`}
                onClick={onClick}>
                <span
                    className={`svg-icon svg-icon-${noBg ? 'xl' : 'md'} svg-icon-${type} d-flex justify-content-center`}
                    style={{ pointerEvents: 'none' }}>
                    {loading ? <span className="mr-6 spinner"></span> : <ImageToRender />}
                </span>
            </a>
        );
    } else {
        return (
            <a
                title={title}
                className={
                    loading
                        ? 'disabled-link btn btn-icon btn-light btn-md'
                        : !png && !noBg
                        ? `${className} btn btn-icon btn-light btn-hover-${type} btn-md`
                        : ''
                }
                onClick={onClick}>
                <span
                    className={`svg-icon svg-icon-${noBg ? 'xl' : 'md'} svg-icon-${type} d-flex justify-content-center`}
                    style={{ pointerEvents: 'none' }}>
                    {loading ? <span className="mr-6 spinner"></span> : <ImageToRender />}
                </span>
            </a>
        );
    }
};

export const Button = props => {
    const { icon, label, type, variant, loading, disabled, display = true, onClick, className = '' } = props;
    return display ? (
        <DefaultButton
            type={type || 'button'}
            variant={variant}
            onClick={onClick}
            disabled={loading || disabled}
            className={className}>
            {icon ? (
                <span className="svg-icon svg-icon-light svg-icon-md">
                    <SVG className="mr-4" src={icon} />
                    <span>{label}</span>
                </span>
            ) : (
                <span>{label}</span>
            )}
            {loading && <span className="ml-3 mr-4 spinner spinner-white"></span>}
        </DefaultButton>
    ) : (
        ''
    );
};

export const SmallButton = props => {
    const { title, type = 'primary', onClick, iconUrl, className = '', loading, png, noBg, icon, disabled } = props;

    const ImageToRender = () => {
        if (icon) return icon;
        if (png) return <img src={toAbsoluteUrl(iconUrl)} style={{ width: 3, height: 3 }} />;
        return <SVG src={toAbsoluteUrl(iconUrl)} />;
    };

    if (disabled) {
        return (
            <a
                title={title}
                className={`${className} disabled btn btn-icon btn-light btn-hover-${type} btn-md`}
                onClick={onClick}>
                <span
                    className={`svg-icon svg-icon-${noBg ? 'sm' : 'md'} svg-icon-${type} d-flex justify-content-center`}
                    style={{ pointerEvents: 'none' }}>
                    {loading ? <span className="mr-6 spinner"></span> : <ImageToRender />}
                </span>
            </a>
        );
    } else {
        return (
            <a
                title={title}
                className={
                    loading
                        ? 'disabled-link btn btn-icon btn-light btn-md'
                        : !png && !noBg
                        ? `${className} btn btn-icon btn-light btn-hover-${type} btn-md`
                        : ''
                }
                onClick={onClick}>
                <span
                    className={`svg-icon svg-icon-${noBg ? 'sm' : 'md'} svg-icon-${type} d-flex justify-content-center`}
                    style={{ pointerEvents: 'none', color: 'grey' }}>
                    {loading ? <span className="mr-6 spinner"></span> : <ImageToRender />}
                </span>
            </a>
        );
    }
};
