/** @format */
import '../../css/myStyle.css';

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { PageTitle } from 'src/app/layout';
import {
    Boundary,
    IconButton,
    Button,
    Modal,
    Tag,
    Input,
    InputPhoneNumber,
    Uploader,
    TableWithOnChangeSearch,
    Select,
    SmallButton,
    TelInput,
    InputTextArea,
    ModalWithCloseButton,
} from 'src/app/components';
import { sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { capitalizeFirstString, useQueryProxy } from 'src/app/helpers/util';
import * as Paths from 'src/app/routes/paths';
import {
    GET_STAFF_PROFILES,
    ENABLE_DISABLE_STAFF,
    DELETE_STAFF_PERMANENTLY,
    CORPORATE_ASSIGN_CARD,
    GET_TEMPLATE_FIELDS_BY_CORPORATE_ID,
    UPDATE_CORPORATE_STAFF,
    SEND_CORPORATE_INVITATION,
    CORPORATE_RECALL_CARD,
} from 'src/app/api/graphql';
import { AuthContext } from 'src/app/context';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import IframeComm from 'react-iframe-comm';
import { STATUS } from '../helpers/misc';
import { STATUS_STAFF } from 'src/app/helpers/misc';
import { uploadFile } from '../api';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

var Isemail = require('isemail');

const { REACT_APP_SPEEDCARD_URL } = process.env;

const Staff = props => {
    const { origin, history } = props;
    const { getSessionStorage, getUserInfo, getCorporate, updateUserInfo } = useContext(AuthContext);
    const { corporateId, corporateName, _id } = getSessionStorage();
    const userInfo = getUserInfo();
    const { maxStaff, website, address, icon } = getCorporate();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedId, setSelectedId] = useState('');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isPreviewCardOpen, setIsPreviewCardOpen] = useState(false);
    const [isAssignCardOpen, setIsAssignCardOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [staffProfile, setStaffProfile] = useState({});
    const [rowSelect, setRowSelect] = useState([]);
    const [assignData, setAssignData] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [formList, setFormList] = useState([]);
    const [fieldsId, setFieldsId] = useState([]);
    const [isRecallOpen, setIsRecallOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [action, setAction] = useState(null);
    const [isEnableDisableOpen, setIsEnableDisableOpen] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [disableAssignBtn, setDisableAssignBtn] = useState(true);
    const [isCopied, setIsCopied] = useState('');
    const [previewTemplateHeight, setPreviewTemplateHeight] = useState('560px');

    const { data: staffProfiles, loading, refetch: refetchStaffProfiles } = useQueryProxy(GET_STAFF_PROFILES, {
        variables: { _corporateId: corporateId },
    });

    const { data: templateFields, loadingTemplateFields } = useQueryProxy(GET_TEMPLATE_FIELDS_BY_CORPORATE_ID, {
        variables: { corporateId: corporateId },
    });

    useEffect(() => {
        if (!loading) {
            if (staffProfiles !== undefined && staffProfiles !== null && staffProfiles.length > 0) {
                var latestList = [];
                staffProfiles.map(staff => {
                    if (staff !== null) {
                        staff.type = staff.role;
                        latestList.push(staff);
                    }
                });

                setTableData(latestList.sort((a, b) => new Date(b.createdDt) - new Date(a.createdDt)));
                setStaffList(latestList);
            } else {
                setTableData('');
            }
        }
    }, [staffProfiles]);

    useEffect(() => {
        if (!loadingTemplateFields) {
            if (templateFields !== undefined && templateFields !== null) {
                if (templateFields.fields !== undefined && templateFields.fields !== null) {
                    let fields = JSON.parse(templateFields.fields);
                    let corpFields = [];
                    let staffFields = [];

                    staffFields.push({
                        dataType: 'select',
                        main: true,
                        name: 'type',
                        value: '',
                    });

                    fields = fields.map(field => {
                        if (field.name === 'company') {
                            field.value = corporateName;
                            corpFields.push(field);
                        } else if (field.name === 'website') {
                            field.value = website ? website : '';
                            corpFields.push(field);
                        } else if (field.name === 'address') {
                            field.value = address ? address : '';
                            corpFields.push(field);
                        } else if (field.name === 'logo') {
                            field.value = icon ? icon : '';
                            corpFields.push(field);
                        } else {
                            staffFields.push(field);
                        }
                    });

                    setFormList({ staff: staffFields, corporate: corpFields });
                    setFieldsId(templateFields._id);
                }
            } else {
                setFormList([]);
            }
        }
    }, [templateFields]);

    const [enableDisableStaff] = useMutation(ENABLE_DISABLE_STAFF, {
        onError: e => setError(e.message),
        onCompleted: () => {
            if (action === 1) {
                setSuccess('Successfully disable staff');
            }
            if (action === 2) {
                setSuccess('Successfully enable staff');
            }
        },
    });

    const [deleteStaff] = useMutation(DELETE_STAFF_PERMANENTLY, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully deleted staff'),
    });

    const [updateCorporateStaff] = useMutation(UPDATE_CORPORATE_STAFF, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully updated staff'),
    });

    const [sendInvitation] = useMutation(SEND_CORPORATE_INVITATION, {
        onError: e => setError(e.message),
        onCompleted: res => {
            setSuccess('Successfully send staff invitation.');
        },
    });

    const [assignCard] = useMutation(CORPORATE_ASSIGN_CARD, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully assign card to staff'),
    });

    const [recallCard] = useMutation(CORPORATE_RECALL_CARD, {
        onError: e => setError(e.message),
        onCompleted: () => setSuccess('Successfully recalled card'),
    });

    const getContactAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}admin-contacts.php?userId=${selectedId}&&isIframe=true&&corporateId=${corporateId}&&corporateName=${corporateName}`,
        width: '100%',
        height: '400px',
    };

    const previewAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}preview-template.php?userId=${selectedId}&&isIframe=true&&corporateId=${corporateId}&&corporateName=${corporateName}`,
        width: '100%',
        height: previewTemplateHeight,
        scrolling: 'no',
        overflow: 'auto',
    };

    const chooseTemplatesAttributes = {
        src: `${REACT_APP_SPEEDCARD_URL}choose-corporate-template.php?corporateId=${corporateId}&&isIframe=tru&&corporateName=${corporateName}`,
        width: '100%',
        height: '460px',
        scrolling: 'no',
    };

    const postData = {
        ...userInfo,
        corporateId: corporateId,
        corporateName: corporateName,
    };

    const postMessageData = JSON.stringify(postData);

    const onReceiveMessage = e => {
        const data = JSON.parse(e.data);

        if (data !== undefined && data !== null) {
            const message = data.message;

            if (message !== undefined && message !== null) {
                if (message.docHeight !== undefined && message.docHeight !== null && message.docHeight !== '') {
                    const docHeight = message.docHeight;
                    console.log('docHeight: ' + docHeight);
                    setPreviewTemplateHeight(docHeight + 'px');
                }

                const selected = message.selected;
                if (selected !== undefined && selected !== null) {
                    setSelectedTemplate(selected);
                    setBtnDisabled(false);
                } else {
                    setSelectedTemplate('');
                    setBtnDisabled(true);
                }
            }
        }
    };

    // iframe has loaded
    const onReady = () => {};

    const openEnableDisableModal = (cell, action) => {
        if (action === 1) {
        }
        setAction(action);
        setSelectedId(cell);
        setIsEnableDisableOpen(true);
    };

    const openDeleteModal = cell => {
        setSelectedId(cell);
        setIsDeleteOpen(true);
    };

    const openRecallModal = cell => {
        if (rowSelect.length > 0) {
            setAssignData(JSON.stringify(rowSelect[0]));
            setIsRecallOpen(true);
        }
    };

    const openEditModal = (cell, row) => {
        setStaffProfile(row);
        setSelectedId(cell);
        setIsEditOpen(true);
    };

    const openPreviewCardModal = (cell, row) => {
        setStaffProfile(row);
        setSelectedId(cell);
        setIsPreviewCardOpen(true);
    };

    const openContactModal = async cell => {
        setSelectedId(cell);
        setIsContactOpen(true);
    };

    const openAssignCardModal = () => {
        if (rowSelect.length > 0) {
            setAssignData(JSON.stringify(rowSelect[0]));
            setIsAssignCardOpen(true);
        }
    };

    const onEnableDisable = () => {
        if (selectedId !== undefined && selectedId !== null && selectedId !== '') {
            const data = {
                id: selectedId,
                action: action,
                corporateId: corporateId,
            };
            enableDisableStaff({
                variables: { input: JSON.stringify(data) },
                onCompleted: e => {
                    setError('');
                    refetchStaffProfiles();
                    setSelectedId('');
                },
                onError: e => {
                    setSuccess('');
                    setError(e.message);
                },
            });
        }
        // TODO: this is just a workaround...
        setTimeout(() => {
            props.history.push('staff');
        }, 2000);
    };

    const onDeleteStaff = () => {
        if (selectedId !== undefined && selectedId !== null && selectedId !== '') {
            const input = {
                id: selectedId,
                corporateId: corporateId,
                category: 'Corporate',
            };
            deleteStaff({
                variables: { input: JSON.stringify(input) },
                onCompleted: () => {
                    setError('');
                    refetchStaffProfiles();
                    setSelectedId('');
                },
                onError: e => {
                    setSuccess('');
                    setError(e.message);
                },
            });
        }
    };

    const onRecallStaff = async () => {
        if (rowSelect.length > 0) {
            let allCard = [];

            rowSelect.forEach(staff => {
                allCard.push(staff._id);
            });

            await recallCard({
                variables: {
                    staff: JSON.stringify(allCard),
                },
                onCompleted: () => {
                    refetchStaffProfiles();
                },
            });
        }
    };

    const onSearch = keyword => {
        if (keyword !== undefined && keyword !== null && keyword !== '') {
            if (staffList !== undefined && staffList !== null && staffList.length > 0) {
                const temp = staffList.filter(staff => staff.name !== null);

                const newData = temp.filter(
                    data =>
                        data.name.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.status.toLowerCase().includes(keyword.toLowerCase()) ||
                        data.username.toLowerCase().includes(keyword.toLowerCase()) ||
                        data._id.toLowerCase().includes(keyword.toLowerCase())
                );

                setTableData(newData);
            }
        } else {
            if (staffList !== undefined && staffList !== null && staffList.length > 0) {
                setTableData(staffList);
            }
        }
    };

    const moveToStaffForm = () => {
        history.push(`/${origin}${Paths.STAFF_FORM}`);
    };

    const processAssignCard = async () => {
        let allStaff = [];
        const templateId = selectedTemplate;

        rowSelect.forEach(staff => {
            allStaff.push(staff._id);
        });

        const asgn = await assignCard({
            variables: {
                staff: JSON.stringify(allStaff),
                templateId: templateId,
                corporateName: corporateName,
                corporateId: corporateId,
            },
        });

        if (asgn) {
            setIsAssignCardOpen(false);
            setSelectedTemplate('');
            setBtnDisabled(true);
            setTimeout(() => {
                refetchStaffProfiles();
            }, 1500);
        }
    };

    const processInvitation = async cell => {
        sendInvitation({
            variables: { _id: cell },
        });
    };

    function setButton(rowSelect) {
        let isEnable = false;
        let count = 0;
        rowSelect.map(element => {
            count++;
            if (element.cardId != null) {
                isEnable = true;
                if (count == rowSelect.length) {
                    return isEnable;
                }
            }
            if (count == rowSelect.length) {
                return isEnable;
            }
        }, isEnable);
        return isEnable;
    }

    return (
        <>
            <PageTitle title="Staff" />
            <Boundary
                modal
                onClick={() => {
                    history.push(`/${origin}${Paths.STAFF}`);
                }}
                loading={loading}
                error={error}
                success={success}
                onClose={() => {
                    setSuccess('');
                    setError('');
                }}>
                <TableWithOnChangeSearch
                    // card
                    keyField="_id"
                    columns={columns(
                        openEnableDisableModal,
                        openEditModal,
                        openPreviewCardModal,
                        openContactModal,
                        processInvitation,
                        openDeleteModal,
                        _id,
                        setIsCopied,
                        isCopied
                    )}
                    data={tableData}
                    defaultSizePerPage={10}
                    noResultMsg="No staff found."
                    onSearch={onSearch}
                    rowSelect={rowSelect}
                    setRowSelect={setRowSelect}
                    showCheckbox={true}
                    setDisableAssignBtn={setDisableAssignBtn}
                    searchExtension={
                        <>
                            <Button
                                label="Add Staff"
                                className=" floating-button font-weight-bold px-9 mr-1"
                                onClick={() => {
                                    const count = staffProfiles.filter(x => x.status !== STATUS_STAFF.inactive).length;
                                    if (count === maxStaff) {
                                        setSuccess('');
                                        setError('Max staff limit reached');
                                    } else {
                                        moveToStaffForm();
                                    }
                                }}
                                alignItems="center"
                                alignSelf="center"
                            />

                            {/* {rowSelect.length > 0
                                ? setButton(rowSelect)
                                    ? (disableRecallBtn = false)
                                    : (disableRecallBtn = true)
                                : (disableRecallBtn = true)} */}

                            <>
                                <Button
                                    label="Assign Card"
                                    className=" floating-button font-weight-bold px-9 ml-1"
                                    onClick={() => openAssignCardModal()}
                                    alignItems="center"
                                    alignSelf="center"
                                    disabled={disableAssignBtn}
                                />
                                <Button
                                    label="Recall Card"
                                    className=" floating-button font-weight-bold px-9 ml-2"
                                    onClick={() => openRecallModal()}
                                    alignItems="center"
                                    alignSelf="center"
                                    disabled={rowSelect.length > 0 ? false : true}
                                />
                            </>
                        </>
                    }
                />
            </Boundary>
            <DeleteStaffModal onDelete={onDeleteStaff} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
            <EnableDisableModal
                onEnableDisable={onEnableDisable}
                isOpen={isEnableDisableOpen}
                setIsOpen={setIsEnableDisableOpen}
                action={action}
            />
            <RecallStaffModal onRecall={onRecallStaff} isOpen={isRecallOpen} setIsOpen={setIsRecallOpen} />
            <ContactListModal
                isOpen={isContactOpen}
                setIsOpen={setIsContactOpen}
                postMessageData={postMessageData}
                getContactAttributes={getContactAttributes}
                onReady={onReady}
                onReceiveMessage={onReceiveMessage}
            />
            <PreviewCardStaffModal
                isOpen={isPreviewCardOpen}
                setIsOpen={setIsPreviewCardOpen}
                postMessageData={postMessageData}
                getAttributes={previewAttributes}
                onReady={onReady}
                onReceiveMessage={onReceiveMessage}
            />
            <EditStaffModal
                isOpen={isEditOpen}
                setIsOpen={setIsEditOpen}
                setStaffProfile={setStaffProfile}
                selectedId={selectedId}
                updateCorporateStaff={updateCorporateStaff}
                staffProfiles={staffProfiles}
                staffProfile={staffProfile}
                setSuccess={setSuccess}
                setError={setError}
                setSelectedId={setSelectedId}
                refetchStaffProfiles={refetchStaffProfiles}
                formList={formList}
                setFormList={setFormList}
                fieldsId={fieldsId}
                corporateId={corporateId}
                _id={_id}
                updateUserInfo={updateUserInfo}
            />
            <AssignCardModal
                isOpen={isAssignCardOpen}
                setIsOpen={setIsAssignCardOpen}
                postMessageData={assignData}
                getAttributes={chooseTemplatesAttributes}
                onReady={onReady}
                onReceiveMessage={onReceiveMessage}
                handleSubmit={processAssignCard}
                btnDisabled={btnDisabled}
            />
        </>
    );
};

const DeleteStaffModal = props => {
    const { onDelete, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Delete Staff"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: onDelete,
            }}>
            Are you sure to permanently delete this staff?
        </Modal>
    );
};

const EnableDisableModal = props => {
    const { onEnableDisable, isOpen, setIsOpen, action } = props;
    return (
        <Modal
            show={isOpen}
            title={action === 1 ? 'Disable Staff' : 'Enable Staff'}
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: onEnableDisable,
            }}>
            Are you sure?
        </Modal>
    );
};

const PreviewCardStaffModal = props => {
    const { isOpen, setIsOpen, getAttributes, postMessageData, onReady, onReceiveMessage } = props;

    return (
        <ModalWithCloseButton
            show={isOpen}
            title="Preview Card"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: false,
                label: 'Ok',
            }}
            okButton={{
                show: false,
                label: 'Close',
                // onClick: handleSubmit,
            }}
            noPadding>
            <div style={{ overflow: 'auto', height: '600px', padding: '1.25rem' }}>
                <IframeComm
                    attributes={getAttributes}
                    postMessageData={postMessageData}
                    handleReady={onReady}
                    handleReceiveMessage={onReceiveMessage}
                />
            </div>
        </ModalWithCloseButton>
    );
};

const RecallStaffModal = props => {
    const { onRecall, isOpen, setIsOpen } = props;
    return (
        <Modal
            show={isOpen}
            title="Recall Card"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'No',
            }}
            okButton={{
                show: true,
                label: 'Yes',
                onClick: onRecall,
            }}>
            Do you want to recall card?
        </Modal>
    );
};

const ContactListModal = props => {
    const { isOpen, setIsOpen, getContactAttributes, postMessageData, onReady, onReceiveMessage } = props;

    return (
        <Modal
            closeButton={true}
            backdrop="static"
            keyboard={false}
            show={isOpen}
            header={true}
            title="Contact"
            onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: false,
                label: 'Move',
            }}
            okButton={{
                show: false,
                label: 'Copy',
            }}>
            <IframeComm
                attributes={getContactAttributes}
                postMessageData={postMessageData}
                handleReady={onReady}
                handleReceiveMessage={onReceiveMessage}
            />
        </Modal>
    );
};

const EditStaffModal = props => {
    const {
        isOpen,
        setIsOpen,
        selectedId,
        staffProfiles,
        staffProfile,
        updateCorporateStaff,
        setError,
        setSuccess,
        setSelectedId,
        refetchStaffProfiles,
        staff = {},
        formList,
        setFormList,
        fieldsId,
        setStaffProfile,
        corporateId,
        _id,
        updateUserInfo,
    } = props;

    let individualFormList = formList.staff;
    const corporateFormList = formList.corporate;

    useEffect(() => {
        if (isOpen) {
            if (staffProfile.userFields !== undefined && staffProfile.userFields !== null) {
                const fields = JSON.parse(staffProfile.userFields);

                let newTempValue = formList.staff;
                fields.map(f => {
                    const name = f.name;
                    let value = f.value;

                    if (newTempValue && newTempValue.length > 0) {
                        newTempValue = newTempValue.map(v => {
                            if (v.name === name) {
                                if (value === undefined || value === null || value === '') {
                                    value = v.value;
                                }

                                if (value === undefined || value === null || value === '') {
                                    if (
                                        staffProfile[v.name] !== undefined &&
                                        staffProfile[v.name] !== null &&
                                        staffProfile[v.name] !== ''
                                    ) {
                                        let val = staffProfile[v.name];
                                        value = val;
                                    }
                                }

                                return {
                                    ...v,
                                    name: name,
                                    value: value,
                                };
                            } else {
                                if (v.name === 'type') {
                                    return {
                                        ...v,
                                        name: v.name,
                                        value: staffProfile['role'] || staffProfile['type'],
                                    };
                                }

                                return {
                                    ...v,
                                    name: v.name,
                                    value: v.value,
                                };
                            }
                        });
                    }
                });

                setFormList({ staff: newTempValue, corporate: corporateFormList });
                individualFormList = newTempValue;
            } else {
                let newTempValue = formList.staff;

                if (newTempValue && newTempValue.length > 0) {
                    newTempValue = newTempValue.map(v => {
                        let value = staffProfile[v.name];

                        if (value === undefined || value === null || value === '') {
                            if (
                                staffProfile[v.name] !== undefined &&
                                staffProfile[v.name] !== null &&
                                staffProfile[v.name] !== ''
                            ) {
                                let val = staffProfile[v.name];
                                value = val;
                            }
                        }

                        return {
                            ...v,
                            name: v.name,
                            value: value,
                        };
                    });
                }

                setFormList({ staff: newTempValue, corporate: corporateFormList });
                individualFormList = newTempValue;
            }
        }
    }, [staffProfile]);

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, isValid } = useFormik({
        initialValues: selectedId ? staffProfile : staff,
        validationSchema: staffProfileInputSchema(),
        validateOnMount: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async values => {
            individualFormList = await Promise.all(
                individualFormList.map(async (z, i) => {
                    const { name, value, dataType } = z;

                    if (dataType === 'image' && value instanceof File) {
                        const fileId = await uploadFile(value);
                        // individualFormList[i].value = fileId;
                        return {
                            ...z,
                            value: fileId,
                        };
                    } else {
                        return {
                            ...z,
                        };
                    }
                })
            );

            const templateFields = {
                templateFieldsId: fieldsId,
                fields: [...individualFormList, ...corporateFormList],
            };

            const input = {
                _id: selectedId,
                _corporateId: corporateId,
                name: values.name,
                email: values.email,
                phone: values.phone,
                templateFields: JSON.stringify(templateFields),
            };

            if (selectedId !== undefined || selectedId !== null || selectedId !== '') {
                staffProfiles.forEach(staff => {
                    if (selectedId === staff._id) {
                        updateCorporateStaff({
                            variables: { input: input },
                            onCompleted: async data => {
                                setError('');
                                refetchStaffProfiles();
                                setSuccess('Successfully updated staff');
                                setSelectedId('');
                                setIsOpen(false);
                                if (_id === selectedId) {
                                    const updated = data.updateCorporateStaff;
                                    await updateUserInfo(updated);
                                }
                            },
                            onError: e => {
                                setSuccess('');
                                setError('Unable to update staff');
                            },
                        });
                    }
                });
            }
        },
    });

    const onChange = e => {
        if (e.target !== undefined) {
            const name = e.target.name;
            var value = e.target.value;

            if (name === 'type') {
                value = parseInt(e.target.value);
            }

            let newTempValue = individualFormList;

            if (newTempValue.length > 0) {
                let exist = false;
                newTempValue = newTempValue.map(v => {
                    if (v.name === name) {
                        v.value = value;
                        exist = true;
                        return {
                            ...v,
                            name: name,
                            value: value,
                        };
                    } else {
                        return {
                            ...v,
                            name: v.name,
                            value: v.value,
                        };
                    }
                });
            } else {
                newTempValue = [
                    {
                        name: name,
                        value: value,
                    },
                ];
            }

            setFieldValue(name, value);
            individualFormList = newTempValue;
        }
    };

    const onFileChange = (name, value) => {
        let newTempValue = individualFormList;

        if (newTempValue.length > 0) {
            let exist = false;
            newTempValue = newTempValue.map(v => {
                if (v.name === name) {
                    v.value = value;
                    exist = true;
                    return {
                        ...v,
                        name: name,
                        value: value,
                    };
                } else {
                    return {
                        ...v,
                        name: v.name,
                        value: v.value,
                    };
                }
            });
        } else {
            newTempValue = [
                {
                    name: name,
                    value: value,
                },
            ];
        }

        // if (name === 'phone' || name === 'phoneNum') {
        //     value = value.replace(/\s+/g, '');
        // }

        setFieldValue(name, value);
        individualFormList = newTempValue;
    };

    const handleKeyDown = async event => {
        // Check if backspace/delete key was pressed
        if (event.keyCode === 8 || event.keyCode === 46) {
            let value = event.target.value;
            const name = event.target.name;

            let newTempValue = individualFormList;

            if (value !== undefined && value.length <= 4) {
                value = null;
            }

            if (newTempValue.length > 0) {
                let exist = false;
                newTempValue = newTempValue.map(v => {
                    if (v.name === name) {
                        v.value = value;
                        exist = true;

                        return {
                            ...v,
                            name: name,
                            value: value,
                        };
                    } else {
                        return {
                            ...v,
                            name: v.name,
                            value: v.value,
                        };
                    }
                });
            } else {
                newTempValue = [
                    {
                        name: name,
                        value: value,
                    },
                ];
            }

            setFieldValue(name, value);
            individualFormList = await newTempValue;
        }
    };

    const onSelectFlag = (currentNumber, selectedCountryData, fullNumber, fieldName) => {
        const dialCode = selectedCountryData.dialCode;
        if (
            currentNumber === undefined ||
            currentNumber === null ||
            currentNumber === '' ||
            currentNumber.length <= 5
        ) {
            onFileChange(fieldName, '+' + dialCode);
        }
    };

    const onPhoneNumberChange = (isValid, value, country, fullNumber, extension, fieldName) => {
        const dialCode = country.dialCode;
        const tempValue = value.replace('+', '');

        if (tempValue !== dialCode) {
            onFileChange(fieldName, value);
        }
    };

    const roles = [
        { type: 'Corporate User', val: 3 },
        { type: 'Corporate Admin', val: 2 },
    ];

    return (
        <Modal
            show={isOpen}
            title="Edit Staff"
            // onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Cancel',
                onClick: () => {
                    setIsOpen(!isOpen);
                    setStaffProfile({});
                },
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                onClick: handleSubmit,
                disabled: !isValid,
            }}>
            <Form>
                <Tabs defaultActiveKey="personal" className="mb-7">
                    <Tab eventKey="personal" title="Personal">
                        {individualFormList &&
                            individualFormList.map((x, i) => {
                                let value =
                                    individualFormList.length > 0
                                        ? individualFormList.filter(f => f.name === x.name)[0].value
                                        : '';

                                const dataType = x.dataType;

                                if (dataType === 'text' || dataType === 'link' || dataType === 'email') {
                                    return (
                                        <Input
                                            mandatory={x.name === 'name' ? true : false}
                                            disabled={
                                                staffProfile['username'] !== undefined
                                                    ? Isemail.validate(staffProfile['username'])
                                                        ? x.name === 'email'
                                                            ? true
                                                            : false
                                                        : false
                                                    : false
                                            }
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={handleBlur}
                                            placeholder={x.name}
                                            key={x.name}
                                            error={x.name === 'name' ? touched.name && errors.name : null}
                                        />
                                    );
                                } else if (dataType === 'address') {
                                    return (
                                        <InputTextArea
                                            rows={1}
                                            mandatory={x.name === 'name' ? true : false}
                                            disabled={
                                                staffProfile['username'] !== undefined
                                                    ? Isemail.validate(staffProfile['username'])
                                                        ? x.name === 'email'
                                                            ? true
                                                            : false
                                                        : false
                                                    : false
                                            }
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={handleBlur}
                                            placeholder={x.name}
                                            key={x.name}
                                            error={x.name === 'name' ? touched.name && errors.name : null}
                                        />
                                    );
                                } else if (dataType === 'select') {
                                    if (staffProfile.type === 1 || staffProfile._id == _id) {
                                        return null;
                                    }

                                    return (
                                        <Select
                                            name={x.name}
                                            label="Role"
                                            placeholder="Select Role"
                                            value={value}
                                            options={roles}
                                            getOptionLabel={option => option.type}
                                            getOptionValue={option => option.val}
                                            onChange={onChange}
                                            onBlur={handleBlur}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'phone' || dataType === 'phoneNum') {
                                    let v = value;

                                    if (v === undefined || v === null || v === '') {
                                        v = '+65';
                                    }

                                    return (
                                        <TelInput
                                            disabled={
                                                staffProfile.username !== undefined
                                                    ? x.name === 'phone'
                                                        ? staffProfile.username === value
                                                            ? true
                                                            : false
                                                        : false
                                                    : false
                                            }
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={v}
                                            onSelectFlag={onSelectFlag}
                                            onPhoneNumberChange={onPhoneNumberChange}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'image') {
                                    return (
                                        <Uploader
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            description="*Only image files are accepted."
                                            key={x.name}
                                            width={450}
                                            previewImage
                                            value={value}
                                            onChange={f => onFileChange(x.name, f)}
                                            onBlur={handleBlur}
                                        />
                                    );
                                }
                            })}
                    </Tab>
                    <Tab eventKey="corporate" title="Corporate">
                        {corporateFormList &&
                            corporateFormList.map((x, i) => {
                                let value =
                                    corporateFormList.length > 0
                                        ? corporateFormList.filter(f => f.name === x.name)[0].value
                                        : '';

                                const dataType = x.dataType;

                                if (dataType === 'text' || dataType === 'link' || dataType === 'address') {
                                    return (
                                        <Input
                                            style={{ color: 'grey' }}
                                            disabled
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            placeholder={x.name}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'phone' || dataType === 'phoneNum') {
                                    return (
                                        <TelInput
                                            disabled
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            value={value}
                                            key={x.name}
                                        />
                                    );
                                } else if (dataType === 'image') {
                                    return (
                                        <Uploader
                                            disabled={true}
                                            name={x.name}
                                            label={capitalizeFirstString(x.name)}
                                            key={x.name}
                                            width={450}
                                            previewImage
                                            value={value}
                                        />
                                    );
                                }
                            })}
                    </Tab>
                </Tabs>
            </Form>
        </Modal>
    );
};

const AssignCardModal = props => {
    const {
        isOpen,
        setIsOpen,
        getAttributes,
        postMessageData,
        onReady,
        onReceiveMessage,
        handleSubmit,
        btnDisabled,
    } = props;

    return (
        <Modal
            show={isOpen}
            title="Choose Template"
            // onToggle={() => setIsOpen(!isOpen)}
            cancelButton={{
                show: true,
                label: 'Cancel',
                onClick: () => {
                    setIsOpen(!isOpen);
                },
            }}
            okButton={{
                show: true,
                label: 'Confirm',
                onClick: handleSubmit,
                disabled: btnDisabled,
            }}>
            <IframeComm
                attributes={getAttributes}
                postMessageData={postMessageData}
                handleReady={onReady}
                handleReceiveMessage={onReceiveMessage}
            />
        </Modal>
    );
};

const columns = (
    openEnableDisableModal,
    openEditModal,
    openPreviewCardModal,
    openContactModal,
    processInvitation,
    openDeleteModal,
    _id,
    setIsCopied,
    isCopied
) => [
    {
        dataField: 'name',
        text: 'Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => (
            <div className="d-flex align-items-center">
                <div className="ml-4">
                    <div className="text-dark-75 font-size-lg mb-0">{row.name}</div>
                </div>
            </div>
        ),
    },
    {
        dataField: 'username',
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{row.username}</span>
                </div>
            );
        },
    },
    {
        dataField: 'accountId',
        text: 'Account Id',
        sort: true,
        copyColumnsToClipboard: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                setIsCopied(row.accountId);
                setTimeout(() => setIsCopied(''), 3000);
            },
        },
        formatter: (cell, row) => {
            const copyToClipboard = text => {
                navigator.clipboard.writeText(text);
            };

            return (
                <div className="d-flex " onClick={() => copyToClipboard(cell)}>
                    <SmallButton
                        className="ml-2"
                        onClick={() => copyToClipboard(cell)}
                        iconUrl="/media/svg/icons/Home/copy.svg"
                        noBg></SmallButton>
                    <span className="row-valign-center ml-1">{row.accountId}</span>
                    {isCopied !== '' && isCopied === row.accountId ? (
                        <span className="text-success ml-2">Copied!</span>
                    ) : (
                        ''
                    )}
                </div>
            );
        },
    },
    {
        dataField: 'type',
        text: 'Role',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
            if (parseInt(row.type) === 1) {
                var roleName = 'SpeedCard Admin';
            } else if (parseInt(row.type) === 2) {
                var roleName = 'Corporate Admin';
            } else if (parseInt(row.type) === 3) {
                var roleName = 'Corporate User';
            }

            return (
                <div className="d-flex ">
                    <span className="row-valign-center">{roleName}</span>
                </div>
            );
        },
    },
    {
        dataField: 'status',
        text: 'Status',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: cell => (
            <div className="d-flex">
                <span className="row-valign-center">
                    <Tag label={cell} />
                </span>
            </div>
        ),
    },
    {
        dataField: '_id',
        text: 'Action',
        formatter: (cell, row) => {
            return (
                <div className="d-flex">
                    <span>
                        <IconButton
                            title="Edit"
                            type="warning"
                            onClick={() => openEditModal(cell, row)}
                            iconUrl="/media/svg/icons/Home/edit-solid.svg"
                            className="mr-3"
                        />
                    </span>
                    {row.cardId !== null ? (
                        <span>
                            <IconButton
                                title="Contact List"
                                type="info"
                                onClick={() => openContactModal(cell)}
                                iconUrl="/media/svg/icons/Home/contacts-solid.svg"
                                className="mr-3"
                            />
                        </span>
                    ) : (
                        <span>
                            <IconButton
                                disabled={true}
                                title="Contact List"
                                type="info"
                                onClick={() => openContactModal(cell)}
                                iconUrl="/media/svg/icons/Home/contacts-solid.svg"
                                className="mr-3"
                            />
                        </span>
                    )}
                    {row.cardId !== null ? (
                        <span>
                            <IconButton
                                title="Preview Card"
                                type="primary"
                                onClick={() => openPreviewCardModal(cell, row)}
                                iconUrl="/media/svg/icons/Home/business-card-icon.svg"
                                className="mr-3"
                            />
                        </span>
                    ) : (
                        <span>
                            <IconButton
                                disabled={true}
                                title="Preview Card"
                                type="primary"
                                onClick={() => openPreviewCardModal(cell, row)}
                                iconUrl="/media/svg/icons/Home/business-card-icon.svg"
                                className="mr-3"
                            />
                        </span>
                    )}
                    {cell !== _id && row.status !== STATUS.inactive && row.status !== STATUS.pending ? (
                        <span>
                            <IconButton
                                title="Disable"
                                type="dark"
                                onClick={() => openEnableDisableModal(cell, 1)}
                                iconUrl="/media/svg/icons/Home/disable-user.svg"
                                className="mr-3"
                            />
                        </span>
                    ) : cell === _id && row.status !== STATUS.inactive ? (
                        <span>
                            <IconButton
                                disabled={true}
                                title="Disable"
                                type="dark"
                                onClick={() => openEnableDisableModal(cell, 1)}
                                iconUrl="/media/svg/icons/Home/disable-user.svg"
                                className="mr-3"
                            />
                        </span>
                    ) : (
                        ''
                    )}
                    {row.status === STATUS.inactive ? (
                        <>
                            <span>
                                <IconButton
                                    title="Enable"
                                    type="dark"
                                    onClick={() => openEnableDisableModal(cell, 2)}
                                    iconUrl="/media/svg/icons/Home/enable-user.svg"
                                    className="mr-3"
                                />
                            </span>
                            <span>
                                <IconButton
                                    title="Delete"
                                    type="danger"
                                    onClick={() => openDeleteModal(cell)}
                                    iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                                    className="mr-3"
                                />
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                    {row.status === STATUS.pending ? (
                        <>
                            <span>
                                <IconButton
                                    title="Delete"
                                    type="danger"
                                    onClick={() => openDeleteModal(cell)}
                                    iconUrl="/media/svg/icons/Home/trash-alt-solid.svg"
                                    className="mr-3"
                                />
                            </span>
                            <span>
                                <IconButton
                                    title="Send Invitation"
                                    type="warning"
                                    onClick={() => processInvitation(cell)}
                                    iconUrl="/media/svg/icons/Communication/Sending mail.svg"
                                    className="mr-3"
                                />
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            );
        },
    },
];

const staffProfileInputSchema = () => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            name: Yup.string().required('Required field'),
        });
    });
};

export default Staff;
