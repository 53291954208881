/** @format */

import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import * as Paths from "src/app/routes/paths";

const ErrorsPage = props => {
  const { pathname } = props.location;

  if (pathname.includes("unauthenticated")) {
    setTimeout(function() {
      // if (window.confirm('Session timed out, Kindly click Ok to redirect to login page....'))
      {
        window.location.replace("public" + Paths.LOGIN);
      }
    }, 5000);
  }

  const getHeader = () => {
    if (pathname.includes("unauthenticated")) return "401";
    return "404";
  };

  const getDescription = () => {
    if (pathname.includes("unauthenticated"))
      return "Session timed out, redirecting you to login page.";
    return "OOPS! Something went wrong here.";
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
        }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        >
          {getHeader()}
        </h1>
        <p className="font-size-h3 font-weight-light">{getDescription()}</p>
      </div>
    </div>
  );
};

export default ErrorsPage;
