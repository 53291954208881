/** @format */

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { Input, Button, Boundary, Select, InputPhoneNumber } from 'src/app/components';

import { forgotPassword } from 'src/app/api';
import * as Paths from 'src/app/routes/paths';

const ForgotPassword = props => {
    const { history } = props;

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const {
        isSubmitting,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isValid,
        setFieldValue,
    } = useFormik({
        initialValues: { type: '', email: '', phone: '' },
        validationSchema: ForgotPasswordSchema,
        validateOnMount: true,
        validateOnChange: true,
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: async (values, { _, setSubmitting }) => {
            setSubmitting(true);

            const input = {
                username: parseInt(values.type) === 1 ? values.email : values.phone,
            };
            console.log(values);
            try {
                const res = await forgotPassword(input);
                const { message } = res.data || {};
                setSubmitting(false);
                setSuccess(message);
            } catch (e) {
                setError(e && e.response.data);
            }
        },
    });

    const types = [
        { type: 'Email', val: 1 },
        { type: 'Mobile', val: 2 },
    ];

    return (
        <>
            {success ? (
                <div className="login-form login-forgot" style={{ display: 'block' }}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h4 className="mb-6">Password reset is successful.</h4>
                        <p>You will receive a confirmation email/sms from us with further instructions.</p>
                        <Button
                            label="Login"
                            className=" floating-button btn btn-primary font-weight-bold px-9 py-4 my-12 mx-4"
                            onClick={() => history.push(`/public${Paths.LOGIN}`)}
                        />
                    </div>
                </div>
            ) : (
                <div className="login-form login-forgot" style={{ display: 'block' }}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">Forgot Password</h3>
                        <div className="text-muted font-weight-bold">Type your username to reset your password</div>
                    </div>
                    <Boundary
                        error={error}
                        success={success}
                        onClose={() => {
                            setError('');
                            setSuccess('');
                        }}>
                        <Form onSubmit={handleSubmit}>
                            <Select
                                name="type"
                                // label="Account Type"
                                placeholder="Username Type"
                                value={values.type}
                                options={types}
                                getOptionLabel={option => option.type}
                                getOptionValue={option => option.val}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // disabledplaceholder={true}
                                error={touched.type && errors.type}
                            />

                            {values.type === '2' ? (
                                <InputPhoneNumber
                                    name="phone"
                                    // width="800px"
                                    value={values.phone}
                                    onChange={setFieldValue}
                                    onBlur={handleBlur}
                                    error={touched.phone && errors.phone}
                                />
                            ) : (
                                ''
                            )}
                            {values.type === '1' ? (
                                <Input
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && errors.email}
                                    placeholder="Enter email"
                                />
                            ) : (
                                ''
                            )}
                            <div className="form-group d-flex flex-wrap flex-center">
                                <Row>
                                    <Col xs="6">
                                        <Button
                                            label="Cancel"
                                            variant="light"
                                            className=" floating-button font-weight-bold px-9 py-4 my-3 mx-4"
                                            onClick={() => history.push(`/public${Paths.LOGIN}`)}
                                        />
                                    </Col>
                                    <Col xs="6">
                                        <Button
                                            type="submit"
                                            label="Submit"
                                            loading={isSubmitting}
                                            disabled={!isValid}
                                            className=" floating-button font-weight-bold px-9 py-4 my-3 mx-4"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Boundary>
                </div>
            )}
        </>
    );
};

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().when('type', {
        is: '2',
        then: Yup.string()
            .nullable()
            .notRequired(),
        otherwise: Yup.string()
            .required('Required field')
            .email('Wrong email format'),
    }),
    phone: Yup.string().when('type', {
        is: '2',
        then: Yup.string().required('Required field'),
        otherwise: Yup.string()
            .nullable()
            .notRequired(),
    }),
});

export default injectIntl(ForgotPassword);
