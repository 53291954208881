/** @format */

import React from 'react';

// export function Alert({ title, body, redirect, buttonText, showButton }) {
//     return (
//         <div className="card card-custom gutter-b">
//             <div className="card-body">
//                 <h3 className="card-title font-weight-bolder text-dark">{title}</h3>
//                 {body}
//             </div>
//             {showButton && (
//                 <Link to={redirect}>
//                     <Button
//                         overrides={{
//                             BaseButton: { style: { width: '100%' } },
//                         }}>
//                         {buttonText}
//                     </Button>
//                 </Link>
//             )}
//         </div>
//     );
// }

export const ErrorAlert = ({ error }) => (
    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
        <div className="alert-text font-weight-bold">{error}</div>
    </div>
);

export const SuccessAlert = ({ success }) => (
    <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
        <div className="alert-text font-weight-bold">{success}</div>
    </div>
);

export const InfoAlert = ({ info }) => (
    <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
        <div className="alert-text font-weight-bold">{info}</div>
    </div>
);
