/** @format */

import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PageTitle } from 'src/app/layout';
import { ButtonCardWrapper } from 'src/app/components';

import * as Paths from 'src/app/routes/paths';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import { AuthContext } from 'src/app/context';
import { isIntersectionExists } from 'src/app/helpers/util';

const { REACT_APP_SPEEDCARD_ORGANIZATION_CODE } = process.env;

const AdminAnalytics = props => {
    const { origin } = props;
    const { getSessionStorage } = useContext(AuthContext);
    const { roles, corporateId } = getSessionStorage();

    return (
        <>
            <PageTitle title="Analytics" />
            <Row>
                {settingList({ origin, roles }).map((x, i) => {
                    const { permittedRoles, title, description, link, icon, color } = x;

                    if (!isIntersectionExists(permittedRoles, roles.split(','))) return null;

                    return (
                        <Col key={i} xs={12} md={6}>
                            <SettingTemplate
                                title={title}
                                description={description}
                                link={link}
                                icon={icon}
                                color={color}
                                iconColor="grey"
                            />
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

const SettingTemplate = ({ title, description, link, icon, fa, small, iconColor, color }) => {
    const CardContent = () => (
        <ButtonCardWrapper
            svgIcon={toAbsoluteUrl(icon)}
            title={title}
            fa={fa}
            small={small}
            iconColor={iconColor}
            description={description}
            color={color || 'success'}
        />
    );

    return (
        <Link to={link} className="dashboard-card">
            <CardContent />
        </Link>
    );
};

const settingList = props => {
    const { origin } = props;

    return [
        {
            link: `/${origin}${Paths.GENERAL_ANALYTICS}`,
            title: 'General Analytics',
            description: 'General Analytics for SpeedCard',
            icon: '/media/svg/icons/Settings/role.png',
            permittedRoles: ['corporatesuperadmin', 'superadmin'],
            color: 'primary',
        },
        {
            link: `/${origin}${Paths.ANALYTICS}`,
            title: 'Contacts Analytics',
            description: 'Contact Analytics for my corporate',
            icon: '/media/svg/icons/Settings/role.png',
            permittedRoles: ['user', 'admin', 'corporatesuperadmin'],
            color: 'primary',
        },
    ];
};

export default AdminAnalytics;
