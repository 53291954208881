/** @format */

import { gql } from '@apollo/client';

export const GET_ALL_CONTACTS_BY_USERID = gql`
    query($_userId: String!) {
        getContactsByUserId(_userId: $_userId) {
            _id
            _userId
            _cardId
            _ownerId
            status
            createdDt
            profileHidden
            tags
            isNotify
        }
    }
`;

export const GET_CONTACT_INFO = gql`
    query getContactInfoByUserId($_id: String!) {
        getContactInfoByUserId(_id: $_id) {
            _id
            name
            phone
            email
            category
            _userId
            _cardId
        }
    }
`;

export const GET_CONTACT_BY_CORPORATE_ID = gql`
    query getContactsInfoByCorporateId($corporateId: String!) {
        getContactsInfoByCorporateId(corporateId: $corporateId) {
            contact {
                _id
                _userId
                _cardId
                status
                createdDt
            }
            user {
                _id
                name
                username
                email
                icon
            }
            owner {
                _id
                name
                username
                email
                icon
            }
            card {
                _id
                cardName
                name
                designation
                company
                email
                address
                phone
                category
                icon
                hidden
                isPrimary
                isPublic
                tags
                layout {
                    _id
                    layout
                }
            }
        }
    }
`;
