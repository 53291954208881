/** @format */

import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useHtmlClassService } from 'src/_metronic/layout/_core/MetronicLayout';
import * as Paths from 'src/app/routes/paths';

export const Footer = ({ privateFooter }) => {
    const uiService = useHtmlClassService();

    useMemo(() => {
        return {
            footerLayout: objectPath.get(uiService.config, 'footer.layout'),
            footerClasses: uiService.getClasses('footer', true),
            footerContainerClasses: uiService.getClasses('footer_container', true),
        };
    }, [uiService]);

    return (
        // <div className={privateFooter ? 'procorp-footer' : 'footer'}>
        //     <div className="procorp-copyright">
        //         <p>
        //             Copyright &copy; 2021-2021 <b>Cyder SG Pte. Ltd</b>. All Rights Reserved.
        //         </p>
        //     </div>
        // </div>

        <div className={`footer p-8 d-flex flex-lg-column`}>
            <Row>
                <Col xs="8" md="10">
                    <b className="text-muted mr-2">2022</b> &copy; Cyder SG Pte Ltd. All Rights Reserved.
                </Col>
                <Col xs="4" md="2">
                    <Row>
                        <Col xs="12" md="6">
                            <Link to={`/public${Paths.PRIVACY}`} target="_blank">
                                Privacy
                            </Link>
                        </Col>
                        <Col xs="12" md="6">
                            <Link to={`/public${Paths.TERMS}`} target="_blank">
                                Terms
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
