/** @format */

import IsEmail from 'isemail';
import { fetcher } from './fetcher';

export const login = async payload => {
    try {
        const response = await fetcher().post('/auth/loginV2', {
            credentials: payload,
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const checkTFA = async payload => {
    try {
        const response = await fetcher().get(`/auth/check-tfa?credentials=${payload}`);
        return response;
    } catch (e) {
        throw e;
    }
};

export const requestOTP = async payload => {
    try {
        const response = await fetcher().get(`/auth/tfa?username=${payload.username}`);
        return response;
    } catch (e) {
        throw e;
    }
};

export const verifyOTP = async payload => {
    try {
        const response = await fetcher().post(`/otp/verify-otp`, payload);
        return response;
    } catch (e) {
        throw e;
    }
};

export const forgotPassword = async payload => {
    try {
        const isEmail = IsEmail.validate(payload.username);
        let response;
        if (isEmail) {
            response = await fetcher().get(`/auth/forgot-password-email?email=${payload.username}`);
        } else {
            response = await fetcher().get(`/auth/forgot-password-phone?phone=${payload.username}`);
        }
        return response;
    } catch (e) {
        throw e;
    }
};

export const forgotUsername = async payload => {
    try {
        const response = await fetcher().get(`/auth/forgot-username?email=${payload.email}`);
        return response;
    } catch (e) {
        throw e;
    }
};

export const changePassword = async payload => {
    try {
        const response = await fetcher().post(`/auth/change-password`, payload);
        return response;
    } catch (e) {
        throw e;
    }
};

export const verifyEmail = async payload => {
    try {
        const response = await fetcher().post(`/registration/verify-email`, payload);
        return response;
    } catch (e) {
        throw e;
    }
};
