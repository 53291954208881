/** @format */

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { Input, PasswordInput, Pin, Button, Boundary, Form } from 'src/app/components';
import { AuthContext } from 'src/app/context';

import * as Paths from 'src/app/routes/paths';
import { getErrorMsg } from '../helpers/util';

const btoa = require('btoa');

const { REACT_APP_SPEEDCARD_ORGANIZATION_CODE } = process.env;

const Login = props => {
    const { history } = props;

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [step, setStep] = useState(0);
    const [user, setUser] = useState({});
    const [otpCount, setOTPCount] = useState(0);

    const { signIn, requestOTPToken, verifyOTPToken } = useContext(AuthContext);

    const onVerifyOTP = async values => {
        try {
            // verify otp
            const res = await verifyOTPToken({
                username: user.username,
                otp: values.otp,
            });
            const corporate = JSON.parse(sessionStorage.getItem('corporate'));
            const { verified, user: resUser } = res.data || {};

            if (res.username !== resUser.username) history.push(`/public/*`);
            // if (corporate.corporateName === REACT_APP_SPEEDCARD_ORGANIZATION_CODE) history.push(`/procorp/`);
            else history.push(`/user/`);
            if (verified) throw res;
            // login
            if (resUser.username === user.username) await onLogin(user);
        } catch (e) {
            // setError(getErrorMsg(e, 'Ivalid OTP'));
            setError('Invalid OTP');
            throw e;
        }
    };

    const onRequestOTP = async username => {
        try {
            const otp = await requestOTPToken({
                username,
            });
            sessionStorage.setItem('otp', JSON.stringify(otp.data));

            setOTPCount(otpCount + 1);
            if (otpCount > 0) setSuccess('New OTP has been sent, please use that new OTP.');
        } catch (e) {
            setError(e.response.data || 'Failed to send OTP');
        }
    };

    const onLogin = async values => {
        try {
            const credentials = getCredentials(values);
            const res = await signIn(credentials);

            if (res.tfa === 'true') {
                setStep(1);
                onRequestOTP(values.username);
                return;
            }

            // route
            else {
                if (res.username !== values.username) history.push(`/public/*`);
                if (res.corporateId === REACT_APP_SPEEDCARD_ORGANIZATION_CODE) history.push(`/procorp/`);
                else history.push(`/user/`);

                return res;
            }
        } catch (e) {
            if (e.response !== undefined && e.response !== null) {
                setError(e.response.data || 'Failed to check TFA enabled');
            } else {
                console.log(e);
            }
        }
    };

    const getCredentials = values => {
        const time = moment().format('YYYYMMDDHHmm');
        //const corporateId = REACT_APP_SPEEDCARD_ORGANIZATION_CODE;
        const credentials = `username=${values.username};password=${values.password};time=${time};tfa=${values.tfa}`;
        return btoa(credentials);
    };

    return (
        <>
            <div className="login-form login-signin" id="kt_login_signin_form">
                <div className="text-center mb-6 mb-lg-6">
                    <h3 className="font-size-h1">
                        <FormattedMessage id="AUTH.LOGIN.TITLE" />
                    </h3>
                    <p className="text-muted font-weight-bold">Enter your username and password</p>
                </div>
                <Boundary
                    error={error}
                    success={success}
                    onClose={() => {
                        setError('');
                        setSuccess('');
                    }}>
                    <>
                        {step === 0 && <LoginForm onSubmit={onLogin} />}
                        {step === 1 && (
                            <OTPForm
                                onSubmit={onVerifyOTP}
                                onRequestOTP={() => onRequestOTP(user.username)}
                                name={'nora'}
                            />
                        )}
                    </>
                </Boundary>
            </div>
        </>
    );
};

const LoginForm = props => {
    const { onSubmit } = props;

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid } = useFormik({
        initialValues: { username: '', password: '' },
        validationSchema: LoginSchema(),
        validateOnMount: true,
        validateOnChange: true,
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: async values => {
            onSubmit(values);
        },
    });

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                name="username"
                placeholder="Username"
                error={touched.username && errors.username}
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <PasswordInput
                name="password"
                placeholder="Password"
                error={touched.password && errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
            />

            <Row>
                <Col xs="6">
                    <div className="form-group justify-content-between align-items-center mt10px">
                        <Link to={`/public${Paths.FORGOT_PASSWORD}`} className="text-dark-50 text-hover-primary my-3">
                            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                        </Link>
                    </div>
                </Col>
                <Col xs="6">
                    <div className="textalign-right form-group justify-content-between align-items-center">
                        <Button
                            type="submit"
                            label="Sign In"
                            disabled={!isValid}
                            className=" floating-button font-weight-bold px-9 py-4 my-3"
                        />
                    </div>
                </Col>
            </Row>
            {/* <div className="text-center mb-10 mb-lg-20">
                <p className="text-muted font-weight-bold">
                    Not yet register? &nbsp;
                    <Link to={`/public${Paths.REGISTRATION}`} className="text-dark-50 text-hover-primary my-3">
                        <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
                    </Link>
                </p>
            </div> */}
        </Form>
    );
};

const OTPForm = props => {
    const { onSubmit, onRequestOTP } = props;

    const { handleSubmit, setFieldValue } = useFormik({
        initialValues: { otp: '' },
        enableReinitialize: true,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: async values => {
            await onSubmit(values);
        },
    });

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Pin
                    length={6}
                    className="mb-14 justify-content-center"
                    name="otp"
                    onComplete={handleSubmit}
                    onChange={({ value, name }) => setFieldValue(name, value)}
                />
                <div className="d-flex justify-content-center mb-10">
                    <Button
                        className=" floating-button "
                        label="Request new OTP"
                        variant="light"
                        onClick={onRequestOTP}
                    />
                </div>
            </Form>
        </div>
    );
};

const LoginSchema = () => {
    return Yup.lazy(_ => {
        return Yup.object().shape({
            username: Yup.string().required('Required field'),
            password: Yup.string().required('Required field'),
        });
    });
};

export default injectIntl(Login);
