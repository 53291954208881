/** @format */

import React, { useEffect, useState, forwardRef, useRef, InputHTMLAttributes } from 'react';
import DefaultSearchableSelect from 'react-select';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import DefaultPinInput from 'react-pin-input';
import DefaultDatePicker, { registerLocale } from 'react-datepicker';
import NumberFormat from 'react-number-format';
import enUS from 'date-fns/locale/en-US'; // the locale you want
import SVG from 'react-inlinesvg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { Editor as DefaultEditor, EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Files from 'react-files';

import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import {
    CamelCase,
    hyperlinkDownload,
    hyperlinkDownloadWithFileNameAndContentType,
    formatAddress,
    convertToPlainText,
} from 'src/app/helpers/util';
import { Modal, IconButton } from 'src/app/components';

import Autosuggest from 'react-autosuggest';
import '../../css/autoSuggest.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Button } from './Button';
import Axios from 'axios';
import createPhoneInput from 'react-phone-number-input/input';

const { REACT_APP_API_URL } = process.env;
registerLocale('enUS', enUS); // register it with the name you want

export const FormFieldOrig = props => {
    const { name, label, mandatory, description, error, children, bottomMargin, inline = false, ...rest } = props;

    return (
        <Form.Group
            {...rest}
            style={{
                marginBottom: bottomMargin || '1.75rem',
            }}
            className={`${props.className} row`}>
            {label ? (
                <>
                    <Form.Label className="col-xl-3 col-lg-3 col-form-label">
                        {label}{' '}
                        {mandatory && (
                            <span variant="danger" className="text-danger mh-2">
                                *
                            </span>
                        )}
                    </Form.Label>
                    <div className="col-lg-9 col-xl-9">
                        {children}
                        {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
                        {description && <div className="mt-2 text text-muted">{description}</div>}
                    </div>
                </>
            ) : (
                <>
                    {children}
                    {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
                    {description && <div className="mt-2 text text-muted">{description}</div>}
                </>
            )}
        </Form.Group>
    );
};

export const FormField = props => {
    const {
        name,
        label,
        mandatory,
        description,
        error,
        children,
        noTopPadding,
        bottomMargin,
        inline = false,
        ...rest
    } = props;

    return (
        <Form.Group
            {...rest}
            style={{
                marginBottom: bottomMargin || '1.75rem',
            }}
            className={`${props.className} row`}>
            {label ? (
                <>
                    <Form.Label
                        className={clsx('col-xl-3 col-lg-3 col-form-label d-block d-lg-none', {
                            pt0: noTopPadding,
                            'font-weight-bolder': mandatory,
                        })}>
                        {label}
                        {mandatory && (
                            <span variant="danger" className="text-danger mh-2">
                                *
                            </span>
                        )}
                    </Form.Label>
                    <Form.Label
                        className={clsx('col-xl-3 col-lg-3 col-form-label d-none d-lg-block textalign-right', {
                            pt0: noTopPadding,
                            'font-weight-bolder': mandatory,
                        })}>
                        {label}
                        {mandatory && (
                            <span variant="danger" className="text-danger mh-2">
                                *
                            </span>
                        )}
                    </Form.Label>
                    <div className="col-lg-9 col-xl-9">
                        {children}
                        {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
                        {description && <div className="mt-2 text text-muted">{description}</div>}
                    </div>
                </>
            ) : (
                <>
                    {children}
                    {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
                    {description && <div className="mt-2 text text-muted">{description}</div>}
                </>
            )}
        </Form.Group>
    );
};

const InputField = props => {
    const { password, error, className = '' } = props;

    return (
        <Form.Control
            disabled={props.disabled}
            type={password ? 'password' : 'text'}
            isInvalid={error}
            className={clsx(className, {
                'form-control form-control-solid form-control-lg h-auto': true,
            })}
            {...props}
        />
    );
};

const InputFieldTextArea = props => {
    const { password, error, rows, className = '' } = props;

    return (
        <Form.Control
            as="textarea"
            rows={rows || 3}
            isInvalid={error}
            className={clsx(className, {
                'form-control form-control-solid h-auto': true,
            })}
            {...props}
        />
    );
};

const InputFieldWrapper = props => {
    const { width, children } = props;

    if (!width) return <>{children}</>;
    return <div style={{ maxWidth: width }}>{children}</div>;
};

export const Input = props => {
    const { password, error, width, className = '' } = props;

    return (
        <FormField {...props}>
            <InputFieldWrapper width={width}>
                <InputField {...props} />
            </InputFieldWrapper>
        </FormField>
    );
};

export const PasswordInput = props => {
    const { width } = props;
    const [isVisible, setIsVisible] = useState(false);

    return (
        <FormField {...props}>
            <InputFieldWrapper width={width}>
                <div className="password-field-container">
                    <InputField {...props} type={isVisible ? 'text' : 'password'} />
                    <div className="visibility-icon-wrapper">
                        <FontAwesomeIcon
                            icon={isVisible ? faEye : faEyeSlash}
                            onClick={() => setIsVisible(!isVisible)}
                        />
                    </div>
                </div>
            </InputFieldWrapper>
        </FormField>
    );
};

export const InputTextArea = props => {
    const { password, error, width, className = '' } = props;

    return (
        <FormField {...props}>
            <InputFieldWrapper width={width}>
                <InputFieldTextArea {...props} />
            </InputFieldWrapper>
        </FormField>
    );
};

export const TextAreaInput = props => {
    const { error, className = '', rows, ...rest } = props;

    return (
        <Form.Control
            as="textarea"
            rows={rows || 3}
            isInvalid={error}
            className={clsx(className, {
                'form-control form-control-solid h-auto': true,
            })}
            {...props}
        />
    );
};

export const Textarea = props => {
    const { error, width, className = '', maxWidth, rows, ...rest } = props;

    return (
        <FormField {...props}>
            {width ? (
                <div
                    style={
                        maxWidth
                            ? {
                                  maxWidth: width,
                                  width: '100%',
                              }
                            : {
                                  maxWidth: width,
                              }
                    }>
                    <TextAreaInput {...props} />
                </div>
            ) : (
                <TextAreaInput {...props} />
            )}
        </FormField>
    );
};

export const NumberFormatInput = props => {
    const {
        readOnly,
        name,
        value,
        className = '',
        onChange,
        thousandSeparator = true,
        placeholder,
        isAllowed,
        error,
        ...rest
    } = props;

    return (
        <FormField {...rest} error={error}>
            {props.width ? (
                <div
                    style={{
                        maxWidth: props.width,
                    }}>
                    <NumberFormat
                        className={clsx(className, {
                            'form-control form-control-solid form-control-lg h-auto': true,
                            'is-invalid': error,
                        })}
                        name={name}
                        displayType={readOnly ? 'text' : 'input'}
                        value={value}
                        onValueChange={onChange}
                        thousandSeparator={thousandSeparator}
                        decimalScale={2}
                        placeholder={placeholder}
                        isAllowed={isAllowed}
                    />
                </div>
            ) : (
                <NumberFormat
                    className={clsx(className, {
                        'form-control form-control-solid form-control-lg  h-auto': true,
                        'is-invalid': error,
                    })}
                    name={name}
                    displayType={readOnly ? 'text' : 'input'}
                    value={value}
                    onValueChange={onChange}
                    thousandSeparator={thousandSeparator}
                    decimalScale={2}
                    placeholder={placeholder}
                    isAllowed={isAllowed}
                />
            )}
        </FormField>
    );
};

export const NumberFormatInputField = props => {
    const {
        readOnly,
        name,
        value,
        className = '',
        onChange,
        thousandSeparator = true,
        placeholder,
        isAllowed,
        ...rest
    } = props;

    return (
        <NumberFormat
            className={clsx(className, {
                'form-control form-control-solid h-auto': true,
            })}
            name={name}
            displayType={readOnly ? 'text' : 'input'}
            value={value}
            onValueChange={onChange}
            thousandSeparator={thousandSeparator}
            decimalScale={2}
            placeholder={placeholder}
            isAllowed={isAllowed}
        />
    );
};

export const NumberFormatInputNew = props => {
    // const {
    //     readOnly,
    //     name,
    //     value,
    //     className = '',
    //     onChange,
    //     thousandSeparator = true,
    //     placeholder,
    //     isAllowed,
    //     width,
    //     ...rest
    // } = props;

    return (
        <FormField {...props}>
            {props.width ? (
                <div
                    style={{
                        maxWidth: props.width,
                    }}>
                    <NumberFormatInputField {...props} />
                </div>
            ) : (
                <NumberFormatInputField {...props} />
            )}
        </FormField>
    );
};

const SelectInput = props => {
    const {
        disabledplaceholder = 'false',
        options = [],
        getOptionLabel = o => o.label,
        getOptionValue = o => o.value,
        getDisabled = o => o.disabled,
        camelCase = false,
    } = props;

    return (
        <Form.Control
            as="select"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            className={clsx(props.className, {
                'form-control form-control-solid h-auto': true,
            })}
            disabled={props.disabled}
            isInvalid={props.error}>
            {[{}, ...options].map((x, i) => {
                let l = getOptionLabel(x) || props.placeholder || '';
                const v = getOptionValue(x) || '';
                const d = getDisabled(x) || false;

                return (
                    <option key={i} value={v} disabled={v === '' ? disabledplaceholder : d}>
                        {camelCase ? CamelCase(l) : l}
                    </option>
                );
            })}
        </Form.Control>
    );
};

export const Select = props => {
    const {
        name,
        marginBottom,
        className,
        error,
        label,
        width,
        description,
        disabled,
        value,
        onChange,
        onBlur,
        disabledplaceholder = 'false',
        placeholder,
        options = [],
        getOptionLabel = o => o.label,
        getOptionValue = o => o.value,
        hidden,
        camelCase = false,
    } = props;

    return (
        <FormField
            id={name}
            error={error}
            label={label}
            description={description}
            className={className}
            hidden={hidden}
            {...props}>
            {width ? (
                <div
                    style={{
                        maxWidth: width,
                    }}>
                    <SelectInput {...props} />
                </div>
            ) : (
                <SelectInput {...props} />
            )}
        </FormField>
    );
};

export const CheckboxInput = props => {
    const {
        label,
        name,
        value,
        mandatory,
        error,
        type,
        options,
        getOptionLabel = o => o.label,
        getOptionValue = o => o.value,
        onChange,
        className,
        hidden,
    } = props;

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (Array.isArray(value)) setSelected([...value]);
    }, []);

    const onLocalChange = e => {
        const { value } = e.target;

        // radio simply returns the value
        if (type === 'radio') {
            setSelected(value);
            onChange([value]);
            return value;
        }

        // checkbox returns array
        if (selected.includes(value)) {
            setSelected(selected => {
                const newSelected = selected.filter(x => x !== value);
                onChange(newSelected);
                return newSelected;
            });
        } else {
            setSelected(selected => {
                selected.push(value);
                onChange(selected);
                return selected;
            });
        }
    };

    return (
        <Form.Group className={className} hidden={hidden}>
            {options ? (
                <>
                    {options.map((x, i) => {
                        const l = getOptionLabel(x) || '';
                        const v = getOptionValue(x) || '';
                        return (
                            <Form.Check
                                key={i}
                                type={type || 'checkbox'}
                                id={name + i}
                                name={name + i}
                                checked={value.filter(val => v === val).length > 0}
                                label={l}
                                value={v}
                                onChange={onLocalChange}
                            />
                        );
                    })}
                </>
            ) : (
                <Form.Check
                    type={type || 'checkbox'}
                    id={name}
                    checked={value}
                    label={label + (mandatory ? '*' : '')}
                    {...props}
                />
            )}
            {error && <div className="my-1 text-danger">{error}</div>}
        </Form.Group>
    );
};

export const CheckboxForForm = props => {
    const { width } = props;

    return (
        <Form.Group {...props}>
            <div className="row">
                <div className={`col-xl-3 col-lg-3 col-form-label d-none d-lg-block`}></div>
                <div className="col-lg-9 col-xl-9">
                    {width ? (
                        <div>
                            <CheckboxInput {...props} />
                        </div>
                    ) : (
                        <CheckboxInput {...props} />
                    )}
                </div>
            </div>
        </Form.Group>
    );
};

export const Checkbox = props => {
    const {
        label,
        name,
        value,
        mandatory,
        error,
        type,
        options,
        getOptionLabel = o => o.label,
        getOptionValue = o => o.value,
        onChange,
        setFieldTouched,
        className,
        hidden,
        readOnly,
    } = props;

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (Array.isArray(value)) setSelected([...value]);
    }, []);

    const onLocalChange = e => {
        if (!onChange) return;
        const { value } = e.target;

        setFieldTouched(name, true);

        // radio simply returns the value
        if (type === 'radio') {
            setSelected(value);
            onChange([value]);
            return value;
        }

        // checkbox returns array
        if (selected.includes(value)) {
            setSelected(selected => {
                const newSelected = selected.filter(x => x !== value);
                onChange(newSelected);
                return newSelected;
            });
        } else {
            setSelected(selected => {
                selected.push(value);
                onChange(selected);
                return selected;
            });
        }
    };

    return (
        <Form.Group className={className} hidden={hidden}>
            {options ? (
                <>
                    {options.map((x, i) => {
                        const l = getOptionLabel(x) || '';
                        const v = getOptionValue(x) || '';
                        return (
                            <Form.Check
                                readOnly={readOnly}
                                className="mb-3"
                                key={i}
                                type={type || 'checkbox'}
                                id={name + i}
                                name={name + i}
                                checked={value.filter(val => v === val).length > 0}
                                label={l}
                                value={v}
                                onChange={onLocalChange}
                            />
                        );
                    })}
                </>
            ) : (
                <Form.Check
                    type={type || 'checkbox'}
                    id={name}
                    checked={value}
                    label={label + (mandatory ? '*' : '')}
                    {...props}
                />
            )}
            {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
        </Form.Group>
    );
};

export const Pin = forwardRef((props, ref) => {
    const { length = 4, name, onChange, onComplete } = props;

    return (
        <FormField {...props}>
            <DefaultPinInput
                length={length}
                name={name}
                onComplete={onComplete}
                {...props}
                focus
                autoSelect
                type="numeric"
                onChange={value => onChange({ value, name })}
                ref={ref}
                style={{ textAlign: 'center' }}
                inputStyle={{
                    borderColor: '#e4e6ef',
                    borderRadius: 5,
                    width: 32,
                    height: 40,
                }}
                inputFocusStyle={{ borderColor: '#639dbb', fontWeight: 'bold' }}
            />
        </FormField>
    );
});

export const DatePickerInput = props => {
    const {
        name,
        label,
        placeholder,
        value,
        className,
        onChange,
        onBlur,
        error,
        dateFormat,
        description,
        autoComplete,
        hidden,
        ...rest
    } = props;

    return (
        <DefaultDatePicker
            wrapperClassName="w-100"
            className={clsx(className, {
                'form-control form-control-solid h-auto': true,
                'is-invalid': error,
            })}
            dateFormat={dateFormat || 'dd MMM yyyy'}
            name={name}
            placeholderText={placeholder}
            selected={value ? new Date(value) : ''}
            onChange={val => onChange(name, val)}
            onBlur={onBlur}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="enUS"
            autoComplete={autoComplete}
            {...rest}
        />
    );
};

export const DatePicker = props => {
    const {
        name,
        label,
        placeholder,
        value,
        className,
        onChange,
        onBlur,
        error,
        dateFormat,
        width,
        description,
        autoComplete,
        hidden,
        mandatory,
        ...rest
    } = props;

    return (
        <FormField
            name={name}
            label={label}
            error={error}
            description={description}
            className={className}
            hidden={hidden}
            mandatory={mandatory}>
            {width ? (
                <div
                    style={{
                        maxWidth: width,
                    }}>
                    <DatePickerInput {...props} />
                </div>
            ) : (
                <DatePickerInput {...props} />
            )}
        </FormField>
    );
};

export const Uploader = props => {
    const {
        multiple = false,
        previewImage,
        label,
        mandatory,
        description,
        value,
        name,
        filename,
        accept,
        acceptAll,
        error,
        className,
        onChange,
        onBlur,
        hidden,
        bottomMargin,
        disabled,
    } = props;
    const acceptDefault = accept || ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
    const FILE_SIZE = 10000000;

    const ref = useRef();

    const [files, setFiles] = useState([]);
    const [localError, setLocalError] = useState('');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [fileIndex, setFileIndex] = useState(0);

    const onLocalChange = e => {
        const { files } = e.currentTarget;
        let validFiles = [];
        let f = Object.values(files).filter(x => typeof x === 'object');

        setLocalError('');

        for (let i = 0; i < f.length; i++) {
            const file = f[i];
            const { size, type } = file;

            // file size check
            if (size >= FILE_SIZE) {
                setLocalError(`File exceed maximum size of ${FILE_SIZE / 1000000}MB`);
                return;
            }

            // type check
            if (!acceptAll && !acceptDefault.includes(type)) {
                setLocalError('Unsupported file format');
                return;
            }

            validFiles.push(
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
        }

        setFiles(validFiles);
        onChange && onChange(multiple ? validFiles : validFiles[0]);
    };

    const openDeleteModal = index => {
        setFileIndex(index);
        setIsDeleteOpen(true);
    };

    const onDeleteFile = () => {
        let validFiles = [...files];
        validFiles.splice(fileIndex, 1);
        ref.current.value = validFiles.length > 0 ? validFiles : '';

        setFiles(validFiles);
        onChange && onChange(multiple ? validFiles : '');
    };

    useEffect(() => {
        let f = value;

        if (!f) {
            setFiles([]);
            return;
        }

        if (f instanceof File) {
            setFiles([f]);
            return;
        }

        if (typeof value === 'object' && value.length) {
            f = value.map(attachment => {
                // EXAMPLE DATA: ['93981492-2caf-4fa8-820d-8964f83fc3f0']
                if (typeof attachment === 'string') {
                    return {
                        name: name || value,
                        preview: `${REACT_APP_API_URL}/attachment/download?id=${value}`,
                    };
                }

                // EXAMPLE DATA: [ File ]
                return attachment;
            });

            setFiles(f);
            return;
        }

        if (typeof value === 'string') {
            // EXAMPLE DATA: '93981492-2caf-4fa8-820d-8964f83fc3f0'
            f = [
                {
                    name: name || value,
                    preview: `${REACT_APP_API_URL}/attachment/download?id=${value}`,
                },
            ];
            setFiles(f);
            return;
        }
    }, [value]);

    return (
        <FormField
            label={label}
            name={name}
            error={localError || error}
            mandatory={mandatory}
            description={description}
            hidden={hidden}
            bottomMargin={bottomMargin}>
            {files && files.length > 0 && (
                <div className="mb-3">
                    {files.map((x, i) => (
                        <div key={i} className="d-flex align-items-center">
                            {previewImage ? (
                                <>
                                    <img alt={x.name} src={x.preview} style={{ maxWidth: '150px' }} />
                                    <IconButton
                                        disabled={disabled}
                                        title="Delete"
                                        type="danger"
                                        onClick={() => openDeleteModal(i)}
                                        iconUrl="/media/svg/icons/Navigation/Close.svg"
                                        className="ml-4"
                                    />
                                </>
                            ) : (
                                <div className="d-flex align-items-center">
                                    <a
                                        className="px-4 py-3 bg-light-secondary file-preview-label"
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            if (filename) {
                                                hyperlinkDownloadWithFileNameAndContentType(
                                                    x.preview,
                                                    `${filename}`,
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                );
                                            } else hyperlinkDownload(x.preview);
                                        }}>
                                        <SVG
                                            src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')}
                                            width={20}
                                            height={20}
                                        />
                                        <span className="ml-2">{x.name || filename}</span>
                                    </a>
                                    <IconButton
                                        disabled={disabled}
                                        title="Delete"
                                        type="danger"
                                        onClick={() => openDeleteModal(i)}
                                        iconUrl="/media/svg/icons/Navigation/Close.svg"
                                        className="ml-2"
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    <Modal
                        show={isDeleteOpen}
                        title="Delete File"
                        onToggle={() => setIsDeleteOpen(!isDeleteOpen)}
                        cancelButton={{
                            show: true,
                            label: 'No',
                        }}
                        okButton={{
                            show: true,
                            label: 'Yes',
                            onClick: onDeleteFile,
                        }}>
                        Do you want to remove this file?
                    </Modal>
                </div>
            )}
            <Form.Control
                hidden={disabled}
                type="file"
                ref={ref}
                name={name}
                className={clsx(className, 'form-control form-control-solid h-auto')}
                multiple={multiple}
                isInvalid={localError || error}
                accept={acceptAll ? null : acceptDefault}
                onChange={onLocalChange}
                onBlur={onBlur}
            />
        </FormField>
    );
};

export const AutoSuggestBar = props => {
    const {
        name,
        label,
        description,
        placeholder,
        suggestionObj,
        setFieldValue,
        onBlur,
        error,
        hidden,
        className,
        getSuggestionsLabel = o => o.label,
        getSuggestionsValue = o => o.value,
    } = props;
    const [value, setValue] = useState('');
    const [suggestions, setSuggestion] = useState([]);

    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${ }()|[\]\\]/g, '\\$&');
    }

    function getSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('^' + escapedValue, 'i');

        return suggestionObj.filter(suggest => regex.test(getSuggestionsLabel(suggest)));
    }

    const getSuggestionValue = suggestion => {
        setFieldValue(name, getSuggestionsValue(suggestion));
        return getSuggestionsLabel(suggestion);
    };

    const renderSuggestion = suggestion => {
        const label = getSuggestionsLabel(suggestion);
        // const value = getSuggestionsValue(suggestion);

        return <span>{label}</span>;
    };

    const customOnChange = (event, { newValue, method }) => {
        setValue(newValue);

        if (method === 'click') {
            //setFieldValue( name, newValue )
        }
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestion(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestion([]);
    };

    return (
        <FormField {...props}>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    name: name,
                    title: label,
                    placeholder: placeholder,
                    value: value,
                    onChange: customOnChange,
                    onBlur: onBlur,
                    className: `form-control form-control-solid h-auto ${className}`,
                }}
            />
        </FormField>
    );
};

export const SearchableSelect = props => {
    const {
        name,
        className,
        error,
        label,
        description,
        disabled,
        value,
        setFieldValue,
        placeholder,
        options = [],
        getOptionLabel = o => o.label,
        getOptionValue = o => o.value,
        hidden,
        bottomMargin,
        mandatory,
        camelCase,
    } = props;

    const [formattedOptions, setFormattedOptions] = useState([]);
    const [currentValue, setCurrentValue] = useState({});

    useEffect(() => {
        const tempFormattedOptions = options.map(x => {
            const l = getOptionLabel(x) || '';
            const v = getOptionValue(x) || '';
            return { label: camelCase ? CamelCase(l) : l, value: v };
        });
        const tempCurrentValue = tempFormattedOptions.find(x => x.value === value);
        setFormattedOptions(tempFormattedOptions);
        setCurrentValue(tempCurrentValue);
    }, [value, options]);

    return (
        <FormField
            id={name}
            error={error}
            label={label}
            description={description}
            className={className}
            hidden={hidden}
            bottomMargin={bottomMargin}
            mandatory={mandatory}>
            <DefaultSearchableSelect
                name={name}
                value={currentValue}
                placeholder={placeholder}
                onChange={e => {
                    setFieldValue(name, e.value);
                    setCurrentValue(e);
                }}
                isDisabled={disabled}
                options={formattedOptions}
                styles={{
                    control: provided => ({
                        ...provided,
                        '&:hover': null,
                        minHeight: 'min-content',
                        boxShadow: 'none',
                        padding: '0.65rem 1rem',
                        fontSize: '1.08rem',
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        padding: '0px',
                    }),
                    dropdownIndicator: provided => ({
                        ...provided,
                        padding: '0px',
                    }),
                    indicatorSeparator: provided => ({
                        ...provided,
                        margin: '2px 7px',
                    }),
                    input: provided => ({
                        ...provided,
                        margin: '0px 2px',
                        padding: '0px',
                    }),
                }}
                theme={theme => ({
                    ...theme,
                    borderRadius: '0.42rem',
                    colors: {
                        ...theme.colors,
                        primary: '#D3AE36',
                        neutral20: '#d7dae3',
                    },
                })}
            />
        </FormField>
    );
};

export const FileInput = props => {
    const { onChange, onError, name, className = '', accept = ['image/*'], multiple = true } = props;

    return (
        <Files
            name={name}
            className={`files-dropzone ${props.className}`}
            onChange={onChange}
            onError={onError}
            accepts={accept}
            multiple={multiple}
            maxFileSize={10000000}
            minFileSize={0}
            clickable>
            Drop files here or click to upload
        </Files>
    );
};

export const AddressInput = props => {
    const {
        name,
        label,
        onChange,
        onBlur,
        setFieldValue,
        error,
        values,
        copyCheckboxName,
        copyTo = '',
        mandatory,
    } = props;

    const searchAddress = () => {
        let postal = values.postal;

        if (postal !== undefined && postal !== null) {
            let url = `https://developers.onemap.sg/commonapi/search?searchVal=${postal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`;

            Axios.get(url).then(response => {
                if (response.data.results[0] !== undefined) {
                    let blockNumber = response.data.results[0].BLK_NO !== 'NIL' ? response.data.results[0].BLK_NO : '';
                    let unitNumber = '';
                    let roadName =
                        response.data.results[0].ROAD_NAME !== 'NIL' ? response.data.results[0].ROAD_NAME : '';
                    let building = response.data.results[0].BUILDING !== 'NIL' ? response.data.results[0].BUILDING : '';

                    setFieldValue('blockNumber', blockNumber);
                    setFieldValue('unitNumber', unitNumber);
                    setFieldValue('roadName', roadName);
                    setFieldValue('building', building);

                    if (values[copyCheckboxName] === true) {
                        if (copyTo.length > 0) {
                            const addressArray = formatAddress({
                                blockNumber,
                                roadName,
                                unitNumber,
                                building,
                                postal,
                            });
                            const address = addressArray.join('\n').trim();
                            setFieldValue(copyTo, address);
                        }
                    }
                }
            });
        }
    };

    const MAX_VAL = 999999;
    const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

    const onBlurCopy = e => {
        onBlur(e);

        if (values[copyCheckboxName] === true) {
            const addressArray = formatAddress(values);
            const address = addressArray.join('\n').trim();
            setFieldValue(copyTo, address);
        }
    };

    const handleChange = e => {
        if (e.target.value.length > 6) {
            e.preventDefault();
        } else {
            onChange(e);
        }
    };

    const handleKeyDown = e => {
        if (
            (e.key >= 0 && e.key <= 9) ||
            e.key === 'Backspace' ||
            e.key === 'Delete' ||
            e.key === 'ArrowUp' ||
            e.key === 'ArrowDown' ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight' ||
            e.key === 'Shift' ||
            e.key === 'Control'
        ) {
        } else {
            e.preventDefault();
        }
    };

    return (
        <>
            <Form.Group
                {...props}
                style={{
                    marginBottom: props.bottomMargin || '1.75rem',
                }}
                className={`${props.className} row`}>
                <Form.Label
                    className={clsx('col-xl-3 col-lg-3 col-form-label d-block d-lg-none', {
                        'font-weight-bolder': mandatory,
                    })}>
                    {label}
                    {mandatory && (
                        <span variant="danger" className="text-danger mh-2">
                            *
                        </span>
                    )}
                </Form.Label>
                <Form.Label
                    className={clsx('col-xl-3 col-lg-3 col-form-label d-none d-lg-block textalign-right', {
                        'font-weight-bolder': mandatory,
                    })}>
                    {label}
                    {mandatory && (
                        <span variant="danger" className="text-danger mh-2">
                            *
                        </span>
                    )}
                </Form.Label>
                <div className="col-lg-9 col-xl-9">
                    <div
                        style={{
                            marginLeft: 10,
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                maxWidth: 350,
                            }}>
                            <div
                                style={{
                                    flexGrow: 1,
                                    maxWidth: 130,
                                    marginRight: 20,
                                }}>
                                <SmallInput
                                    name={'postal'}
                                    placeholder="Postal"
                                    value={values.postal}
                                    onKeyDown={e => handleKeyDown(e)}
                                    onChange={e => handleChange(e)}
                                    onBlur={e => onBlurCopy(e)}
                                    error={error}
                                    inline={true}
                                />
                            </div>
                            <div
                                style={{
                                    flexGrow: 0,
                                }}>
                                <Button
                                    label={'Search'}
                                    className="procorp-address-button offset-1 mb-2"
                                    onClick={searchAddress}
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                maxWidth: 350,
                            }}>
                            <div
                                style={{
                                    marginRight: 40,
                                }}>
                                <SmallInput
                                    name={'blockNumber'}
                                    placeholder="Block Number"
                                    value={values.blockNumber}
                                    onChange={onChange}
                                    onBlur={e => onBlurCopy(e)}
                                    // error={error}
                                    inline={true}
                                />
                            </div>
                            <div>
                                <SmallInput
                                    name={'unitNumber'}
                                    placeholder="Floor and Unit Number"
                                    value={values.unitNumber}
                                    onChange={onChange}
                                    onBlur={e => onBlurCopy(e)}
                                    // error={error}
                                    inline={true}
                                />
                            </div>
                        </div>

                        <Textarea
                            name={'roadName'}
                            placeholder="Road Name"
                            value={values.roadName}
                            row={2}
                            maxWidth
                            width={450}
                            onChange={onChange}
                            onBlur={e => onBlurCopy(e)}
                            // error={error}
                        />

                        <Textarea
                            name={'building'}
                            row={2}
                            maxWidth
                            width={450}
                            placeholder="Building"
                            value={values.building}
                            onChange={onChange}
                            onBlur={e => onBlurCopy(e)}
                            // error={error}
                        />
                    </div>
                </div>
            </Form.Group>
        </>
    );
};

export const SmallInput = props => {
    const { password, error, className = '', inline = false } = props;

    return (
        <FormField {...props}>
            <Form.Control
                type={password ? 'password' : 'text'}
                isInvalid={error}
                {...props}
                className={`form-control procorp-small-input form-control-solid h-auto form-inline ${className}`}
            />
        </FormField>
    );
};

export const MultipleListUploader = props => {
    const {
        multiple = true,
        previewImage,
        label,
        mandatory,
        description,
        value,
        name,
        filename,
        accept,
        acceptAll,
        error,
        className,
        onChange,
        onBlur,
        hidden,
    } = props;
    const acceptDefault = accept || ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
    const FILE_SIZE = 2000000;

    const [tempFiles, setTempFiles] = useState([]);
    const [localError, setLocalError] = useState('');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [fileIndex, setFileIndex] = useState(0);

    const onLocalChange = e => {
        const { files } = e.currentTarget;
        let validFiles = [];
        let f = Object.values(files).filter(x => typeof x === 'object');

        for (let i = 0; i < f.length; i++) {
            const file = f[i];
            const { size, type } = file;

            // file size check
            if (size >= FILE_SIZE) {
                setLocalError(`File exceed maximum size of ${FILE_SIZE / 1000000}MB`);
                return;
            }

            // type check
            if (!acceptAll && !acceptDefault.includes(type)) {
                setLocalError('Unsupported file format');
                return;
            }

            validFiles.push(
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
        }

        if (tempFiles !== undefined && tempFiles !== null && tempFiles.length > 0) {
            tempFiles.map(file =>
                validFiles.push(
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        }

        setTempFiles(validFiles);
        onChange && onChange(validFiles);
    };

    const openDeleteModal = index => {
        setFileIndex(index);
        setIsDeleteOpen(true);
    };

    const onDeleteFile = () => {
        let validFiles = [...tempFiles];
        validFiles.splice(fileIndex, 1);

        setTempFiles(validFiles);
        onChange && onChange(multiple ? validFiles : null);
    };

    useEffect(() => {
        if (!value) return;

        let f = value;
        if (typeof value === 'object' && value.length) {
            f = value.map(attachment => {
                // EXAMPLE DATA: ['93981492-2caf-4fa8-820d-8964f83fc3f0']
                if (typeof attachment === 'string') {
                    return {
                        name: name || value,
                        preview: `${REACT_APP_API_URL}/attachment/download?id=${value}`,
                    };
                }

                // EXAMPLE DATA: [ File ]
                return attachment;
            });

            setTempFiles(f);
            return;
        }

        if (typeof value === 'string') {
            // EXAMPLE DATA: '93981492-2caf-4fa8-820d-8964f83fc3f0'
            f = [
                {
                    name: name || value,
                    preview: `${REACT_APP_API_URL}/attachment/download?id=${value}`,
                },
            ];
            setTempFiles(f);
            return;
        }
    }, [value]);

    return (
        <FormField
            label={label}
            name={name}
            error={localError || error}
            mandatory={mandatory}
            description={description}
            hidden={hidden}>
            {tempFiles && tempFiles.length > 0 && (
                <div className="mb-4">
                    {tempFiles.map((x, i) => (
                        <div key={i} className="d-flex align-items-center">
                            {previewImage ? (
                                <>
                                    <img alt={x.name} src={x.preview} style={{ maxWidth: '150px' }} />
                                    <IconButton
                                        title="Delete"
                                        type="danger"
                                        onClick={() => openDeleteModal(i)}
                                        iconUrl="/media/svg/icons/Navigation/Close.svg"
                                        className="ml-4"
                                    />
                                </>
                            ) : (
                                <div className="d-flex align-items-center px-4 py-2 bg-light-secondary">
                                    <a
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            if (filename) {
                                                hyperlinkDownloadWithFileNameAndContentType(
                                                    x.preview,
                                                    `${filename}`,
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                );
                                            } else hyperlinkDownload(x.preview);
                                        }}>
                                        <SVG
                                            src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')}
                                            width={20}
                                            height={20}
                                        />
                                        <span className="ml-2">{x.name}</span>
                                    </a>
                                    <IconButton
                                        title="Delete"
                                        type="danger"
                                        onClick={() => openDeleteModal(i)}
                                        iconUrl="/media/svg/icons/Navigation/Close.svg"
                                        className="ml-4"
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    <Modal
                        show={isDeleteOpen}
                        title="Delete File"
                        onToggle={() => setIsDeleteOpen(!isDeleteOpen)}
                        cancelButton={{
                            show: true,
                            label: 'No',
                        }}
                        okButton={{
                            show: true,
                            label: 'Yes',
                            onClick: onDeleteFile,
                        }}>
                        Do you want to remove this file?
                    </Modal>
                </div>
            )}
            <Form.Control
                type="file"
                name={name}
                className={clsx(className, {
                    'form-control form-control-solid h-auto py-4 px-6': true,
                })}
                multiple={multiple}
                isInvalid={localError || error}
                accept={acceptAll ? null : acceptDefault}
                onChange={onLocalChange}
                onBlur={onBlur}
            />
        </FormField>
    );
};

export const RichTextEditor = props => {
    const { mandatory, name, value, setFieldValue, setFieldError, setFieldTouched, onBlur, error, ...rest } = props;
    const [editorState, setEditorState] = useState(null);
    const [fieldState, setFieldState] = useState({});
    const editor = useRef(null);

    useEffect(() => {
        if (!fieldState.touched) {
            setEditorState(EditorState.createWithContent(ContentState.createFromText(convertToPlainText(value || ''))));
        }
    }, [value]);

    const onFocus = () => {
        editor.current.focus();
        setFieldState(prev => ({ ...prev, touched: true, focus: true }));
    };

    const handleBlur = () => {
        onBlur(name);
        setFieldState(prev => ({ ...prev, focus: false }));
        setFieldTouched(name, true);
        if (mandatory && !value) {
            setFieldError(name, 'Required field');
        }
    };

    const handleChange = input => {
        setEditorState(input);
        setFieldValue(name, input.getCurrentContent().getPlainText('\u0001'));
    };

    return (
        <FormField name={name} error={error} {...rest} onClick={onFocus}>
            {editorState && (
                <div
                    className={clsx('form-control form-control-solid form-control-lg h-auto py-0', {
                        active: fieldState.focus,
                        'is-invalid': error,
                    })}>
                    <DefaultEditor
                        ref={editor}
                        name={name}
                        editorState={editorState}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </div>
            )}
        </FormField>
    );
};

export { Form };
