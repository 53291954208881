import React from "react";

export const ShortDivider = () => {
  return (
    <div
      style={{
        width: "35px",
        paddingTop: "2px",
        marginTop: "10px",
        marginBottom: "15px"
      }}
      className="bg-primary"
    ></div>
  );
};
