/** @format */

import React from 'react';
import { FormField } from './Input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';

export const InputPhoneNumber = props => {
    const { width } = props;

    return (
        <FormField {...props}>
            {width ? (
                <div
                    style={{
                        maxWidth: width,
                    }}>
                    <InputPhoneNumberInput {...props} />
                </div>
            ) : (
                <InputPhoneNumberInput {...props} />
            )}
        </FormField>
    );
};

export const InputPhoneNumberInput = props => {
    const {
        error,
        value,
        onChange,
        name,
        placeholder = 'phone',
        disabled,
        className = 'form-control form-control-solid h-auto form-control w-100',
        onKeyDown,
    } = props;

    return (
        <PhoneInput
            country={'sg'}
            placeholder={placeholder}
            value={value !== undefined && value !== null ? value : ''}
            onChange={onChange}
            inputClass={className}
            autoFormat={false}
            countryCodeEditable={false}
            enableSearch={true}
            searchStyle={{ width: '100%' }}
            inputProps={{
                name: name,
            }}
        />
    );
};
