/** @format */

import { gql } from '@apollo/client';

export const GET_SYSTEM_SETTINGS = gql`
    query {
        getSystemSettings {
            _id
            maxMainCard
            maxAnnexCard
            maxTags
        }
    }
`;

export const UPDATE_SYSTEM_SETTINGS = gql`
    mutation updateSystemSettings($input: SystemSettingsInputUpdate) {
        updateSystemSettings(input: $input) {
            _id
            maxMainCard
            maxAnnexCard
            maxTags
        }
    }
`;
