/** @format */

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from 'src/_metronic/layout/_core/MetronicLayout';

export function HeaderMobile({ isMyCorp }) {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
            headerMobileCssClasses: uiService.getClasses('header_mobile', true),
            headerMobileAttributes: uiService.getAttributes('header_mobile'),
        };
    }, [uiService]);

    return (
        <>
            <div
                id="kt_header_mobile"
                className={`header-mobile ${isMyCorp ? 'border-top' : ''}`}
                {...layoutProps.headerMobileAttributes}>
                <Link
                    className="procorp-menu-text"
                    to="/"
                    style={{
                        fontSize: 20,
                    }}>
                    {/* <img alt="Logo" className="logo-default max-h-30px" src={toAbsoluteUrl('/media/images/logo.png')} /> */}
                    SpeedCard
                </Link>
                <div className="d-flex align-items-center">
                    <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                        <span />
                    </button>
                </div>
            </div>
        </>
    );
}
